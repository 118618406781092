import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';
import { IUserContext } from '../../rootReducer';

export interface ILoadFeedMessagesRequest {
	user: IUserContext;
	contractId: string;
}

export interface ILoadFeedMessagesSuccess {
	messages: IFeedMessageAM[];
	useCachedData: boolean;
}

export interface IChangeFeedMessageStatusRequest {
	user: IUserContext;
	messageId: string;
	newStatus: FeedMessageStatus;
}

export interface IChangeFeedMessageStatusSuccess {
	messageId: string;
	newStatus: FeedMessageStatus;
}

export interface ILogFeedMessageFollowRequest {
	user: IUserContext;
	messageId: string;
	followType: string;
}

export interface IFeedMessageAM {
	id: string;
	created: Date;
	status: FeedMessageStatus;
	firstRead: Date | null;
	priority: FeedMessagePriority;
	localizedContents: IFeedMessageContentAM[];

}

export interface IFeedMessageContentAM {
	language: string;
	body: string;
	linkText: string;
	linkUri: string;
}

export type FeedMessageStatus =
	"Active" |
	"UserClosed" |
	"Handled" |
	"Expired" |
	"Deleted";

export type FeedMessagePriority =
	"Low" |
	"Medium" |
	"High";

export const feedActions = {

	loadMessages: createAsyncAction(
		'feed/LOAD_MESSAGES_REQUEST',
		'feed/LOAD_MESSAGES_SUCCESS',
		'feed/LOAD_MESSAGES_FAILED'
	)<ILoadFeedMessagesRequest, ILoadFeedMessagesSuccess, Error>(),

	changeMessageStatus: createAsyncAction(
		'feed/CHANGE_MESSAGE_STATUS_REQUEST',
		'feed/CHANGE_MESSAGE_STATUS_SUCCESS',
		'feed/CHANGE_MESSAGE_STATUS_FAILED'
	)<IChangeFeedMessageStatusRequest, IChangeFeedMessageStatusSuccess, Error>(),

	logMessageFollow: createAsyncAction(
		'feed/LOG_MESSAGE_FOLLOW_REQUEST',
		'feed/LOG_MESSAGE_FOLLOW_SUCCESS',
		'feed/LOG_MESSAGE_FOLLOW_FAILED'
	)<ILogFeedMessageFollowRequest, string, Error>(),

	clearFeedMessageCache: createAction(
		"feed/CLEAR_CACHE")<void>()
};

export type FeedActionTypes = ActionType<typeof feedActions>;
