import { AnyObject } from "./anyObject";

export enum GA4EventType {
	ui_clicks = 'ui_clicks',
}

export enum GA4EventCategory {
	faq_tile = 'faq_tile',
	other_residence_contact = 'other_residence_contact',
	quick_link = 'quick_link',
	tile_swipe = 'tile_swipe',
	tile_click = 'tile_click',
	headernav = 'headernav',
	usermenu = 'usermenu',
	footernav = 'footernav',
}

export enum GA4EventAction {
	sauna = 'sauna',
	faq = 'faq',
	rent_question = 'rent_question',
	disturbance_report = 'disturbance_report',
	keys = 'keys',
	problem = 'problem',
	broadband = 'broadband',
	parking_space = 'parking_space',
	lumotoolbox = 'lumotoolbox',
	homeguide = 'homeguide',
	lumoteam = 'lumoteam',
	ledger = 'ledger',
	houseinfo = 'houseinfo',
	services = 'services',
	servicerequest = 'servicerequest',
	contactus = 'contactus',
	contract = 'contract',
	customerinfo = 'customerinfo',
	actions = 'actions',
	facilitiesandreservations = 'facilitiesandreservations',
	ourhouse = 'ourhouse',
	benefits = 'benefits',
}

interface GA4Event extends AnyObject {
	event: GA4EventType;
	category: GA4EventCategory;
	action: GA4EventAction;
}

export function addTelemetryEventGA4(event: GA4Event) {
	const eventObject = {
		...event,
		label: `${window.location.pathname}${window.location.search}`,
		ga4_event: true,
	};

	const dataLayer = (window as any).dataLayer;
	if (dataLayer) {
		dataLayer.push(eventObject);
	}
}
