import { combineReducers } from "redux";
import { userReducer, refreshingReducer, loginReducer, ILoginState } from "./Authentication/userReducer";
import {
	IDashboardState,
	dashboardReducer
} from "./Pages/Dashboard/dashboardReducer";
import {
	IEmbedState,
	embedReducer
} from "./Pages/Embed/embedReducer";
import {
	IContractContextState,
	contractContextReducer
} from "./Routing/ContractContext/contractContextReducer";
import { feedReducer, IFeedState } from './Components/Feed/feedReducer';
import { settingsReducer } from './Settings/settingsReducer';
import { IAnonymousState, anonymousReducer } from './Pages/AnonymousUsers/anonymousReducer';

export interface IUserContext {
	firstName: string;
	lastName: string;
	userId: string;
	customerId: string;
	accessToken: string;
	language: UILanguage;
	email: string;
	expiresAt: number;
	welcomeActionsNeeded: boolean;
	hasLinkedIdentities: boolean;
	impersonatingUser?: string;
	shouldShowSiteSurvey: boolean;
}

export type UILanguage = 'fi' | 'en';

export interface ISettingsState {
	loaded: boolean;
	ssoAuthority?: string;
	languageCookieDomain: string | null;
	commonUiBaseUrl: string | undefined;
	cdnBaseUrl: string | undefined;
	assetsBaseUrl: string | undefined;
	rentalApartmentStoreBaseUrl: string | undefined;
}

export interface IShellState {
	refreshingUser: boolean;
	user: IUserContext | null;
	dashboard: IDashboardState;
	embedding: IEmbedState;
	contracts: IContractContextState;
	feed: IFeedState;
	settings: ISettingsState;
	anonymousState: IAnonymousState;
	loginState: ILoginState;
}

export interface IAuthenticatedShellState extends IShellState {
	user: IUserContext;
}

export type KnownActions = never;

export const rootReducer = combineReducers<IShellState>({
	user: userReducer,
	refreshingUser: refreshingReducer,
	dashboard: dashboardReducer,
	embedding: embedReducer,
	contracts: contractContextReducer,
	feed: feedReducer,
	settings: settingsReducer,
	anonymousState: anonymousReducer,
	loginState: loginReducer
});
