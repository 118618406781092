import React from 'react';
import { IContentArticleAM, ApiOperationState } from 'src/ApiClient';
import { ResourceText, useResource } from "@kojamo/react-utils";

interface IArticleFeedProps {
	articles: IContentArticleAM[];
	articlesFetchState: ApiOperationState | undefined;
}

export const ArticleFeed: React.FC<IArticleFeedProps> = ({ articles, articlesFetchState }) => {
	if (articlesFetchState !== ApiOperationState.Succeeded) {
		return null;
	}
	const { getResourceText } = useResource();
	return (
		<div className="mod-inspirationLinks">
			<div className="mod-inspirationLinks__wrapper">
				<h2 className="mod-inspirationLinks__heading">
          <span>
            <ResourceText textType="MarkdownInline" resourceKey="ArticleFeed_Heading_Main" />
          </span>
				</h2>
				<ul className="mod-inspirationLinks__list">
					{articles.map((article) => (
						<li className="mod-inspirationLinks__list-item" key={article.id}>
							<a
								className="mod-inspirationLinks__list-item-wrapLink"
								href={article.url}
							>
								<picture>
									<source
										media="(max-width: 899px)"
										srcSet={article.imageUrlMedium}
									/>
									<img
										className="mod-inspirationLinks__list-item-image"
										src={article.imageUrl}
									/>
								</picture>
								<h3 className="mod-inspirationLinks__list-item-heading">
									{article.title}
								</h3>
							</a>
						</li>
					))}
				</ul>
				<div className="mod-inspirationLinks__cta">
					<a
						href={getResourceText('ArticleFeed_SeeAllArticles_Url')}
						className="mod-inspirationLinks__cta-button"
					>
            <span>
              <ResourceText textType="MarkdownInline" resourceKey="ArticleFeed_SeeAllArticles_Link" />
            </span>
					</a>
				</div>
			</div>
		</div>
	);
};

export default ArticleFeed;
