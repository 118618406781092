import { IUserContext, UILanguage } from './rootReducer';
import { ApiMaintenanceError } from "./ApiMaintenanceError";
import { CustomHeaders } from "./CustomHeaders";
import { ApiCallError } from './ApiCallError';

/**
 * We use an alternate name for the Authorization header to work around an IE bug
 * concerning mixing Basic authentication with Bearer authentication in XHR.
 *
 * The Shell backend will treat Shell-Authorization as an override for Authorization.
 */
const AuthorizationHeaderName = 'Shell-Authorization';
const UiLanguageHeaderName = 'ui-language';

export abstract class ApiClientBase {

	public static deserialize<T>(data: string): any {
		const parsed = JSON.parse(data, ApiClientBase.reviveDateTime);

		const result: T = parsed;
		return result;
	}

	private static dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{1,7})?Z$/;
	private static reviveDateTime(key: any, value: any): any {

		if (typeof value === "string" && ApiClientBase.dateFormat.test(value)) {
			return new Date(value);
		}

		return value;
	}

	private accessToken: string | null;

	constructor(private language: UILanguage, context?: IUserContext, accessToken?: string) {

		if (!!context && !!accessToken) {
			throw new Error("Context and accessToken can't both be specified!");
		}

		if (context) {
			this.accessToken = context.accessToken;
		}
		else if (accessToken) {
			this.accessToken = accessToken;
		}
		else {
			this.accessToken = null;
		}
	}

	protected async fetchString(url: string, method = 'GET', headers: Record<string, string> | undefined = {}, body?: string | undefined): Promise<string> {

		if (this.accessToken) {
			headers[AuthorizationHeaderName] = `Bearer ${this.accessToken}`;
		}

		headers[UiLanguageHeaderName] = this.language;

		const response = await fetch(process.env.REACT_APP_API_BASE_URL + url, {
			// For normal operations, we don't need cookies. However, if the maintenance page is active _and_
			// a user uses the maintenance page bypass mechanism, the bypass setting is stored in a cookie.
			// Having credentials: omit here will drop the cookie,every API request will get the maintenance page
			// and the app will show its contents regardless of the bypass setting. Hence, same-origin.
			credentials: 'same-origin',
			method: method,
			headers,
			body
		});

		this.validateResponse(url, method, response);

		return await response.text();
	}

	protected async fetchJson<T>(url: string): Promise<T> {

		const jsonString = await this.fetchString(url);
		return ApiClientBase.deserialize(jsonString);
	}

	protected async post<T>(url: string, body?: string): Promise<T> {

		const headers: Record<string, string> | undefined = this.accessToken
			? { [AuthorizationHeaderName]: `Bearer ${this.accessToken}` }
			: {};
		headers["Content-Type"] = "application/json";
		headers[UiLanguageHeaderName] = this.language;

		const response = await fetch(process.env.REACT_APP_API_BASE_URL + url,
			{
				credentials: "omit",
				method: "POST",
				headers,
				body,
			});
		this.validateResponse(url, "POST", response);

		const resultString = await response.text();
		return ApiClientBase.deserialize(resultString);
	}

	protected async put(url: string): Promise<void> {
		const response = await fetch(process.env.REACT_APP_API_BASE_URL + url,
			{
				credentials: "omit",
				method: "PUT",
				headers: {
					[AuthorizationHeaderName]: `Bearer ${this.accessToken}`,
					[UiLanguageHeaderName]: this.language,
				}
			});
		this.validateResponse(url, "PUT", response);

	}

	private validateResponse(url: string, method: string, response: Response): void | never {
		if (response.headers.has(CustomHeaders.MaintenancePage)) {
			throw new ApiMaintenanceError(`Request ${method} ${url} returned maintenance page`);
		}

		if (response.status >= 300) {
			throw new ApiCallError(`Unexpected status code ${response.status} from ${method} to '${url}'`, response.status);
		}
	}
}


