import { Reducer } from 'redux';
import { IUserContext } from '../rootReducer';
import { AuthActionTypes, authActions } from './authenticationActions';
import { getType } from 'typesafe-actions';
import { User } from 'oidc-client';
import { IUserMetadataAM } from 'src/ApiClient';

const parseProfile = (oldState: IUserContext | null, user: User, metadata?: IUserMetadataAM): IUserContext => {

	if (!oldState && !metadata) { throw new Error("No metadata available!"); }

	const impersonatingUserId = user.profile['Kojamo:ImpersonatingUser:UserId'];
	const impersonatingUserFullName = user.profile['Kojamo:ImpersonatingUser:FullName'];

	return {
		...oldState,
		customerId: user.profile.customer_id,
		firstName: user.profile.given_name!,
		lastName: user.profile.family_name!,
		userId: user.profile.sub,
		accessToken: user.access_token,
		language: (metadata && metadata.language && metadata.language === 'en') ? 'en' : 'fi',
		email: user.profile.email!,
		expiresAt: user.expires_at,
		welcomeActionsNeeded: !!metadata ? metadata.welcomeActionsNeeded : oldState!.welcomeActionsNeeded,
		hasLinkedIdentities: !!metadata ? metadata.hasLinkedIdentities : oldState!.hasLinkedIdentities,
		impersonatingUser: impersonatingUserId ? `${impersonatingUserFullName} (${impersonatingUserId})` : undefined,
		shouldShowSiteSurvey: !!metadata ? metadata.shouldShowSiteSurvey : false,
	}
};

export const userReducer: Reducer<IUserContext | null, AuthActionTypes> = (state = null, action) => {
	switch (action.type) {

		case getType(authActions.login.success):
		case getType(authActions.refresh.success): {
			return parseProfile(state, action.payload.user, action.payload.metadata);
		}

		case getType(authActions.logout.success):
			return null;

		case getType(authActions.markWelcomeReady): {
			return state ? { ...state, welcomeActionsNeeded: false } : null;
		}

		case getType(authActions.login.failure):
		case getType(authActions.saveSiteSurveyAction.request): {
			return state;
		}
		case getType(authActions.saveSiteSurveyAction.success):
		case getType(authActions.saveSiteSurveyAction.failure): {
			return state ? { ...state, shouldShowSiteSurvey: false } : null;
		}
	}
	return state;
}

export const refreshingReducer: Reducer<boolean, AuthActionTypes> = (state = true, action) => {
	switch (action.type) {
		case getType(authActions.refresh.request):
			return true;
		case getType(authActions.refresh.success):
		case getType(authActions.refresh.failure):
			return false;
	}
	return state;
}

export enum LoginStatus {
	NotLoggedIn, LoggingIn, LogInSucceeded, LogInFailed
}

export interface ILoginState {
	status: LoginStatus;
	error?: Error;
}

export const loginReducer: Reducer<ILoginState, AuthActionTypes> = (state = { status: LoginStatus.NotLoggedIn }, action) => {
	switch (action.type) {
		case getType(authActions.login.request):
			return { status: LoginStatus.LoggingIn };
		case getType(authActions.login.success):
			return { status: LoginStatus.LogInSucceeded };
		case getType(authActions.login.failure):
			return { status: LoginStatus.LogInFailed, error: action.payload };
		default:
			return state;
	}
};
