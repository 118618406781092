import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
	ITileConfiguration,
	adaptiveCardHostConfig
} from './TileConfiguration';
import { AdaptiveCard, Action, OpenUrlAction } from 'adaptivecards';
import {
	IEmbeddedUrl,
	embedActions
} from '../Pages/Embed/embedActions';
import {IAuthenticatedShellState, IUserContext, UILanguage} from 'src/rootReducer';
import { connect } from 'react-redux';
import { IContractAM } from 'src/ApiClient';
import { isEmbedUrl, getLocalizedPathForTile, isDashboardUrl, getDashboardUrlRelativePart } from 'src/Routing/embeddedUrls';
import {authenticatedRoutes} from "../Routing/authenticatedRoutes";

export interface ITileRendererStateProps {
	user: IUserContext;
	embeddedUrls: IEmbeddedUrl[];
	shouldLoadUrls: boolean;
	currentContract: IContractAM;
	uiLanguage: UILanguage;
}

export interface ITileRendererOwnProps {
	tile: ITileConfiguration;
	onClick?: () => void;
}

export type ITileRendererProps = ITileRendererOwnProps &
	ITileRendererStateProps &
	ITileRendererDispatchProps &
	RouteComponentProps<any>;

class TileRenderer extends React.Component<ITileRendererProps> {
	constructor(props: ITileRendererProps, context?: any) {
		super(props, context);

		if (this.props.shouldLoadUrls) {
			this.props.fetchEmbeddedUrls(this.props.user);
		}
	}

	public render() {
		return <div className="tile blue-overlay" onClick={this.props.onClick} ref={this.renderCard} />;
	}

	private renderCard = (element: HTMLDivElement | null) => {
		if (!element) {
			return;
		}

		const tileDef = this.props.tile;

		const card = new AdaptiveCard();
		card.parse(JSON.parse(tileDef.cardJson));

		card.hostConfig = adaptiveCardHostConfig;
		card.onExecuteAction = (action) => this.handleAction(action);

		const renderedCard = card.render();

		if(!renderedCard) {
			return;
		}

		if (tileDef.backgroundColor) {
			renderedCard.style.backgroundColor = tileDef.backgroundColor;
		}

		element.appendChild(renderedCard);

		element.id = tileDef.id;
		if (tileDef.contentClass) {
			element.classList.add("tile--" + tileDef.app.toLowerCase() + "--" + tileDef.contentClass.toLowerCase());
		}
	};

	private handleAction(action: Action): void {
		if (action.getJsonTypeName() === 'Action.OpenUrl') {
			this.handleOpenUrlAction(action as OpenUrlAction);
		} else {
			alert(action.getJsonTypeName() + ': ' + action.title);
		}
	}

	private handleOpenUrlAction(action: OpenUrlAction): void {

		if(!action.url) {
			return;
		}

		const { currentContract } = this.props;
		if (isDashboardUrl(action.url)) {
			const relativePart = getDashboardUrlRelativePart(action.url);
			if (relativePart.startsWith('#')) {
				this.props.history.replace(relativePart)
			} else {
				const fullUrl = `/${currentContract.contractNumber}${authenticatedRoutes.frontpage[this.props.uiLanguage]}/${relativePart}`;

				this.props.history.push(fullUrl);
			}
		} else if (isEmbedUrl(action.url)) {
			const { embeddedUrls, tile, user, history } = this.props;

			const localizedUrl = getLocalizedPathForTile(embeddedUrls, action.url, tile.app, user.language);
			if (localizedUrl) {
				history.push(`/${currentContract.contractNumber}/${localizedUrl}`);
			} else {
				// todo: log error about unknown embedded url
			}
		}
		else if (action.url.startsWith(window.location.origin)) {
			const path = action.url.substring(window.location.origin.length);
			this.props.history.push(path);
		}
		else {
			window.open(action.url, "_blank");
		}
	}
}

export interface ITileRendererDispatchProps {
	fetchEmbeddedUrls: (user: IUserContext) => any;
}

const mapStateToProps = ({
	user,
	embedding,
	contracts,
	dashboard
}: IAuthenticatedShellState): ITileRendererStateProps => {
	const { urls, fetchingUrls } = embedding;
	return {
		user,
		shouldLoadUrls: !urls.length && !fetchingUrls,
		embeddedUrls: urls,
		currentContract: contracts.selectedContract!,
		uiLanguage: dashboard.uiLanguage,
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		{
			fetchEmbeddedUrls: () =>
				embedActions.fetchUrls.request()
		}
	)(TileRenderer)
);
