import { takeLatest, call, put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { embedActions } from "./embedActions";
import { ApiClient } from "../../ApiClient";
import { getApiClient } from "../../Common/getApiClient";

function* fetchEmbeddedUrlsSaga() : any {
	try {
		const api:ApiClient = yield call(getApiClient);

		const urls = yield call([api, api.fetchEmbeddedUrls]);
		yield put(embedActions.fetchUrls.success(urls));
	} catch (e: any) {
		yield put(embedActions.fetchUrls.failure(e));
	}
}

function* fetchFooterSaga() : any {
	try {
		const api:ApiClient = yield call(getApiClient);

		const footer = yield call([api, api.fetchFooter]);
		yield put(embedActions.fetchFooter.success(footer));
	} catch (e: any) {
		yield put(embedActions.fetchFooter.failure(e));
	}
}

export function* embedSaga() {
	yield takeLatest(getType(embedActions.fetchUrls.request), fetchEmbeddedUrlsSaga);
	yield takeLatest(getType(embedActions.fetchFooter.request), fetchFooterSaga);
}
