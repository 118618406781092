import * as React from "react";
import { IAuthenticatedShellState, IUserContext, UILanguage } from "../../rootReducer";
import { connect } from "react-redux";
import {
	feedActions,
	ILoadFeedMessagesRequest,
	IFeedMessageAM,
	ILogFeedMessageFollowRequest,
} from "./feedActions";
import { IContractAM } from "src/ApiClient";
import InternalLink from "../InternalLink";
import orderBy from "lodash.orderby";
import { addQueryParameterToUrl } from "src/Tooling";

export interface IAlertViewStateProps {
	user: IUserContext;
	contract: IContractAM;
	isLoading: boolean;
	alertMessages: IFeedMessageAM[];
	uiLanguage: UILanguage;
}

export type IAlertViewProps = IAlertViewStateProps & IAlertViewDispatchProps;

/**
 * 	AlertView shows high priority feed messages on the top of the screen.
 *  	Used for messaging about extensive water damages, blackouts etc.
 */
class AlertView extends React.Component<IAlertViewProps> {
	constructor(props: IAlertViewProps, context?: any) {
		super(props, context);

		if (!this.props.isLoading) {
			this.props.loadMessages({
				user: this.props.user,
				contractId: this.props.contract.contractId,
			});
		}
	}

	public render() {
		if (!this.props.alertMessages.length) {
			return null;
		}

		return (
			<section className="alert header-extension">
				<div className="marquee">
					{/* These are intentionally duplicated.
					When the text is wrapped around the screen,
					the other one displays part of the text in the left side,
					other the right side of the screen. */}
					<MarqueeMessage alertMessages={this.props.alertMessages} onClick={this.linkClicked} language={this.props.uiLanguage}/>
					<MarqueeMessage alertMessages={this.props.alertMessages} onClick={this.linkClicked} language={this.props.uiLanguage}/>
				</div>
			</section>
		);
	}

	private linkClicked = (message: IFeedMessageAM) => {
		this.props.logMessageFollow({
			user: this.props.user,
			messageId: message.id,
			followType: "MyLumoUI/AlertView",
		});
	};
}

function MarqueeMessage({
	alertMessages,
	language,
	onClick,
}: {
	alertMessages: IFeedMessageAM[];
	language: UILanguage;
	onClick: (message: IFeedMessageAM) => void;
}) {
	return (
		<div className="message">
			{alertMessages.map((m) => {
				const localizedContent = m.localizedContents.find(c => c.language === language.toString()) ?? m.localizedContents[0];
				return (
					<InternalLink
						to={addQueryParameterToUrl(
							localizedContent.linkUri,
							"fc",
							"f"
						)}
						key={m.id}
						onClick={() => onClick(m)}
					>
						<span className="content">
							{localizedContent.body}{" "}
							{localizedContent.linkText}&nbsp;&rsaquo;
						</span>
					</InternalLink>
				);
			})}
		</div>
	);
}

export interface IAlertViewDispatchProps {
	loadMessages: (request: ILoadFeedMessagesRequest) => any;
	logMessageFollow: (request: ILogFeedMessageFollowRequest) => any;
}

const mapStateToProps = ({
	user,
	feed,
	contracts,
	dashboard,
}: IAuthenticatedShellState): IAlertViewStateProps => {
	let alertMessages = feed.messages.filter(
		(m) => m.priority === "High" && m.status === "Active"
	);
	alertMessages = orderBy(alertMessages, [(m) => m.created], ["desc"]);

	return {
		user,
		isLoading: feed.loadingMessages,
		alertMessages,
		contract: contracts.selectedContract!,
		uiLanguage: dashboard.uiLanguage,
	};
};

export default connect(mapStateToProps, {
	loadMessages: feedActions.loadMessages.request,
	logMessageFollow: feedActions.logMessageFollow.request,
})(AlertView);
