import 'react-app-polyfill/ie11';
import "ie-array-find-polyfill";
import "./Polyfills/string-includes";
import "./Polyfills/string-startsWith";
import * as ReactDOM from 'react-dom';
import HeaderScripts from './HeaderScripts';
import App from './App';
import { Router } from 'react-router-dom';
import {browserHistory} from "./browserHistory";

ReactDOM.render(
	<Router history={browserHistory}>
		<HeaderScripts />
		<App />
	</Router>,
	document.getElementById('root') as HTMLElement
);
