import {useDispatch} from "react-redux";
import {authActions} from "src/Authentication/authenticationActions";
import {ResourceText} from "@kojamo/react-utils";

export default function ContractNotFound() {
	const dispatch = useDispatch();
	const logout = () => dispatch(authActions.logout.request());
	return (
			<div className="infopage">
				<p><strong><ResourceText textType="MarkdownInline"
										 resourceKey="ContractNotFound_Heading_Main"/></strong></p>

				<ResourceText resourceKey="ContractNotFound_Description_PageInfo" textType="Markdown"/>

				<p>
					<button onClick={logout}><ResourceText textType="MarkdownInline" resourceKey="ContractNotFound_Button_BackToStart"/>
					</button>
				</p>
			</div>
		);
}
