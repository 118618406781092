import InternalLink from "src/Components/InternalLink";
import { ResourceText } from '@kojamo/react-utils';
export interface IContractCancellationProps {
    isCancelled: boolean;
};

export function ContractCancellation(props: IContractCancellationProps) {

    const headingResourceKey: string = props.isCancelled ?
        "Dashboard_ContractCancellation_AlreadyCancelledHeading" :
        "Dashboard_ContractCancellation_Heading";

    const ingressResourceKey: string = props.isCancelled ?
        "Dashboard_ContractCancellation_AlreadyCancelledIngress" :
        "Dashboard_ContractCancellation_Ingress";

    return (
        <section className="contract-cancellation-contentBox">
            <h2>
                <ResourceText textType="MarkdownInline" resourceKey={headingResourceKey} />
            </h2>
            <p className="cancellation-ingress">
                {props.isCancelled}
                <ResourceText textType="MarkdownInline" resourceKey={ingressResourceKey} />
            </p>
            {!props.isCancelled ?
                <InternalLink className="button cancellation" app="Customer" to="cancel-contract">
                    <ResourceText textType="MarkdownInline" resourceKey="Dashboard_ContractCancellation_Button" />
                </InternalLink> : null}
        </section>);
}
