import * as React from 'react';
import { Helmet } from 'react-helmet';

function GTM() {
    return <Helmet>
        <script>
        {`
        <!-- Google Tag Manager -->
        (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                    'https://gtm.lumo.fi/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-5TGP2KZ');
        <!-- End Google Tag Manager -->
        `}
        </script>;
    </Helmet>
}

function Giosg() {
    return <Helmet>
        <script type="text/javascript">
        {`
		  <!-- Giosg tag -->
		  window.addEventListener(
  			"CookiebotOnAccept",
			function () {
			  (function(w, t, f) {
				var s='script',o='_giosg',h='https://service.giosg.com',e,n;e=t.createElement(s);e.async=1;e.src=h+'/live2/'+f;
				w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};w[o]._c=f;w[o]._h=h;n=t.getElementsByTagName(s)[0];n.parentNode.insertBefore(e,n);
			  })(window,document,"fab39dce-eaa9-11e5-ac82-00163ea5738e");
			},
			false
          );
          <!-- Giosg tag -->
        `}
        </script>
    </Helmet>
}

const HeaderScripts: React.FunctionComponent = () => (
	<>
		<GTM />
		<Giosg />
	</>
);

export default HeaderScripts;
