import { IEmbeddedUrl } from 'src/Pages/Embed/embedActions';
import orderBy from 'lodash.orderby';

const embeddedLinkUri =
	'https://schemas.kojamo.fi/customer-dashboard/conventions/embedded-view/';
const dashboardUrl =
	'shell:dashboard';

const getEmbeddedUrlPathSegment = (url: string): string => {
	if (!url.startsWith(embeddedLinkUri)) {
		throw new Error(`getEmbeddedUrlPathSegment expected an embed-URL, but got: ${url}`)
	}
	return url.substr(embeddedLinkUri.length);
}

const findUrl = (
	urls: IEmbeddedUrl[],
	predicate: (url: IEmbeddedUrl) => boolean,
	resultsSorter: (url: IEmbeddedUrl) => number,
	resultsSortDirection: 'asc' | 'desc'
): IEmbeddedUrl | null => {

	const matchingUrls = urls.filter(predicate);

	if (matchingUrls.length === 0) {
		return null;
	}

	if (matchingUrls.length === 1) {
		return matchingUrls[0];
	}

	const sorted = orderBy(matchingUrls, [(u) => resultsSorter(u)], [resultsSortDirection]);
	return sorted[0];
};

export const isDashboardUrl = (candidate: string) =>
	candidate.startsWith(dashboardUrl);

export const getDashboardUrlRelativePart = (url: string) =>
	url.replace(dashboardUrl, '');

export const isEmbedUrl = (candidate: string) =>
	candidate.startsWith(embeddedLinkUri);

export const getLocalizedPathForTile = (
	urls: IEmbeddedUrl[],
	targetUrl: string,
	app: string,
	language: string
) => {
	const localPart = getEmbeddedUrlPathSegment(targetUrl);

	const embeddedUrl = findUrl(
		urls,
		(u) => u.app === app && localPart.startsWith(u.name),
		(u) => u.name.length,
		'desc'
	);
	if (!embeddedUrl) {
		return null;
	}

	const trailingUrlPart = localPart.substr(embeddedUrl.name.length);
	const translatedPath = embeddedUrl.paths[language];
	return translatedPath + trailingUrlPart;
};

export const getEmbedUrlLocalizedPath = (embeddedUrl: IEmbeddedUrl, targetPath: string, language: string) => {
	const trailingUrlPart = targetPath.substr(embeddedUrl.targetUrl.length);
	const translatedPath = embeddedUrl.paths[language];
	if (!trailingUrlPart.length) {
		return translatedPath
	}
	return translatedPath + (trailingUrlPart.startsWith('/') ? trailingUrlPart : '/'+trailingUrlPart);
}

export const findUrlConfigurationByLocalizedUrl = (
	urls: IEmbeddedUrl[],
	inputUrl: string,
	language: string
) => {
	const matchedUrl = findUrl(urls,
		(u) => inputUrl.startsWith(u.paths[language]),
		(u) => u.paths[language].length,
		'desc'
	);

	return matchedUrl || null;
};

export const findUrlConfigurationByTargetUrl = (
	urls: IEmbeddedUrl[],
	targetUrl: string,
	language: string
) => {
	const matchedUrl = findUrl(urls,
		(u) => targetUrl.startsWith(u.targetUrl),
		(u) => u.paths[language].length,
		'desc'
	);

	return matchedUrl || null;
};

export const findUrlConfigurationByLogicalName = (
	urls: IEmbeddedUrl[],
	app: string,
	urlName: string
) => {
	const matchedUrls = urls.filter(u => u.app === app && u.name === urlName);
	if (matchedUrls.length == 0) {
		return null;
	}
	if (matchedUrls.length > 1) {
		return null;
	}

	return matchedUrls[0];

};


export const getTargetUrlByLocalizedPath = (
	matchedUrl: IEmbeddedUrl,
	inputUrl: string,
	language: string
) => {
	let trailingUrlPart = inputUrl.substr(matchedUrl.paths[language].length);
	if (trailingUrlPart.startsWith('/') && matchedUrl.targetUrl.endsWith('/')) {
		// No double-/
		trailingUrlPart = trailingUrlPart.substring(1);
	}
	return `${matchedUrl.targetUrl}${matchedUrl.languageByRouting ? `${language}/` : ''}${trailingUrlPart}`;
};

const getBaseUrlPrefix = (url: string) => {
	if (url.startsWith('https://')) {
		return 'https://';
	}

	if (url.startsWith('http://')) {
		return 'http://';
	}

	if (url.startsWith('//')) {
		return '//';
	}

	return '';
}

export const getBaseUrl = (url: string) => {
	const baseUrlPrefix = getBaseUrlPrefix(url);

	const schemelessUrl = baseUrlPrefix === '' ? url : url.substr(baseUrlPrefix.length);
	const pathStart = schemelessUrl.indexOf('/');
	const authorityPart = pathStart === -1 ? schemelessUrl : schemelessUrl.substr(0, pathStart);

	return baseUrlPrefix + authorityPart;
}
