import * as React from "react";
import { Route, Switch, RouteComponentProps, withRouter } from "react-router-dom";
import "./Styles/style.scss";
import { createStoreWithState } from "./reduxStore";
import { Provider } from "react-redux";
import OidcCallback from "./Pages/OidcCallback";
import { appActions } from './appActions';
import { UnregisterCallback } from 'history';
import { trackPageView } from './Telemetry';
import AppRoutes from './AppRoutes';
import Cookies from "universal-cookie";
import * as QueryString from 'query-string';
import { CookieBotStylesLink } from "./Components/CookiebotStylesLink/CookieBotStylesLink";

import {initialDashboardState} from "./Pages/Dashboard/dashboardReducer";
import {ResourceTextProvider} from "./Common/Localization/ResourceTextProvider";

const store = createStoreWithState({
	dashboard: initialDashboardState,
});

// Whichever page we are on, let's first try to refresh the current user session
store.dispatch(appActions.init());

declare global {
	interface Window {
		Cookiebot?: any;
	}
}

class App extends React.Component<RouteComponentProps<any>> {
	private historyChangeCallback?: UnregisterCallback;

	constructor(props: RouteComponentProps<any>, context?: any) {
		super(props, context);
	}

	setVh = () => {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	};

	handleResize = () => {
		this.setVh();
	};

	handleOrientationChange = () => {
		setTimeout(this.setVh, 200);
	};
	public componentWillMount() {
		this.props.history.listen(this.locationChanged);
	}

	public componentDidMount() {
		this.setVh();
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('orientationchange', this.handleOrientationChange);
		window.addEventListener(
			"CookiebotOnAccept",
			this.onCookieBotAcceptChanged
		);
	}

	public componentWillUnmount() {
		if (this.historyChangeCallback) {
			this.historyChangeCallback();
		}
		window.removeEventListener('resize', this.handleResize);
		window.removeEventListener('orientationchange', this.handleOrientationChange);
	}

	public render() {
		return (
			<Provider store={store}>
				<CookieBotStylesLink/>
				<ResourceTextProvider>
					<Switch>
						<Route exact path="/openid-connect/silent">
							<OidcCallback silent />
						</Route>

						<AppRoutes />
					</Switch>
				</ResourceTextProvider>
			</Provider>
		);
	}

	onCookieBotAcceptChanged = () => {
		const areStatisticsCookiesAllowed =
			window.Cookiebot?.consent.statistics === true;
		if (areStatisticsCookiesAllowed && this.props.location.search.length) {
			const qs = QueryString.parse(this.props.location.search);
			const utmParams = Object.keys(qs).filter((k) =>
				k.startsWith("utm_")
			);
			if (utmParams.length) {
				const utmParamsObj = {};
				// Backend expects cookie to be similar format as query string, in which same key can have multiple values
				for (const key of utmParams) {
					const val = qs[key];
					// @ts-expect-error: We know this is an array or we make it one
					let utmParam: (typeof val)[] | undefined = utmParamsObj[key];
					if (utmParam) {
						utmParam.push(val);
					} else {
						utmParam = [qs[key]];
					}
				}
				const cookies = new Cookies();
				cookies.set("CampaignData", JSON.stringify(utmParamsObj), {
					path: "/",
				});
			}
		}
	}

	private locationChanged() {
		trackPageView();
	}
}

export default withRouter(App);
