import {Button, Icon, IconBell, SpacingContainer} from "@kojamo/lumo-dls";
import {authenticatedRoutes} from "../../Routing/authenticatedRoutes";
import {
	MyLumoLogo, TopNavigationContractQuickLink,
	navigationLinkButtonClasses,
	topNavigationLinkColorClasses, TopNavigationProfileMenu,
	TopNavigationReservationsMenu
} from "./NavigationShared";
import {Link} from "react-router-dom";
import {useAppLanguage} from "../../Common/Localization/useAppLanguage";
import {useContract} from "../../Routing/ContractContext/useContract";
import {LinkWithSelectedState} from "./LinkWithSelectedState";

export function TopNavigationMobile() {
	const {contracts} = useContract();
	return (
		<div className="l-visible-mobile-only">
			<TopNavigationMobileUpperRow />
			{contracts.length > 1 &&
			<TopNavigationMobileLowerRow />
			}
		</div>
	);
}

export function TopNavigationMobileUpperRow() {
	const uiLanguage = useAppLanguage();
	return (
		<SpacingContainer justify="edges" className="l-background-brand-background-light l-padding-3-x l-padding-2-y">
			<SpacingContainer>
				<LinkWithSelectedState to={authenticatedRoutes.announcementsArchive}>
					<IconBell className={topNavigationLinkColorClasses}/>
				</LinkWithSelectedState>
				<TopNavigationReservationsMenu isMobile />
			</SpacingContainer>
			<Link to={authenticatedRoutes.frontpage[uiLanguage]} className={navigationLinkButtonClasses}>
				<MyLumoLogo/>
			</Link>
			<div>
				<Button variant="text" disabled>
					{/* This is here to position the logo to center */}
					<Icon />
				</Button>
				<TopNavigationProfileMenu isMobile />
			</div>
		</SpacingContainer>
	);
}

export function TopNavigationMobileLowerRow() {
	return (
		<SpacingContainer justify="center" className="l-background-brand-background-lighter">
			<TopNavigationContractQuickLink isSmall />
		</SpacingContainer>
	);
}
