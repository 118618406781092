import React from 'react';
import InternalLink from './InternalLink';
import { ResourceText } from "@kojamo/react-utils";
import {ResourceLink} from "./ResourceLink";




export interface IChangeApartmentProps {
	onCloseRequested?: () => void;
}

export class ChangeApartment extends React.Component<IChangeApartmentProps> {

	public render() {

		const anchors = document.getElementsByClassName("header-extension");
		let overlayStyle = {};

		const overlayTop = Math.max(...Array.from(anchors).map(c => c.getBoundingClientRect().bottom));
		overlayStyle = { paddingTop: overlayTop + 'px' };

		return (
			<section className="overlay" style={overlayStyle}
					 onClick={this.onlyCloseOverlay}>
				<div className="card"
					 onClick={this.suppressClicks}>

					<a className="close-button" onClick={this.onlyCloseOverlay} > <span className="lines" />
					</a>
					<ol>
						<li>
							<p>
								<strong><ResourceText textType="MarkdownInline" resourceKey={"ChangeApartment_Text_ChooseAndRent"}/></strong>{' '}
								<ResourceText textType="MarkdownInline" resourceKey={"ChangeApartment_Description_NewHomeFromWebStore"} />
							</p>
						</li>
						<li>
							<p><strong><ResourceText textType="MarkdownInline" resourceKey={"ChangeApartment_Text_Terminate"}/> </strong> <ResourceText textType="MarkdownInline" resourceKey={"ChangeApartment_Text_CurrentApartment"}/>
								<InternalLink app="Termination" to="termination" onClick={this.closeOverlay}>
									<ResourceText resourceKey="ChangeApartment_Link_Here" textType="MarkdownInline"/> ›
								</InternalLink></p>
							<p><ResourceText resourceKey={"ChangeApartment_Description_ChangeInfo1"}/></p>
							<p><ResourceText resourceKey={"ChangeApartment_Description_ChangeInfo2"}/></p>
						</li>
						<li>
							<p>
								<strong><ResourceText textType="MarkdownInline" resourceKey={"ChangeApartment_Text_MoveIntoYourNewHome"}/></strong>{' '}
								<ResourceText textType="MarkdownInline" resourceKey={"ChangeApartment_Text_EnjoyBenefits"}/>
							</p>
						</li>
					</ol>
					<ResourceLink className="button" hrefKey={"ChangeApartment_Url_ContinueToWebStore"} resourceKey={"ChangeApartment_Link_ContinueToWebStore"} />
				</div>
			</section>
		);
	}

	private suppressClicks = (e: React.MouseEvent) => {
		e.stopPropagation();
	}

	private onlyCloseOverlay = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		this.closeOverlay();
	}

	private closeOverlay = () => {
		if (this.props.onCloseRequested) {
			this.props.onCloseRequested();
		}
	}
}
