import React from "react";
import { useResource, ResourceText } from "@kojamo/react-utils";
import { useSelector } from 'react-redux';
import {IAuthenticatedShellState} from "../../rootReducer";


interface ApartmentLiftLinkProps {
	resourceSuffix: string;
	onClick?: (e: React.MouseEvent<HTMLElement>, linkTarget: string) => void;
}

const ApartmentLiftLink: React.FC<ApartmentLiftLinkProps> = ({resourceSuffix}) => {

	const { settings } = useSelector((state: IAuthenticatedShellState) => state);
	const {getResourceImageUrlByKey, getResourceText} = useResource();

	const commonUiBaseUrl = settings.commonUiBaseUrl;

	const linkTargetKey = `Dashboard_ApartmentLifts_Url_${resourceSuffix}`;
	const linkTextKey = `Dashboard_ApartmentLifts_Link_${resourceSuffix}`;
	const headingKey = `Dashboard_ApartmentLifts_Heading_${resourceSuffix}`;
	const descriptionKey = `Dashboard_ApartmentLifts_Description_${resourceSuffix}`;
	const imageKey = `Dashboard_ApartmentLifts_Image_${resourceSuffix}`;


	const imageUrl = getResourceImageUrlByKey(commonUiBaseUrl, imageKey);
	const link = getResourceText(linkTargetKey);

	return (
		<li
			className="lift-item clickable"
			style={{ backgroundImage: `url(${imageUrl})` }}
			onClick={() => (window.location.href = `${link}`)}
		>
			<div className="overlay-mask">
				<p>
					<ResourceText textType="MarkdownInline" resourceKey={headingKey} />
				</p>
				<p className="details">
					<ResourceText textType="MarkdownInline" resourceKey={descriptionKey} />
				</p>
				<a
					className="button"
					href={link}
				>
					<ResourceText textType="MarkdownInline" resourceKey={linkTextKey} />
				</a>
			</div>
		</li>
	);
};
export default ApartmentLiftLink;
