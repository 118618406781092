import * as React from "react";
import {useEffect} from "react";
import {Route, RouteComponentProps, Switch, useLocation, withRouter} from "react-router";
import OidcCallback from "./Pages/OidcCallback";

import DashboardPage from "./Pages/Dashboard/Dashboard";
import NotFoundPage from "./Pages/NotFound";
import IndexPage from "./Pages/IndexPage";
import EmbedPage from "./Pages/Embed/EmbedPage";

import AuthenticatedRoute from "./Routing/AuthenticatedRoute";

import OidcLogoutCallback from "./Pages/OidcLogoutCallback";
import ContractContext from "./Routing/ContractContext/ContractContext";
import FeedHistory from "./Pages/Feed/FeedHistory";

import ContactInformationPage from "./Pages/AnonymousUsers/ContactInformationPage";
import FindMaintenanceContactPage from "./Pages/AnonymousUsers/FindMaintenanceContactPage";

import {anonymousRoutes} from "./Routing/anonymousRoutes";
import LanguageSpecificRoute from "./Routing/LanguageSpecificRoute";
import SiteSurvey from "./Components/SiteSurvey";
import SurveyCompletePage from "./Pages/SurveyCompletePage";
import {IShellState, UILanguage} from "./rootReducer";
import {connect, useDispatch} from "react-redux";

import {Redirect} from "react-router-dom";
import {BenefitRedirect} from "./Components/BenefitRedirect";
import {dashboardActions} from "./Pages/Dashboard/dashboardActions";
import {authenticatedRoutes, contractNumber} from "./Routing/authenticatedRoutes";
import {closeGiosgChats, sessionStorageUserIdKey} from "./lib/giosg";

function ignoreUser(props: any) {
	const allProps = props;
	const { user, ...result } = allProps;
	return result;
}

interface IStateProps {
	isAuthenticated: boolean;
	uiLanguage: UILanguage;
	userId?: string;
}

type Props = IStateProps & RouteComponentProps<any>;

function AppRoutes(props: Props) {
	const { history, uiLanguage, isAuthenticated, userId } = props;
	const {pathname} = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		const newLanguage = pathname.includes("/en") ? "en" : "fi"
		if (uiLanguage !== newLanguage) {
			dispatch(dashboardActions.switchLanguage(newLanguage));
		}
	}, [pathname.includes("/en")]);

	useEffect(() => {
		const interval = setInterval(() => {
			const previousUserId = sessionStorage.getItem(
				sessionStorageUserIdKey
			);
			if (previousUserId !== userId) {
				closeGiosgChats();
			}
		}, 3000);

		return () => clearInterval(interval);
	}, [userId]);

	return (
		<Switch>
			<Route exact path="/openid-connect/interactive">
				<OidcCallback />
			</Route>
			<Route exact path="/openid-connect/logout">
				<OidcLogoutCallback />
			</Route>

			<LanguageSpecificRoute
				language="en"
				exact
				path={anonymousRoutes.root.en}
				component={IndexPage}
			/>
			<LanguageSpecificRoute
				language="en"
				exact
				path={anonymousRoutes.contact.en}
				component={ContactInformationPage}
			/>
			<LanguageSpecificRoute
				language="en"
				exact
				path={anonymousRoutes.maintenanceInformation.en}
				component={FindMaintenanceContactPage}
			/>
			<LanguageSpecificRoute
				language="en"
				exact
				path={anonymousRoutes.serviceRequest.en}
				render={(props) => {
					return (
						<EmbedPage
							hasAnonymousNavigation={true}
							simpleLayout={true}
							keepMatchUrlInPath={true}
							useContractId={false}
							{...ignoreUser(props)}
						/>
					);
				}}
			/>
			<LanguageSpecificRoute
				language="en"
				exact
				path={anonymousRoutes.contactPreferences.en}
				render={(props) => {
					return (
						<EmbedPage
							simpleLayout={true}
							keepMatchUrlInPath={true}
							useContractId={false}
							{...ignoreUser(props)}
						/>
					);
				}}
			/>
			<LanguageSpecificRoute
				language="en"
				exact
				path={anonymousRoutes.announcement.en}
				render={(props) => {
					return (
						<EmbedPage
							simpleLayout={true}
							keepMatchUrlInPath={true}
							useContractId={false}
							{...ignoreUser(props)}
						/>
					);
				}}
			/>

			{!isAuthenticated && (
				<LanguageSpecificRoute
					language="en"
					path={anonymousRoutes.parkingSpaces.en}
					render={(props) => {
						return (
							<EmbedPage
								keepMatchUrlInPath={true}
								useContractId={false}
								{...ignoreUser(props)}
							/>
						);
					}}
				/>
			)}

			<LanguageSpecificRoute
				language="fi"
				exact
				path={anonymousRoutes.root.fi}
				component={IndexPage}
			/>
			<LanguageSpecificRoute
				language="fi"
				exact
				path={anonymousRoutes.contact.fi}
				component={ContactInformationPage}
			/>
			<LanguageSpecificRoute
				language="fi"
				exact
				path={anonymousRoutes.maintenanceInformation.fi}
				component={FindMaintenanceContactPage}
			/>
			<LanguageSpecificRoute
				language="fi"
				exact
				path={anonymousRoutes.serviceRequest.fi}
				render={(props) => {
					return (
						<EmbedPage
							hasAnonymousNavigation={true}
							simpleLayout={true}
							keepMatchUrlInPath={true}
							useContractId={false}
							{...ignoreUser(props)}
						/>
					);
				}}
			/>
			<LanguageSpecificRoute
				language="fi"
				exact
				path={anonymousRoutes.contactPreferences.fi}
				render={(props) => {
					return (
						<EmbedPage
							simpleLayout={true}
							keepMatchUrlInPath={true}
							useContractId={false}
							{...ignoreUser(props)}
						/>
					);
				}}
			/>
			<LanguageSpecificRoute
				language="fi"
				exact
				path={anonymousRoutes.announcement.fi}
				render={(props) => {
					return (
						<EmbedPage
							simpleLayout={true}
							keepMatchUrlInPath={true}
							useContractId={false}
							{...ignoreUser(props)}
						/>
					);
				}}
			/>
			{!isAuthenticated && (
				<LanguageSpecificRoute
					language="fi"
					path={anonymousRoutes.parkingSpaces.fi}
					render={(props) => {
						return (
							<EmbedPage
								keepMatchUrlInPath={true}
								useContractId={false}
								{...ignoreUser(props)}
							/>
						);
					}}
				/>
			)}

			<AuthenticatedRoute>
				<ContractContext>
					<Switch>
						<AuthenticatedRoute
							exact
							path={authenticatedRoutes.welcome.fi}
							render={(props) => {
								return (
									<EmbedPage
										simpleLayout={true}
										keepMatchUrlInPath={true}
										useContractId={false}
										{...ignoreUser(props)}
									/>
								);
							}}
						/>

						<AuthenticatedRoute
							exact
							path={authenticatedRoutes.welcome.en}
							render={(props) => {
								return (
									<EmbedPage
										simpleLayout={true}
										keepMatchUrlInPath={true}
										useContractId={false}
										{...ignoreUser(props)}
									/>
								);
							}}
						/>
						{/* Login page to trigger authentication from inside parkingspace app */}
						<AuthenticatedRoute
							exact
							path={authenticatedRoutes.parkingSpacesLoginRoot.fi}
							render={(props) => {
								const contractNumber =
									props.match.params.contractNumber;
								const lang = uiLanguage;
								const page =
									lang === "fi"
										? "autopaikat"
										: "en/parkingspaces";
								const target = `/${contractNumber}/${page}`;
								return <Redirect to={target} />;
							}}
						/>
						<AuthenticatedRoute
							exact
							path={authenticatedRoutes.parkingSpacesLoginRoot.en}
							render={(props) => {
								const contractNumber =
									props.match.params.contractNumber;
								const lang = uiLanguage;
								const page =
									lang === "fi"
										? "autopaikat"
										: "en/parkingspaces";
								const target = `/${contractNumber}/${page}`;
								return <Redirect to={target} />;
							}}
						/>
						<React.Fragment>
							<SiteSurvey
								currentPath={history.location.pathname}
							/>
							<Switch>

								<AuthenticatedRoute
									path={authenticatedRoutes.frontpageRoot.fi}
									component={DashboardPage}
								/>

								<AuthenticatedRoute
									path={authenticatedRoutes.frontpageRoot.en}
									component={DashboardPage}
								/>

								<AuthenticatedRoute
									path={authenticatedRoutes.messageHistoryRoot.fi}
									component={FeedHistory}
								/>

								<AuthenticatedRoute
									path={authenticatedRoutes.messageHistoryRoot.en}
									component={FeedHistory}
								/>

								<AuthenticatedRoute
									path={authenticatedRoutes.surveyCompleteRoot.fi}
									component={SurveyCompletePage}
								/>

								<AuthenticatedRoute
									path={authenticatedRoutes.surveyCompleteRoot.en}
									component={SurveyCompletePage}
								/>

								<AuthenticatedRoute
									path={authenticatedRoutes.benefitsRoot.fi}
									component={BenefitRedirect}
								/>

								<AuthenticatedRoute
									path={authenticatedRoutes.benefitsRoot.en}
									component={BenefitRedirect}
								/>

								<AuthenticatedRoute
									path={`/${contractNumber}/`}
									render={(props) => {
										return (
											<EmbedPage
												useContractId={true}
												{...ignoreUser(props)}
											/>
										);
									}}
								/>
								<Route component={NotFoundPage} />
							</Switch>
						</React.Fragment>
					</Switch>
				</ContractContext>
			</AuthenticatedRoute>
		</Switch>
	);
}

export default withRouter(
	connect(({ user, dashboard }: IShellState) => ({
		isAuthenticated: !!user,
		userId: user?.userId,
		uiLanguage: dashboard.uiLanguage,
	}))(AppRoutes)
);
