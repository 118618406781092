import {connect} from "react-redux";
import React from "react";
import {authActions} from "../Authentication/authenticationActions";
import {IShellState, IUserContext} from "../rootReducer";
import Layout from "../Layout";
import {ResourceText} from "@kojamo/react-utils";

interface ISurveyCompleteStateProps {
	user: IUserContext;
}

type SurveyCompletePageProps = ISurveyCompleteStateProps & DispatchProps;

class SurveyCompletePage extends React.Component<SurveyCompletePageProps> {
	constructor(props: SurveyCompletePageProps) {
		super(props);
	}

	public render() {
		return (<Layout pageIdentifier="surveyComplete" isSimple={true}>
			<div className="surveyComplete-root">
				<ResourceText textType="MarkdownInline" resourceKey="SurveyComplete_Content" />
			</div>
		</Layout>);
	}

	public componentDidMount() {
		this.props.saveSiteSurveyAction({user: this.props.user, customerCompletedSurvey: true});
		// The survey is opened in popup so it should be possible to programmatically close the window.
		window.close();
	}
}

const dispatchProps = {
	saveSiteSurveyAction: authActions.saveSiteSurveyAction.request,
};
type DispatchProps = typeof dispatchProps;


export default connect(({user}: IShellState) => ({
	user: user as IUserContext
}), {
	saveSiteSurveyAction: authActions.saveSiteSurveyAction.request,
})(SurveyCompletePage);
