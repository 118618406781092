import {FC, useEffect, useState, useRef} from 'react';
import { ResourceText } from "@kojamo/react-utils";
import {useResource} from "@kojamo/react-utils";
import {useSelector} from "react-redux";
import {IAuthenticatedShellState} from "../rootReducer";
import _ from 'lodash';

interface IFaqItemData {
	title: string;
	url: string;
}

const FaqItem: FC<IFaqItemData> = ({ title, url }) => (
	<li className="mod-faqLifts__listItem">
		<a className="mod-faqLifts__listItemLink" href={url}>
			{title}
		</a>
	</li>
);

const splitToLink = (line: string): IFaqItemData => {
	const parts = line.split('=');
	return { title: parts[0].trim(), url: line.substring(parts[0].length + 1).trim() };
};

const parseFaqItems = (input: string): IFaqItemData[] =>
	input
		.replace('\r\n', '\n')
		.split('\n')
		.map(mi => splitToLink(mi));

const FaqList: FC = () => {
	const [faqItems, setFaqItems] = useState<IFaqItemData[]>([]);
	const {getResourceText} = useResource();

	const language = useSelector((state: IAuthenticatedShellState) => state.dashboard.uiLanguage);
	const prevLanguage = useRef(language);

	useEffect(() => {

			const faqItemData = getResourceText('FaqList_Links');
			const parsedItems = parseFaqItems(faqItemData);
			if (!_.isEqual(parsedItems, faqItems)) {
				setFaqItems(parsedItems);
			}
			prevLanguage.current = language;

	}, [getResourceText, language, faqItems]);

	return (
		<div className="mod-faqLifts">
			<div className="mod-faqLifts__wrapper">
				<div className="mod-faqLifts__headingWrapper">
					<div className="mod-faqLifts__iconWrapper">
						<svg
							className="mod-faqLifts__icon"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 56 56"
						>
							<g fill="none" fillRule="evenodd" transform="translate(1 1)">
								<circle
									cx="27"
									cy="27"
									r="26.8"
									stroke="#F05183"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								/>
								<circle cx="26.5" cy="40.5" r="1.5" fill="#F05183" fillRule="nonzero" />
								<path
									stroke="#F05183"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M21.7725217,13.7547826 C26.5933913,11.6243478 32.8313043,11.8775652 34.9617391,15.3252174 C37.0921739,18.7728696 35.6215652,22.7829565 31.9693913,25.8775652 C28.3172174,28.9721739 27,30.6521739 27,33.0869565"
								/>
							</g>
						</svg>
					</div>
					<h2 className="mod-faqLifts__heading">
						<ResourceText textType="MarkdownInline" resourceKey="FaqList_Heading_Main" />
					</h2>
					<p className="mod-faqLifts__text">
						<ResourceText textType="MarkdownInline" resourceKey="FaqList_InfoText" />
					</p>
				</div>
				<ul className="mod-faqLifts__list">
					{
						faqItems.map(fi =>
							<FaqItem key={fi.title} title={fi.title} url={fi.url} />
						)
					}
				</ul>
				<div className="mod-faqLifts__cta">
					<a href={getResourceText('FaqList_AllQuestions_Url')} className="mod-faqLifts__cta-button atom-button">
							<span>
								<ResourceText textType="MarkdownInline" resourceKey="FaqList_AllQuestions_Link" />
							</span>
					</a>
				</div>
			</div>
		</div>
	);
}

export default FaqList;
