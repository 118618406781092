import React from 'react';
import { useSelector } from 'react-redux';
import {ResourceText} from "@kojamo/react-utils";
import {IAuthenticatedShellState} from "../../rootReducer";


const AgreementAndPaymentsInfo: React.FC = () => {
	const welcomePage = useSelector((state: IAuthenticatedShellState) => state.dashboard.welcomePage);
	const user = useSelector((state: IAuthenticatedShellState) => state.user);

	if (welcomePage == null) {
		return null;
	}

	return (
		<section className="mod-contentBox">
			<div className="mod-contentBox__wrapper">
				<div className="mod-contentBox__textBlock">
					<h2>
						<ResourceText textType="MarkdownInline" resourceKey="Dashboard_Agreement_Heading" />
					</h2>
					<p>
						<ResourceText textType="MarkdownInline" resourceKey="Dashboard_Agreement_DueDateInfo" />
					</p>
				</div>
				<div className="mod-contentBox__textBlock">
					<div className="atom-downloadMaterial__box -bgPink">
						<svg className="atom-downloadMaterial__icon" width="33" height="30" viewBox="0 0 33 30" xmlns="http://www.w3.org/2000/svg">
							<g stroke="#F05183" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
								<path d="M9.5 28.5h-5a3 3 0 01-3-3v-24h11l3 5h16v19a3 3 0 01-3 3h-5M16.5 28.5v-14" />
								<path d="M21.5 23.5l-5 5-5-5" />
							</g>
						</svg>
						<a className="atom-downloadMaterial__link" target="_blank" href={welcomePage.contractDownloadLink}>
							<ResourceText textType="MarkdownInline" resourceKey="Dashboard_Agreement_Link_DownloadContract" />
						</a>
					</div>
					<div className="atom-downloadMaterial__box -bgPink">
						<svg className="atom-downloadMaterial__icon" width="33" height="30" viewBox="0 0 33 30" xmlns="http://www.w3.org/2000/svg">
							<g stroke="#F05183" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
								<path d="M9.5 28.5h-5a3 3 0 01-3-3v-24h11l3 5h16v19a3 3 0 01-3 3h-5M16.5 28.5v-14" />
								<path d="M21.5 23.5l-5 5-5-5" />
							</g>
						</svg>
						<a className="atom-downloadMaterial__link" target="_blank" href={welcomePage.paymentListDownloadLink}>
							<ResourceText textType="MarkdownInline" resourceKey="Dashboard_Agreement_Link_DownloadNextInvoice" />
						</a>
					</div>
				</div>
				<div className="mod-contentBox__textBlock">
					<div className="atom-infoBoxAlt -noPadding -alignCenter">
						<svg className="atom-infoBoxAlt__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
							<g fill="none" fillRule="evenodd" transform="translate(-70 -1623)">
								<path fill="#002663" d="M0 0h375v5343H0z" />
								<rect width="340" height="3153" x="18" y="85" fill="#002663" rx="10" />
								<g transform="translate(43 1585)">
									<rect width="273" height="233" fill="#FFF" rx="10" />
									<path fill="#002663" fillRule="nonzero" d="M66.0571 50.5143l-4.7714-1.7V38H32.7143v10.8143l-4.7714 1.7A1.4282 1.4282 0 0027 51.857V75.143C27 76.7209 28.2792 78 29.8571 78H64.143C65.7209 78 67 76.7208 67 75.1429V51.857a1.4282 1.4282 0 00-.9429-1.3428zM59.743 39.5429v17.4857L47.7714 64.657a1.4286 1.4286 0 01-1.5428 0L34.257 57.0286V39.5429H59.743zM28.3143 51.857L32.6 50.3143v5.8143l-1.0714-.6857a1.4286 1.4286 0 00-.6572-.3H28.343l-.0286-3.2858zm34.8143 3.2858a1.4286 1.4286 0 00-.7715.2285l-1.0714.6857V50.243l4.2857 1.5428v3.3572h-2.4428zm-13.2715-10H37v-1.4286h12.8571v1.4286zm5.7143 4.2857H37V48h18.5714v1.4286zm-2.8571 4.2857H37v-1.4286h15.7143v1.4286z" />
								</g>
							</g>
						</svg>
						<div className="atom-card__text">
							<p>
								<ResourceText textType="MarkdownInline" resourceKey="Dashboard_Agreement_DocumentsEmailed" />
							</p>
							<p>
								<strong>
									{user.email}
								</strong>
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AgreementAndPaymentsInfo;
