import * as React from "react";
import {connect} from "react-redux";
import {authActions, ISaveSiteSurveyActionRequest} from "./Authentication/authenticationActions";
import AlertView from './Components/Feed/AlertView';
import {IShellState, UILanguage} from './rootReducer';
import {dashboardActions, ILoadGiosgTokenRequest} from './Pages/Dashboard/dashboardActions';
import {ChangeApartment} from './Components/ChangeApartment';
import {RouteComponentProps, withRouter} from 'react-router';
import SiteSurveyDialog from './Components/SiteSurveyDialog';
import DirectRentalSurveyDialog from "./Components/DirectRentalSurveyDialog";
import {embedActions} from "./Pages/Embed/embedActions";

import {addTelemetryEventGA4, GA4EventCategory, GA4EventType} from "./telemetryGA4";
import {ILayoutState} from "./ILayoutState";
import {ILayoutStateProps} from "./ILayoutStateProps";
import {getTranslatedUrl} from "./Routing/getTranslatedUrl";
import {TopNavigation} from "./Components/Navigation/TopNavigation";
import {BottomNavigationMobile} from "./Components/Navigation/BottomNavigationMobile";
import {NavigationProvider} from "./Components/Navigation/NavigationProvider";
import {mainContentId} from "./Components/Navigation/mainContentId";

export interface ILayoutOwnProps {
	isSimple?: boolean;
	top?: JSX.Element;
	showFooter?: boolean;
	className?: string;
	pageIdentifier: string;
	showDirectRentalSurveyPrompt?: boolean;
	children?: React.ReactNode | React.ReactNode[];
}

export type LayoutProps = ILayoutOwnProps &
	ILayoutStateProps &
	ILayoutDispatchProps &
	RouteComponentProps<any>;

export function setPageClass(pageIdentifier: string) {

	const pageIdentifierClass = "page-" + pageIdentifier;
	const currentClasses = document.body.classList;
	if (!currentClasses.contains(pageIdentifierClass)) {
		currentClasses.forEach((value: string, key: number, parent) => {
			if (value.startsWith("page-")) {
				parent.remove(value);
			}
		});
		currentClasses.add(pageIdentifierClass);
	}
}

export function setHtmlLang(language: UILanguage) {
	if (document && document.documentElement) {
		document.documentElement.lang = language;
	}
}

class Layout extends React.Component<LayoutProps, ILayoutState> {
	constructor(props: LayoutProps, context?: any) {
		super(props, context);

		this.state = { userMenuOpen: false, mobileNavOpen: false, contentScrollTop: 0, reservationsMenuOpen: false };
	}

	public componentDidMount() {
		window.addEventListener("message", this.receiveMessage, false);

		this.ensureApartmentChangeModalVisibility();

		if (this.props.user && this.props.contract) {
			this.props.loadGiosgToken({ user: this.props.user, contractId: this.props.contract.contractId });
		}

		if (this.props.showFooter && !this.props.fetchingFooter && this.props.footer === null) {
			this.props.fetchFooter();
		}
	}

	public componentDidUpdate(prevProps: LayoutProps) {
		this.ensureApartmentChangeModalVisibility();

		if (this.props.showFooter && prevProps.uiLanguage !== this.props.uiLanguage) {
			this.props.fetchFooter();
		}
	}

	public componentWillUnmount() {
		window.removeEventListener("message", this.receiveMessage, false);
	}

	public render() {
		setPageClass(this.props.pageIdentifier);
		setHtmlLang(this.props.uiLanguage);

		return (
			<NavigationProvider>
				{this.props.changeApartmentModalVisible && <ChangeApartment onCloseRequested={this.closeApartmentChangeOverlay} />}
				{this.props.siteSurveyModalVisible && <SiteSurveyDialog onOpenSurveyRequested={this.onOpenSiteSurveyRequested} onCloseRequested={this.closeSiteSurveyOverlay} />}
				{this.props.showDirectRentalSurveyPrompt && <DirectRentalSurveyDialog onCloseRequested={this.onCloseDirectRentalSurveyRequested} onOpenSurveyRequested={this.onOpenDirectRentalSurveyRequested} />}

				{!this.props.isSimple && this.props.user && this.props.contract?.contractId && <AlertView />}

				<TopNavigation />

				{this.props.top}

				{this.props.children && (
					<main id={mainContentId} className={`container body-content with-navigation ${this.props.className || ''}`}>
						{this.props.children}
						{this.props.showFooter && this.props.footer && (
							<footer dangerouslySetInnerHTML={{__html: this.props.footer}} />
						)}
					</main>
				)}

				<BottomNavigationMobile />
			</NavigationProvider>
		);
	}

	private onOpenDirectRentalSurveyRequested = () => {
		this.props.setDirectRentalSurveyModalVisibility(false);
	}

	private onCloseDirectRentalSurveyRequested = () => {
		this.props.setDirectRentalSurveyModalVisibility(false);
	}

	private ensureApartmentChangeModalVisibility() {
		if (window.location.hash.indexOf('asunnonvaihto') > -1) {
			this.props.setChangeApartmentModalVisibility(true);
		}
	}

	private closeApartmentChangeOverlay = () => {
		this.props.history.replace('#');

		this.props.setChangeApartmentModalVisibility(false);
	}

	private onOpenSiteSurveyRequested = () => {
		this.props.saveSiteSurveyAction({ user: this.props.user!, customerContinuedToSurvey: true });
		this.props.setSiteSurveyModalVisibility(false);
	}

	private closeSiteSurveyOverlay = () => {
		this.props.saveSiteSurveyAction({ user: this.props.user!, customerContinuedToSurvey: false });
		this.props.setSiteSurveyModalVisibility(false);
	}

	private receiveMessage = (event: any) => {
		if (event.data === "open-apartment-change") {
			this.props.setChangeApartmentModalVisibility(true);
		} else if (event.data.toString().startsWith("bottomNav-")) {
			const linkKey = event.data.toString().substring(10);
			this.sendBottomNavigationLinkTelemetry(linkKey);
		} else if (event.data.toString().startsWith('language-')) {
			const newLang: UILanguage = event.data.toString().substring(9) === 'en' ? 'en' : 'fi';
			const currentLang: UILanguage = this.props.uiLanguage
			if (newLang !== currentLang) {
				this.setCurrentLanguage(newLang);
			}
		}
	}

	private sendBottomNavigationLinkTelemetry(link: string) {
		addTelemetryEventGA4({event: GA4EventType.ui_clicks, category: GA4EventCategory.footernav, action: link as any});
	}

	private setCurrentLanguage(language: UILanguage) {
		const isLoggedIn = !!this.props.user;
		if (isLoggedIn) {
			this.props.switchLanguage(language);
		}
		this.props.history.push(getTranslatedUrl(this.props.location.pathname, language, isLoggedIn));
	}
}

export interface ILayoutDispatchProps {
	setChangeApartmentModalVisibility: (visible: boolean) => any;
	setSiteSurveyModalVisibility: (visible: boolean) => any;
	switchLanguage: (language: UILanguage) => any;
	loadGiosgToken: (Request: ILoadGiosgTokenRequest) => any;
	saveSiteSurveyAction: (request: ISaveSiteSurveyActionRequest) => any;
	setDirectRentalSurveyModalVisibility: typeof dashboardActions.setDirectRentalSurveyModalVisibility;
	fetchFooter: typeof embedActions.fetchFooter.request;
}

const mapStateToProps = ({
	user,
	dashboard,
	contracts,
	embedding: {footer, fetchingFooter},
}: IShellState): ILayoutStateProps => ({
	user,
	contract: contracts.selectedContract,
	changeApartmentModalVisible: dashboard.changeApartmentModalVisible,
	siteSurveyModalVisible: dashboard.siteSurveyModalVisible,
	uiLanguage: dashboard.uiLanguage,
	footer,
	fetchingFooter,
});

export default connect(
	mapStateToProps,
	{
		setChangeApartmentModalVisibility: dashboardActions.setChangeApartmentModalVisibility,
		setSiteSurveyModalVisibility: dashboardActions.setSiteSurveyModalVisibility,
		switchLanguage: dashboardActions.switchLanguage,
		loadGiosgToken: dashboardActions.loadGiosgToken.request,
		saveSiteSurveyAction: authActions.saveSiteSurveyAction.request,
		setDirectRentalSurveyModalVisibility: dashboardActions.setDirectRentalSurveyModalVisibility,
		fetchFooter: embedActions.fetchFooter.request,
	}
)(withRouter(Layout));

