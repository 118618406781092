import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { ISettingsState } from '../rootReducer';
import { SettingsActionTypes, settingsActions } from './settingsActions';


const initialState: ISettingsState = {
	loaded: false,
	languageCookieDomain: null,
	commonUiBaseUrl: undefined,
	cdnBaseUrl: undefined,
	assetsBaseUrl: undefined,
	rentalApartmentStoreBaseUrl: undefined
}

export const settingsReducer: Reducer<ISettingsState, SettingsActionTypes> = (state = initialState, action) => {
	switch (action.type) {
		case getType(settingsActions.fetchSettings.success):
			return {
				loaded: true,
				ssoAuthority: action.payload.ssoAuthority,
				languageCookieDomain: action.payload.languageCookieDomain,
				commonUiBaseUrl: action.payload.commonUiBaseUrl,
				cdnBaseUrl: action.payload.cdnBaseUrl,
				assetsBaseUrl: action.payload.assetsBaseUrl,
				rentalApartmentStoreBaseUrl: action.payload.rentalApartmentStoreBaseUrl
			}
	}
	return state;
}
