import * as React from 'react';
import zipwith from 'lodash/zipWith';
import isEqual from 'lodash/isEqual';
import { animate } from 'src/Animation/animate';

export interface IStackProps {
	showPager?: boolean;
	onSwipeEnded?: () => void;
}

export interface ICoords {
	x: number;
	y: number;
}

export interface IStackItemDrag {
	startTime: number;
	itemIndex: number;
	dragStartPosition: ICoords;
	direction?: HorizontalDirection;
	offsetX?: number;
}

export interface IStackState {
	activeIndex?: number;
	drag: IStackItemDrag | null;
	animating: boolean;
	children?: React.ReactNode[];
	originalChildKeys?: string[];
}

type HorizontalDirection = 'left' | 'right';

const isMouseEvent = (e: Event): e is MouseEvent =>
	e.type.indexOf('mouse') === 0;

export class Stack extends React.Component<IStackProps, IStackState> {
	public static getDerivedStateFromProps(
		props: IStackProps & Readonly<{ children?: React.ReactNode }>,
		state: IStackState
	): Partial<IStackState> {
		const childrenFromProps = React.Children.toArray(props.children);

		const keys = childrenFromProps.map((c: any) => c.key as string);

		if (isEqual(state.originalChildKeys, keys)) {
			return {};
		}

		return {
			children: childrenFromProps,
			originalChildKeys: keys,
			activeIndex:
				childrenFromProps.length > 0
					? Math.max(
						0,
						Math.min(
							state.activeIndex || 0,
							childrenFromProps.length - 1
						)
					)
					: undefined
		};
	}

	private static getCoordinates(e: MouseEvent | TouchEvent): ICoords {
		if (isMouseEvent(e)) {
			return { x: e.pageX, y: e.pageY };
		}

		// For touchend, e.touches is undefined, while changedTouches contains
		// the touches that were last removed. At least on iOS safari, the event
		// does contain pageX and pageY, but MDN documentation doesn't specify them,
		// so they seem to be non-standard? In any case, changedTouches works and is
		// documented, so we use that.
		const touchList = e.touches.length > 0 ? e.touches : e.changedTouches;

		return { x: touchList[0].pageX, y: touchList[0].pageY };
	}

	private container: HTMLUListElement | null = null;
	private isDesktopMediaQuery = window.matchMedia('(min-width: 640px)');
	private swipeEnabled = !this.isDesktopMediaQuery.matches;

	constructor(
		props: IStackProps & { children?: React.ReactNode },
		context?: any
	) {
		super(props, context);
		this.state = {
			activeIndex:
				React.Children.count(this.props.children) > 0 ? 0 : undefined,
			drag: null,
			animating: false
		};
	}

	public componentDidMount() {
		window.addEventListener("click", this.windowClicked);

		if (this.isDesktopMediaQuery.addEventListener) {
			this.isDesktopMediaQuery.addEventListener('change', this.setSwipeEnabled);
		}
	}

	public componentWillUnmount() {
		window.removeEventListener("click", this.windowClicked);

		if (this.isDesktopMediaQuery.removeEventListener) {
			this.isDesktopMediaQuery.removeEventListener('change', this.setSwipeEnabled);
		}

		this.unregisterMoveEvents();
	}

	public render() {
		const children = this.state.children || [];
		return (
			<section className="stack-container">
				<ul className="stack" ref={this.setStackContainer}>
					{children
						.map((child, index) => (
							<li
								key={(child as any).key}
								onMouseDown={this.swipeStart}
								onTouchStart={this.swipeStart}
								style={{ zIndex: children.length - index }}
							// TODO: Allow updates that keep active index on top
							// Probably easiest if pixel track width is changed to percentage units?
							// style={this.state.activeIndex! > item.index ? ``}
							>
								{child}
							</li>
						))}
				</ul>

				{this.renderPager()}
			</section>
		);
	}

	private renderPager() {
		if (!this.props.showPager) {
			return null;
		}

		return (
			<ul className="dots">
				{React.Children.map(this.props.children, (child, index) => (
					<li
						key={index}
						className={
							index === this.state.activeIndex ? 'selected' : ''
						}
					/>
				))}
			</ul>
		);
	}

	private setSwipeEnabled = (ev: MediaQueryListEvent) => {
		this.swipeEnabled = !ev.matches;

		if (!this.swipeEnabled) {
			this.clearStackTransforms();
		}
	}

	private setStackContainer = (container: HTMLUListElement | null) => {
		this.container = container;
	};

	private swipeStart = (
		e: React.MouseEvent<HTMLLIElement> | React.TouchEvent<HTMLLIElement>
	) => {
		if (!this.swipeEnabled) {
			return;
		}

		if (!this.container || this.state.activeIndex === undefined) {
			return;
		}

		if (
			isMouseEvent(e.nativeEvent) &&
			e.nativeEvent.type === 'mousedown' &&
			e.nativeEvent.which !== 1 // Primary mouse button is down
		) {
			return;
		}

		if (this.state.animating) {
			return;
		}

		this.registerMoveEvents();

		this.setState({
			drag: {
				startTime: e.timeStamp,
				itemIndex: this.state.activeIndex,
				dragStartPosition: Stack.getCoordinates(e.nativeEvent),
				offsetX: 0,
				direction: 'right'
			},
			animating: false
		});
	};

	private registerMoveEvents() {
		window.addEventListener('mousemove', this.swipeMove);
		window.addEventListener('touchmove', this.swipeMove);

		window.addEventListener('mouseup', this.swipeEnd);
		window.addEventListener('touchend', this.swipeEnd);
	}

	private unregisterMoveEvents() {
		window.removeEventListener('mousemove', this.swipeMove);
		window.removeEventListener('touchmove', this.swipeMove);

		window.removeEventListener('mouseup', this.swipeEnd);
		window.removeEventListener('touchend', this.swipeEnd);
	}

	private windowClicked = () => {
		this.setState({ animating: false });
	}

	private swipeMove = (e: MouseEvent | TouchEvent) => {
		if (!this.state.drag) {
			return;
		}

		if (isMouseEvent(e) && e.buttons === 0) {
			this.unregisterMoveEvents();
			this.setState({ drag: null });
			return;
		}

		const { x: endX, y: endY } = Stack.getCoordinates(e);
		const { drag, activeIndex, animating } = this.state;
		const { dragStartPosition } = drag;
		const { x: startX, y: startY } = dragStartPosition;
		const deltaX = endX - startX;
		const deltaY = endY - startY;

		const trackWidth = this.container!.offsetWidth;

		if (!animating) {
			if (Math.abs(deltaX) <= Math.abs(deltaY)) {
				// scrolling up or down, remove listeners
				this.unregisterMoveEvents();
				return;
			}
		}

		if (deltaX === 0) {
			return;
		}

		const items = this.container!.children;

		const [targetX, itemIndex] =
			deltaX > 0
				? [deltaX - trackWidth, activeIndex! - 1]
				: [deltaX, activeIndex!];

		const targetItem = items[itemIndex] as HTMLLIElement;
		const direction = deltaX > 0 ? 'right' : 'left';

		this.setState({
			drag: {
				...this.state.drag,
				itemIndex,
				direction
			},
			animating: true
		});

		const lastItemIndex = this.container!.children.length - 1;

		requestAnimationFrame(() => {
			if (itemIndex === lastItemIndex && targetItem) {
				const targetWithInertia = Math.pow(Math.abs(targetX), 0.8) * -1;
				targetItem.style.left = `${targetWithInertia}px`;
			} else {
				const targetWithInertia =
					targetX > 0
						? Math.pow(targetX, 0.6)
						: targetX;

				if (targetItem) {
					targetItem!.style.left = `${targetWithInertia}px`;
				}

				this.updateStack(Math.abs(targetWithInertia) / trackWidth, itemIndex + 1);
			}
		});
	};

	private swipeEnd = async (e: MouseEvent | TouchEvent) => {
		this.unregisterMoveEvents();

		const { drag, animating } = this.state;
		if (!drag || !animating) {
			return;
		}

		const { startTime, dragStartPosition, direction, itemIndex } = drag;
		const { x: startX } = dragStartPosition;

		const eventCoords = Stack.getCoordinates(e);

		const elapsed = e.timeStamp - startTime;
		const distance = startX - eventCoords.x;
		const velocity = Math.abs(distance / elapsed);

		const lastItemIndex = this.container!.children.length - 1;

		const animationTargetIndex = Math.max(
			0,
			Math.min(itemIndex, lastItemIndex)
		);

		const item = this.container!.children[animationTargetIndex] as HTMLLIElement;

		const trackWidth = this.container!.offsetWidth;

		// Returns [target slide, current dragged slide target X, should switch slides]
		const getTarget = (): [number, number] => {
			if (velocity <= 0.5) {
				// We are staying on the current slide, because the user swiped slowly
				if (direction === 'right' && itemIndex > -1) {
					// Swiping to the right, dragging the stack item on the left
					// Stay on current item,
					return [itemIndex + 1, -trackWidth];
				}

				// At this point, because we're swiping to the left, we're dragging the current
				// item. Let's return it back to its place.
				return [itemIndex, 0];
			}

			// We're switching slides, because the user swiped fast
			if (direction === 'right') {
				// Swiping to the right, dragging the stack item on the left
				if (itemIndex > -1) {
					// We have an item to the left of here, so:
					// Animate item on the left, move to the active position
					return [itemIndex, 0];
				}

				// This is the leftmost item, so:
				// Animate this item, move back to the starting position
				return [0, 0];
			}

			// Swiping to the left, dragging the current stack item
			if (itemIndex < lastItemIndex) {
				// We have an item to the right of here, so:
				// Animate this item, move a screen's width to the left
				return [itemIndex + 1, -trackWidth];
			}

			// This is the rightmost item, so:
			// Animate this item, move back to the active position
			return [itemIndex, 0];
		};

		const [targetIndex, targetX] = getTarget();

		const animStartPos = Math.abs(item.offsetLeft) / trackWidth;

		const animDuration =
			direction === 'left'
				? 250 * (1 - animStartPos)
				: 250 * animStartPos;

		await animate<{left:number}>({
			durationMs: animDuration,
			start: { left: item.offsetLeft },
			end: { left: targetX },
			callback: (x) => {
				item.style.left = `${x.left}px`;

				if (itemIndex < lastItemIndex) {
					const scaleAdjust = -1 * (x.left / trackWidth);
					this.updateStack(scaleAdjust, itemIndex + 1);
				}
			}
		});

		const activeIndex = Math.max(0, Math.min(targetIndex, lastItemIndex));
		this.updateStack(1, activeIndex);

		this.setState({
			activeIndex,
			drag: null,
			animating: false
		});

		if (this.props.onSwipeEnded) {
			this.props.onSwipeEnded();
		}
	};

	private updateStack(per: number, startIndex: number) {
		if (!this.state.drag) {
			return;
		}
		if (!this.container) {
			return;
		}

		const progressPercentage = Math.min(1.1, Math.max(0, per));

		const orderedCards = Array.from(this.container.children) as HTMLLIElement[];
		const visibleCards = orderedCards.slice(startIndex, startIndex + 3);
		const cardSettings = [
			{
				scaleBase: 0.9,
				opacity: 1,
				offsetMin: 0,
				offsetMax: 0.19
			},
			{
				scaleBase: 0.8,
				opacity: 1,
				offsetMin: 0.19,
				offsetMax: 0.32
			},
			{
				scaleBase: 0.7,
				opacity: progressPercentage,
				offsetMin: 0.32,
				offsetMax: 0.4
			}
		]
		const scaleConfigurations = zipwith(
			visibleCards,
			cardSettings,
			(card, { opacity, scaleBase, offsetMin, offsetMax }) => ({
				card,
				scale: scaleBase + 0.1 * progressPercentage,
				opacity,
				offsetMin,
				offsetMax
			})
		);

		scaleConfigurations.forEach(({ card, scale, opacity, offsetMin, offsetMax }) => {
			if (!card) {
				return;
			}

			const actualScale = Math.min(1, scale);

			const trackWidth = this.container!.offsetWidth;
			const offsetMinPx = trackWidth * offsetMin;
			const offsetMaxPx = trackWidth * offsetMax;
			const travelDistance = offsetMaxPx - offsetMinPx;
			const targetLeft = offsetMinPx + travelDistance * (1 - progressPercentage);

			card.style.transform = `scale(${actualScale})`;
			card.style.left = `${targetLeft}px`;
			card.style.opacity = `${opacity}`;
		});

		if (visibleCards.length === 0) {
			return;
		}

		orderedCards.slice(startIndex + 3).forEach((card) => {
			card.style.opacity = '0';
		});

		const nextItemContent = visibleCards[0].querySelector(
			'.content'
		) as HTMLElement;
		if (nextItemContent) {
			nextItemContent.style.opacity = `${per}`;
		}
	}

	private clearStackTransforms() {
		if (!this.container) {
			return;
		}

		Array.from(this.container.children).forEach((element: Element) => {
			const el = element as HTMLElement;
			el.style.transform = '';
			el.style.left = '';
			el.style.opacity = '';
		})
	}
}
