import { UserManager, UserManagerSettings } from 'oidc-client';
import Cookies from 'universal-cookie';
import { translatedAuthenticatedUrl } from "../Routing/authenticatedRoutes";
import { UILanguage } from "../rootReducer";

export interface IUserProfile {
	given_name: string;
	family_name: string;
	customer_id: string;
	name: string;
	idp: string;
	sub: string;
}

const getLocalUrl = (relativeUrl: string): string =>
	`${window.location.origin}${relativeUrl}`;

const userManagerSettings: UserManagerSettings = {
	client_id: 'kojamo-customer-dashboard-shell',
	redirect_uri: getLocalUrl('/openid-connect/interactive'),
	silent_redirect_uri: getLocalUrl('/openid-connect/silent'),
	response_type: 'id_token token',
	scope: 'openid profile email kojamo-customer-dashboard-api kojamo-customer-dashboard-apps',
	post_logout_redirect_uri: getLocalUrl('/openid-connect/logout'),
	popup_post_logout_redirect_uri: getLocalUrl('/openid-connect/logout')
}

export interface IAuthenticationClientSettings {
	authority: string;
}

export interface ISignInParams {
	returnUrl?: string;
	culture: UILanguage;
}

const loginReturnUrlCookieName = 'loginReturnUrl';

export class AuthenticationClient {
	private client: UserManager;
	constructor(settings: IAuthenticationClientSettings) {
		this.client = new UserManager({
			...userManagerSettings,
			...settings
		});
	}

	public signIn(args?: ISignInParams) {

		const cookies = new Cookies();
		if (args && args.returnUrl) {
			const url = translatedAuthenticatedUrl(args.returnUrl, args.culture);
			if (url && args) {
				cookies.set(loginReturnUrlCookieName, url, { path: '/' });
			}
		} else {
			cookies.remove(loginReturnUrlCookieName, { path: '/' });
		}

		return this.client.signinRedirect(!!args && !!args.culture ? { extraQueryParams: { culture: args.culture  } } : null);
	}

	public refresh(url?: string) {
		return this.client.signinSilent(url);
	}

	public signOut(language: string) {
		return this.client.signoutRedirect({ extraQueryParams: { culture: language  } });
	}

	public receiveInteractive(url?: string) {
		return this.client.signinRedirectCallback(url);
	}

	public receiveSilent(url?: string) {
		return this.client.signinSilentCallback(url);
	}

	public receiveSignOut(url?: string) {
		return this.client.signoutRedirectCallback(url);
	}

	public getPostLoginRedirectUrl() {
		const cookies = new Cookies();
		const returnUrl = cookies.get(loginReturnUrlCookieName);

		cookies.remove(loginReturnUrlCookieName, { path: '/' });

		return returnUrl || '/' ;
	}
}
