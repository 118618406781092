import { ITileConfiguration } from "./Tiles/TileConfiguration";
import { ApiClientBase } from "./ApiClientBase";
import { IEmbeddedUrl } from "./Pages/Embed/embedActions";
import { IFeedMessageAM, FeedMessageStatus } from './Components/Feed/feedActions';
import { IGiosgAM } from './Pages/Dashboard/Giosg/GiosgAM';
import BusinessEntityMaintenanceInformationAM, { ApartmentBasicAM, BusinessPartnerAM } from './Pages/AnonymousUsers/businessEntityMaintenanceInformationAM';
import { IUserContext, UILanguage } from "./rootReducer";

export class ApiClient extends ApiClientBase {

	constructor(language: UILanguage, context?: IUserContext, accessToken?: string) {
		super(language, context, accessToken);
	}

	public async fetchMaintenanceInformation(): Promise<BusinessEntityMaintenanceInformationAM> {
		return await this.fetchJson<BusinessEntityMaintenanceInformationAM>(
			"/api/maintenance-information"
		);
	}

	public async getApartments(businessEntityId: string): Promise<ApartmentBasicAM[]> {
		return await this.fetchJson<ApartmentBasicAM[]>(
			`/api/business-entity/${businessEntityId}/apartments/`);
	}

	public async fetchTiles(
		contractId: string
	): Promise<ITileConfiguration[]> {
		return await this.fetchJson<ITileConfiguration[]>(
			"/api/cards/" + contractId
		);
	}

	public async logLogin(contractId?: string, source?: string): Promise<IUserMetadataAM> {
		return await this.post<IUserMetadataAM>(
			"/api/log-login?"
			+ (source ? `source=${source}&` : "")
			+ (contractId ? `contractId=${contractId}` : "")
		);
	}

	public async logContractSelection(contractId: string): Promise<any> {
		return await this.post(
			`/api/log-contract-selection/${contractId}`
		);
	}

	public async getUserMetadata(): Promise<IUserMetadataAM> {
		return await this.fetchJson<IUserMetadataAM>(
			"/api/get-user-metadata"
		);
	}

	public async fetchGiosgToken(
		contractId: string
	): Promise<IGiosgAM> {
		return await this.fetchJson<IGiosgAM>(
			"/api/chat/token/for-contract/" + contractId
		);
	}

	public async fetchEmbeddedUrls(): Promise<IEmbeddedUrl[]> {
		return await this.fetchJson<IEmbeddedUrl[]>("/api/embedded-uris");
	}

	public async fetchFooter(): Promise<string> {
		return await this.fetchString("/api/embedded-footer");
	}

	public async fetchContracts(): Promise<IContractAM[]> {
		return await this.fetchJson<IContractAM[]>("/api/contracts");
	}

	public async fetchFeedMessages(contractId: string): Promise<IFeedMessageAM[]> {
		return await this.fetchJson<IFeedMessageAM[]>(`/api/feed/${contractId}/history`);
	}

	public async changeFeedMessageStatus(messageId: string, newStatus: FeedMessageStatus): Promise<void> {
		await this.put(`/api/feed/${messageId}/status?newStatus=${newStatus}`);
	}

	public async logFeedMessageFollow(messageId: string, followType: string): Promise<void> {
		await this.put(`/api/feed/${messageId}/followed?from=${followType}`);
	}

	public async fetchSettings(): Promise<IShellSettingsAM> {
		return await this.fetchJson<IShellSettingsAM>("/api/shell-settings");
	}

	public async getArticlesByTag(request: IArticleRequest): Promise<IContentArticleAM> {
		const tagPrimary = this.translateScandinavianChars(request.tagPrimary);
		const tagSecondary = request.tagSecondary && this.translateScandinavianChars(request.tagSecondary);

		return this.fetchJson<IContentArticleAM>(
			`/api/content/articles/${tagPrimary}/${tagSecondary || ''}?take=${request.take}&language=${request.language}`,
		);
	}

	public async changeCustomerLanguage(language: any): Promise<any> {
		return await this.fetchString(`/api/customer-language`, "POST", {'Content-Type': 'application/json; charset=utf-8'}, JSON.stringify({language}) );
	}

	public async saveSiteSurveyAction(result: ISiteSurveyResult) {
		await this.post('/api/site-survey-action', JSON.stringify(result));
	}

	public async getWelcomePageInfo(contractId: string) : Promise<IWelcomePageInfoAM> {
		return await this.fetchJson<IWelcomePageInfoAM>(`/api/${contractId}/welcome-page`);
	}

	private translateScandinavianChars(str: string): string {
		return str.replace(/ä/g, 'a').replace(/ö/g, 'o');
	}
}

export enum ApiOperationState {
	Requested = 'Requested',
	Succeeded = 'Succeeded',
	Failed = 'Failed',
}

export interface IContractAM {
	isContractTerminationScheduled: boolean;
	contractId: string;
	contractNumber: string;
	contractStartDate: Date;
	contractSigningDate: Date | undefined;
	address: IAddress;
	businessEntity: IContractBusinessEntityAM;
	involvementPeriods: IInvolvementPeriod[];
	isDirectRental: boolean;
	rentedWithBookingFee: boolean;
	isCancellable: boolean;
	isCancelled: boolean;
	reservationServiceAccess: IReservationServiceAccess;
}

export interface IReservationServiceAccess {
	onlineSaunaReservation: boolean;
	sharedFacilityReservations: boolean;
}

export interface IContractBusinessEntityAM {
	id: string;
	name: string;
}

export interface IAddress {
	street: string;
	zipCode: string;
	city: string;
}

export interface IInvolvementPeriod {
	start: Date;
	end?: Date;
}

export interface IUserMetadataAM { // represents CustomerState, but as 'state' is related to redux, let's use another word
	welcomeActionsNeeded: boolean;
	hasLinkedIdentities: boolean;
	language: string;
	shouldShowSiteSurvey: boolean;
}

export interface IShellSettingsAM {
	ssoAuthority: string;
	appInsightsInstrumentationKey?: string;
	languageCookieDomain: string | null;
	commonUiBaseUrl: string | undefined;
	cdnBaseUrl: string;
	assetsBaseUrl: string;
	rentalApartmentStoreBaseUrl: string;
}

export interface IArticleRequest {
	tagPrimary: string;
	tagSecondary?: string;
	take: number;
	language: string;
}

export interface IContentArticleAM {
	id: string;
	title: string;
	url: string;
	imageUrl: string;
	imageUrlMedium: string;
	imageUrlSmall: string;
}

type ISiteSurveyResult = {
	customerContinuedToSurvey: boolean;
} | {
	customerCompletedSurvey: boolean;
}

export interface IWelcomePageInfoAM {
	apartment: IWelcomePageApartmentInfoAM;
	benefits: IBenefitCardAM[];
	contractDownloadLink: string;
	paymentListDownloadLink: string;
}

export interface IWelcomePageApartmentInfoAM {
	id: string;
	contractStartDate: Date;
	areaInSqm: number | undefined;
	floorPlan: string;
	rent: number;
	mainImageId: string | undefined;
	keyManagementPartner: BusinessPartnerAM | undefined;
}

export interface IBenefitCardAM {
	technicalName: string;
	name: string;
	price: string;
	description: string;
	link: string;
}
