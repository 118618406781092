import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsightsContainer: { instance: ApplicationInsights | undefined } = { instance: undefined };

export interface IErrorTrackingOptions {
	appInsightsInstrumentationKey?: string;
}

export function setCurrentTelemetryUserId(userId: string | null) {
	if (userId) {
		if (appInsightsContainer.instance) {
			appInsightsContainer.instance.setAuthenticatedUserContext(userId);
		}
	} else {
		if (appInsightsContainer.instance) {
			appInsightsContainer.instance.clearAuthenticatedUserContext();
		}
	}
}

const ignoreDependenciesForDomains: RegExp[] = [/\.giosg\.com\//, /^www\.google-analytics\.com\//, /^\.visualwebsiteoptimizer\.com\//, /\.hubapi\.com\//];

export function setupErrorReporting(options: IErrorTrackingOptions) {
	if (options.appInsightsInstrumentationKey) {
		const appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: options.appInsightsInstrumentationKey,
				disableFetchTracking: false,
				enableCorsCorrelation: true,
				correlationHeaderDomains: ['*.lumo.fi'],
				disableCookiesUsage: true,
			}
		});

		appInsights.loadAppInsights();

		// Add telemetry filter. https://docs.microsoft.com/en-us/azure/azure-monitor/app/api-filtering-sampling#javascript-web-applications
		appInsights.addTelemetryInitializer(item => {
			if (item.baseType === 'RemoteDependencyData' && item.baseData) {

				const host = item.baseData.target;
				const ignore = ignoreDependenciesForDomains.filter(d => d.test(host)).length > 0;
				return !ignore;
			}

			return true;
		})

		appInsightsContainer.instance = appInsights;
	}
}

export function reportError(e: Error) {
	if (appInsightsContainer.instance) {
		appInsightsContainer.instance.trackException({ exception: e });
	}
}

export function trackPageView() {
	if (appInsightsContainer.instance) {
		appInsightsContainer.instance.trackPageView();
	}
}
