import Layout, {setHtmlLang} from "../../Layout";
import {Link, Redirect, RouteComponentProps} from 'react-router-dom';
import {ILoginRequest, authActions} from '../../Authentication/authenticationActions';
import {connect, useSelector} from 'react-redux';
import {anonymousActions} from './anonymousActions';
import BusinessEntityMaintenanceInformationAM from './businessEntityMaintenanceInformationAM';
import {IShellState, UILanguage} from '../../rootReducer';
import {anonymousRoutes} from 'src/Routing/anonymousRoutes';


import AnonymousViewCanonicalLink from "../../Components/AnonymousViewCanonicalLink";
import {ResourceText} from "@kojamo/react-utils";
import {ResourceLink} from "../../Components/ResourceLink";
import {useEffect} from "react";
import {authenticatedRoutes} from "../../Routing/authenticatedRoutes";

interface IContactInformationPageStateProps {
	isDataLoaded: boolean;
	isDataLoading: boolean;
	isErrorLoadingData: boolean;
	maintenanceInformation: BusinessEntityMaintenanceInformationAM | null;
	uiLanguage: UILanguage;
}

export type ContactInformationPageProps =
	IContactInformationPageStateProps
	& IContactInformationPageDispatchProps
	& RouteComponentProps<any>;

function ContactInformationPage(props: ContactInformationPageProps) {
	const isLoggedIn = useSelector<IShellState, boolean>(({user}) => !!user);

	useEffect(() => {
		setHtmlLang(props.uiLanguage);
		if (!props.isDataLoaded && !props.isDataLoading && !props.isErrorLoadingData) {
			props.loadMaintenanceInformation();
		}
	}, []);

	const onLoginClicked = () => {
		const location = props.location;
		const locationState = location.state as any;
		const loginProps = {
			returnUrl: locationState ? locationState.from.pathname + locationState.from.hash : undefined,
			culture: props.uiLanguage
		};

		props.login(loginProps);
	}

	return isLoggedIn ? <Redirect to={authenticatedRoutes.contact[props.uiLanguage]} /> : (
		<>
			<AnonymousViewCanonicalLink/>
			<Layout pageIdentifier="anonymous">
				<section className="content-block is-card drop-shadow">
					<Link to={anonymousRoutes.root[props.uiLanguage]} target="_top"
						  className="back-arrow back-to-home ">
						<svg width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
							<path fill="#F05183"
								  d="M12 5c0 .4-.3.7-.7.7h-9L5 8.5c.3.3.3.7 0 1l-.4.2-.5-.2-4-4-.1-.2v-.6l.1-.2 4-4c.3-.2.7-.2 1 0 .2.3.2.7 0 1l-3 2.8h9.1c.4 0 .7.3.7.7z"/>
						</svg>
						<ResourceText resourceKey="ContactInformationPage_Link_ForTenant" textType="MarkdownInline"/>
					</Link>

					<h1 className="align-center">
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="36" viewBox="0 0 14 36">
							<path fill="#222" fillRule="evenodd"
								  d="M8.109 18l-2.558.03L2.554 3.07a.402.402 0 0 1 .084-.344.393.393 0 0 1 .32-.155h7.708c.16 0 .262.083.318.155.058.07.117.185.086.344L8.11 18zm2.557-18H2.958C1.09 0-.312 1.73.06 3.582L3.057 18.54a2.512 2.512 0 0 0 2.456 2.031h2.596c1.192 0 2.219-.848 2.455-2.031l2.998-14.958C13.932 1.731 12.532 0 10.666 0zM6.97 33.429c-1.704 0-3.09-1.402-3.09-3.125s1.386-3.124 3.09-3.124c1.703 0 3.089 1.401 3.089 3.124s-1.386 3.125-3.09 3.125zm0-8.82c-3.11 0-5.631 2.55-5.631 5.695C1.339 33.45 3.86 36 6.969 36c3.112 0 5.632-2.55 5.632-5.696 0-3.145-2.52-5.695-5.631-5.695z"/>
						</svg>
						<ResourceText resourceKey="ContactInformationPage_Heading_Main" textType="MarkdownInline"/>
					</h1>

					<p className="align-center"><ResourceText resourceKey="ContactInformationPage_Text_HelpInfo"
															  textType="MarkdownInline"/> <a
						onClick={onLoginClicked}><ResourceText resourceKey="ContactInformationPage_Link_ByLoggingIn"
															   textType="MarkdownInline"/></a></p>

					<div className="card">

						<div className="group align-center">
							<p><ResourceText resourceKey="ContactInformationPage_Text_LeftKeysHome"
											 textType="MarkdownInline"/><br/>
								<ResourceText resourceKey="ContactInformationPage_Text_NeedHelp"
											  textType="MarkdownInline"/><br/>
								<strong><ResourceText
									resourceKey="ContactInformationPage_Description_ContactMaintenance"
									textType="MarkdownInline"/></strong></p>
							<Link to={anonymousRoutes.maintenanceInformation[props.uiLanguage]} className="button"><span
								className="hide"><ResourceText resourceKey="ContactInformationPage_Link_Find"
															   textType="MarkdownInline"/>&nbsp;</span><ResourceText
								resourceKey="ContactInformationPage_Link_MaintenanceCompany" textType="MarkdownInline"/></Link>
						</div>
					</div>


				</section>

				<section className="content-block is-card drop-shadow align-center">
					<p className="align-center">
						<ResourceText resourceKey="ContactInformationPage_Description_OtherContacts"
									  textType="MarkdownInline"/>
					</p>
					<ResourceLink className="button invert" hrefKey="ContactInformationPage_Link_SendFeedback_Url"
								  resourceKey="ContactInformationPage_Link_SendFeedback"/>
				</section>
			</Layout>
		</>
	);
}

export interface IContactInformationPageDispatchProps {
	login: (request: ILoginRequest) => any;
	loadMaintenanceInformation: () => any;
}

const mapStateToProps = ({anonymousState, dashboard}: IShellState): IContactInformationPageStateProps => {
	return {
		isDataLoaded: anonymousState.isDataLoaded,
		isDataLoading: anonymousState.isDataLoading,
		isErrorLoadingData: anonymousState.isErrorLoadingData,
		maintenanceInformation: anonymousState.maintenanceInformation,
		uiLanguage: dashboard.uiLanguage
	};
};

export default connect(
	mapStateToProps,
	{
		login: authActions.login.request,
		loadMaintenanceInformation: anonymousActions.loadMaintenanceInformation.request
	})(ContactInformationPage);
