import { fork, take, takeLatest, takeEvery, call, put, select, race, delay } from 'redux-saga/effects';
import { getType, ActionType } from "typesafe-actions";
import { authActions, ILogAndCompleteLogin } from './authenticationActions';
import { AuthenticationClient } from './authenticationClient';
import { IShellState, IUserContext } from 'src/rootReducer';
import { ApiClient, IUserMetadataAM } from 'src/ApiClient';
import { settingsActions } from 'src/Settings/settingsActions';
import { User } from 'oidc-client';
import * as QueryString from 'query-string';
import { appActions } from "src/appActions";
import { ApiCallError } from 'src/ApiCallError';
import { getApiClient } from "../Common/getApiClient";
import { translatedAuthenticatedUrl } from "../Routing/authenticatedRoutes";
import { closeGiosgChats, sessionStorageUserIdKey } from "../lib/giosg";
import { queryParameterSessionStorageKey } from "../Routing/loginQueryParameterCache";
import { browserHistory } from "../browserHistory";

function* withAuthClient(action: keyof AuthenticationClient, args?: any) : any {
	const settingsLoaded = yield select((s: IShellState) => s.settings.loaded);

	if (!settingsLoaded) {
		yield take(getType(settingsActions.fetchSettings.success));
	}

	const authority = yield select((s: IShellState) => s.settings.ssoAuthority!);

	const client = new AuthenticationClient({ authority });

	const { result, timeout } = yield race({
		result: call([client, action], args),
		timeout: delay(30000)
	});
	if (timeout) {
		yield put(appActions.authenticationTimeout(action));
	}

	return result;
}

function* loginSaga(action: ActionType<typeof authActions.login.request>) {
	try {
		yield call(withAuthClient, 'signIn', action.payload );
	} catch (e: any) {
		yield put(authActions.login.failure(e));
	}
}

function* logAndCompleteLoginSaga(action: ActionType<typeof authActions.logAndCompleteLogin>) {

	const { user, contractId, source }: ILogAndCompleteLogin = action.payload;

	if (!user) {
		yield put(authActions.login.failure(new Error("No user data")));
		return;
	}
	const api:ApiClient = yield call(getApiClient,undefined,user.access_token);
	const logResult: IUserMetadataAM = yield call([api, api.logLogin], contractId, source);
	const metadata = logResult;

	// Check if we have stored query parameters to session storage before identification and redirect to that url.
	// Needed for example when the user tries to access a page directly with a url and is redirected to login page.
	// Login page will lose the query parameters and we need to redirect to the original url after identification.
	const queryBeforeIdentification = sessionStorage.getItem(
		queryParameterSessionStorageKey
	);

	if (user.profile.language) {
		let url = translatedAuthenticatedUrl(window.location.pathname, user.profile.language);
		if (queryBeforeIdentification) {
			const {pathName, search } = JSON.parse(queryBeforeIdentification) as {pathName: string, search: string};
			sessionStorage.removeItem(queryParameterSessionStorageKey);

			const translatedUrl = translatedAuthenticatedUrl(pathName, user.profile.language) || pathName;
			if(translatedUrl){
				url = `${translatedUrl}${search || ''}`
			}
		}

		yield put(authActions.switchLanguageOnLogin(user.profile.language));
		if (url) {
		 	browserHistory.push(url as string);
		}
		const userId = user.profile.sub;
		const previousUserId = sessionStorage.getItem(
			sessionStorageUserIdKey
		);
		if (previousUserId !== userId) {
			closeGiosgChats();
		}
		sessionStorage.setItem(sessionStorageUserIdKey, userId);
	}

	yield put(authActions.login.success({ user, metadata }));
}

function* refreshSaga(): any {
	try {
		const user: User = yield call(withAuthClient, 'refresh');
		if (!user) {
			throw new Error("No User data!");
		}

		const currentUserContext = yield select((s: IShellState) => s.user);
		if (currentUserContext) {
			yield put(authActions.refresh.success({ user }));
		} else {
			const api:ApiClient = yield getApiClient(undefined, user.access_token);
			// Read login information from url, and if such exists, log a new login
			if (window.location.search) {

				const queryParams = QueryString.parse(window.location.search);

				const source = queryParams["loginFrom"];
				const contractId = queryParams["loginContractId"];


				if (source || contractId) {
					const metadataFromLogLogin = yield call([api, api.logLogin], contractId as string, source as string);
					yield put(authActions.refresh.success({ user, metadata: metadataFromLogLogin }));
					return;
				}
			}

			const metadata = yield call([api, api.getUserMetadata]);
			const userId = user.profile.sub;
			const previousUserId = sessionStorage.getItem(
				sessionStorageUserIdKey
			);
			if (previousUserId !== userId) {
				closeGiosgChats();
			}

			sessionStorage.setItem(sessionStorageUserIdKey, userId);
			yield put(authActions.refresh.success({ user, metadata }));
		}
	} catch (e: any) {
		if(e instanceof ApiCallError && e.statusCode >= 500){
			yield put(authActions.error(e));
		} else {
			yield put(authActions.refresh.failure());
		}
	}
}

function* logoutSaga() {

	try {
		const language: string = yield select((s: IShellState) => s.user?.language ?? 'fi');
		closeGiosgChats();
		yield delay(100);

		yield call(withAuthClient, 'signOut', language);

		// Wait a moment for the signout redirect to trigger. Otherwise resulting
		// DOM changes might prevent the end session frame from being loaded
		yield delay(5000);

		// This shouldn't actually be necessary, since at this point the
		// session should already have ended via a browser redirect, and
		// we should never reach this point anyway.
		yield put(authActions.logout.success());
	} catch (e: any) {
		yield put(authActions.logout.failure(e));
	}
}

function* watchLoginSaga() {
	yield takeLatest(getType(authActions.login.request), loginSaga);
}

function* watchRefreshSaga() {
	yield takeLatest(getType(authActions.refresh.request), refreshSaga);
}

function* watchLogoutSaga() {
	yield takeLatest(getType(authActions.logout.request), logoutSaga);
}

function* refreshLoginSaga() {
	while (true) {
		yield delay(30000);

		const user: IUserContext = yield select((s: IShellState) => s.user);
		if (user) {
			const currentTimeInEpochSeconds = Math.ceil((new Date()).valueOf() / 1000);
			const ttlSeconds = user.expiresAt - currentTimeInEpochSeconds;
			if (ttlSeconds < 30) {
				yield put(authActions.refresh.request());
			}
		}
	}
}

function* saveSiteSurveyActionSaga(
	action: ActionType<typeof authActions.saveSiteSurveyAction.request>
) {
	try {
		const api:ApiClient = yield call(getApiClient,action.payload.user);
		yield call([api, api.saveSiteSurveyAction], action.payload);
		yield put(authActions.saveSiteSurveyAction.success());
	} catch (e: any) {
		yield put(authActions.saveSiteSurveyAction.failure(e));
	}
}

export function* authSaga() {
	yield fork(watchLoginSaga);
	yield fork(watchRefreshSaga);
	yield fork(watchLogoutSaga);
	yield fork(refreshLoginSaga);
	yield takeEvery((getType(authActions.logAndCompleteLogin)), logAndCompleteLoginSaga);
	yield takeLatest(getType(authActions.saveSiteSurveyAction.request), saveSiteSurveyActionSaga);
}
