import { UILanguage } from 'src/rootReducer';
import { getTranslatedUrlForRoute } from "./getTranslatedUrlForRoute";
import { matchPath } from "react-router-dom";

export const contractNumber = ':contractNumber([0-9]+)';
const page = ':page([a-z0-9-]+(\\.html)?)';
export const langEn = 'en';

export const shellRoutes = {
	frontpage: {fi: `/etusivu`, en: `/${langEn}/frontpage`},
	frontpageRoot: {fi: `/${contractNumber}/etusivu`, en: `/${contractNumber}/${langEn}/frontpage`},
	welcome: {fi: `/tervetuloa`, en: `/${langEn}/welcome`},
	messageHistoryRoot: {fi: `/${contractNumber}/viestihistoria`, en: `/${contractNumber}/${langEn}/message-history`},
	messageHistory: {fi: `/viestihistoria`, en: `/${langEn}/message-history`},
	surveyCompleteRoot: {fi: `/${contractNumber}/kysely-valmis`, en: `/${contractNumber}/${langEn}/survey-complete`},
};

export const saunaRoutes = {
	sauna: {fi: "/sauna", en: `/${langEn}/sauna`}
};

export const terminationRoutes = {
	termination: {fi: "/irtisanominen", en: `/${langEn}/termination`},
	instructions: {fi: "/poismuutto-ohje", en: `/${langEn}/moving-out-instruction`}
};

//Magento has .html pages that are not translated
export const magentoRoutes = {
	magento: {fi: `/palvelut/tilaus/${page}`, en: `/${langEn}/services/order/${page}`},
}
export const ourHouseRoutes = {
	house: {fi: "/talo", en: `/${langEn}/house`},
	home: {fi: "/koti", en: `/${langEn}/home`},
	contactInfo: {fi: "/talo/yhteystiedot", en: `/${langEn}/house/contact-information`},
	services: {fi: "/palvelut", en: `/${langEn}/services`},
	emissionsCalculator: {fi: "/hiilijalanjalkitesti", en: `/${langEn}/carbon-footprint-test`},
	exchangeFlow: {fi: "/koti/vaihto", en: `/${langEn}/home/exchange`},
	facilityReservations: {fi: "/tilavaraukset", en: `/${langEn}/facility-reservations`},
	benefitsRoot: {fi: `/${contractNumber}/talo/edut`, en: `/${contractNumber}/${langEn}/house/benefits`},
	announcementsArchive: {fi: "/talo/tiedotteet/arkisto", en: `/${langEn}/house/notices/archive`},
	announcement: {fi: "/talo/tiedotteet/:announcementId", en: `/${langEn}/house/notices/:announcementId`},
};

export const parkingSpaceRoutes = {
	parkingSpace: {fi: "/autopaikat", en: `/${langEn}/parkingspaces`},
	businessEntity: {fi: "/autopaikat/talo", en: `/${langEn}/parkingspaces/house`},
	rentalReady: {fi: "/autopaikat/vuokraus-valmis", en: `/${langEn}/parkingspaces/rental-ready`},
	terminationReady: {fi: "/autopaikat/irtisanominen-valmis", en: `/${langEn}/parkingspaces/termination-ready`},
	identificationComplete: {
		fi: "/autopaikat/tunnistautuminen-valmis",
		en: `/${langEn}/parkingspaces/identification-complete`
	},
	parkingSpacesLoginRoot: {
		fi: `/${contractNumber}/autopaikat-login`,
		en: `/${contractNumber}/${langEn}/parkingspaces-login`
	},
	searchWatch: {fi: "/autopaikat/vahti", en: `/${langEn}/parkingspaces/watch`},
	searchWatchCreate: {fi: "/autopaikat/vahti/luo", en: `/${langEn}/parkingspaces/watch/create`},
	searchWatchRemove: {fi: "/autopaikat/vahti/poista", en: `/${langEn}/parkingspaces/watch/delete`},
};

export const ledgerRoutes = {
	rent: {fi: "/vuokra", en: `/${langEn}/rent`},
	paymentSucceeded: {fi: "/vuokra/maksu/valmis", en: `/${langEn}/rent/payment/completed`},
	paymentFailed: {fi: "/vuokra/maksu/epaonnistui", en: `/${langEn}/rent/payment/failed`},
	rentLate: {fi: "/vuokra/myohassa", en: `/${langEn}/rent/late`},
};

export const customerRoutes = {
	profile: {fi: "/omat-tiedot", en: `/${langEn}/personal-information`},
	contract: {fi: "/oma-sopimus", en: `/${langEn}/own-contract`},
	cancelContract: {fi: "/peruuta-sopimus", en: `/${langEn}/cancel-contract`},
	contractCancelled: {fi: "/sopimus-peruutettu", en: `/${langEn}/contract-cancelled`},
	communicationPreferences: {fi: "/viestintaasetukset", en: `/${langEn}/communication-preferences`},
};

export const contactRoutes = {
	contact: {fi: "/yhteydenotto", en: `/${langEn}/contact`},
	reportProblemAnonymous: {fi: "/vikailmoitus/kirjautumaton", en: `/${langEn}/service-request/anonymous`},
	reportProblemShorthand: {fi: "/vikailmoitus", en: `/${langEn}/fault-report`},
	reportProblem: {fi: "/yhteydenotto/vikailmoitus", en: `/${langEn}/contact/fault-report`},
	myServiceRequests: {fi: "/yhteydenotto/omat", en: `/${langEn}/contact/my-service-requests`},
	question: {fi: "/yhteydenotto/kysymys", en: `/${langEn}/contact/question`},
	feedback: {fi: "/palaute", en: `/${langEn}/feedback`},
	rentQuestion: {fi: "/yhteydenotto/vuokranmaksu", en: `/${langEn}/contact/rent-payment`},
	saunaContact: {fi: "/yhteydenotto/saunavuorot", en: `/${langEn}/contact/sauna-shifts`},
	disturbance: {fi: "/yhteydenotto/hairio", en: `/${langEn}/contact/disturbance`},
	otherTopics: {fi: "/yhteydenotto/muut-asiat", en: `/${langEn}/contact/other-topics`},
	keys: {fi: "/yhteydenotto/avaimet", en: `/${langEn}/contact/keys`},
	moving: {fi: "/yhteydenotto/muutto", en: `/${langEn}/contact/moving`},
	parking: {fi: "/yhteydenotto/autopaikat", en: `/${langEn}/contact/parking`},
	commonFacilities: {fi: "/yhteydenotto/tilavaraus", en: `/${langEn}/contact/facility-reservation`},
	benefits: {fi: "/yhteydenotto/palvelut", en: `/${langEn}/contact/services`}
};


export const authenticatedRoutes = {
	...shellRoutes,
	...contactRoutes,
	...customerRoutes,
	...ledgerRoutes,
	...parkingSpaceRoutes,
	...ourHouseRoutes,
	...magentoRoutes,
	...terminationRoutes,
	...saunaRoutes
};

export function matchTranslatedRoute(pathname: string, route: Record<UILanguage, string>, language: UILanguage) {
	return matchPath(pathname, {
		path: `/${contractNumber}${route[language]}`,
		exact: true
	});
}

export function translatedAuthenticatedUrl(url: string, targetLanguage: UILanguage): string | undefined {
	return getTranslatedUrlForRoute(authenticatedRoutes, url, targetLanguage, true);
}
