import { fork } from "redux-saga/effects";
import { authSaga } from "./Authentication/authSaga";
import { dashboardSaga } from "./Pages/Dashboard/dashboardSaga";
import { embedSaga } from "./Pages/Embed/embedSaga";
import { contractContextSaga } from "./Routing/ContractContext/contractContextSaga";
import { feedSaga } from './Components/Feed/feedSaga';
import { settingsSaga } from './Settings/settingsSaga';
import { appInitSaga } from './appInitSaga';
import { anonymousSaga } from './Pages/AnonymousUsers/anonymousSaga';

export function* rootSaga() {
	yield fork(settingsSaga);
	yield fork(appInitSaga);
	yield fork(authSaga);
	yield fork(dashboardSaga);
	yield fork(embedSaga);
	yield fork(contractContextSaga);
	yield fork(feedSaga);
	yield fork(anonymousSaga);
}
