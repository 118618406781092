import { getType } from 'typesafe-actions';
import { take, takeLatest, put } from 'redux-saga/effects';
import { appActions } from './appActions';
import { settingsActions } from './Settings/settingsActions';
import { authActions } from './Authentication/authenticationActions';

function* watchAppInitSaga() {
    yield put(settingsActions.fetchSettings.request());

    yield take(getType(settingsActions.fetchSettings.success));

    yield put(authActions.refresh.request());
}

export function* appInitSaga() {
    yield takeLatest(getType(appActions.init), watchAppInitSaga);
}