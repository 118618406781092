import {useSelector} from "react-redux";

import {useResource, ResourceText} from "@kojamo/react-utils";

import React from "react";
import benefitPlaceholderImg from '../../img/lumo-etu-placeholder.jpg';
import {IAuthenticatedShellState} from "../../rootReducer";
import InternalLink from "../InternalLink";

const Services: React.FC = () => {

	const {getResourceImageUrlByKey} = useResource();

	const welcomePage = useSelector((state: IAuthenticatedShellState) => state.dashboard.welcomePage);
	const commonUiBaseUrl = useSelector((state: IAuthenticatedShellState) => state.settings.commonUiBaseUrl);
	const cdnBaseUrl = useSelector((state: IAuthenticatedShellState) => state.settings.cdnBaseUrl);

	if (!welcomePage) {
		return null;
	}

	return (
		<section className="mod-services">
			<div className="mod-services__wrapper">
				<div className="mod-services__intro">
					<h2 className="mod-services__heading">
						<ResourceText textType="MarkdownInline" resourceKey="Dashboard_Services_Heading"/>
					</h2>
				</div>
				<div className="mod-services__services">
					<ul className="mod-services__servicesList">
						{welcomePage.benefits.map((b) => {
							let img = getResourceImageUrlByKey(commonUiBaseUrl, "Dashboard_Benefits_Image_" + b.technicalName, true);
							if (!img) { img = benefitPlaceholderImg; }
							return (
								<li key={b.technicalName} className="atom-serviceBanner">
									<InternalLink
										app="OurHouse"
										to="benefits"
										parameters={"#" + b.technicalName}
										className="atom-serviceBanner__serviceLink"
										id={`MoversServices_${b.technicalName}`}
									>
										<div className="atom-serviceBanner__imageWrapper">
											<img className="icon"
												 src={`${cdnBaseUrl}images/benefits/icons/${b.technicalName}-white`}/>
											<img className="atom-serviceBanner__serviceImage" src={img} alt=""/>
										</div>
										<div className="atom-serviceBanner__serviceText">
											<h3>{b.name}</h3>
											<span
												className={`price${b.price.toLowerCase().indexOf("ilmainen") > -1 || b.price.toLowerCase().indexOf("free") > -1 ? " -free" : ""}`}>{b.price}</span>
											<p>{b.description}</p>
										</div>
									</InternalLink>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</section>
	);
};

export default Services;
