import { takeLatest, call, put } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { settingsActions } from './settingsActions';
import { ApiClient } from 'src/ApiClient';
import { getApiClient } from "../Common/getApiClient";

function* watchFetchSettingsSaga() : any {
	try {
		const client:ApiClient = yield call(getApiClient);

		const settings = yield call([client, client.fetchSettings]);

		yield put(settingsActions.fetchSettings.success(settings));
	} catch (e: any) {
		yield put(settingsActions.fetchSettings.failure(e));
	}
}

export function* settingsSaga() {
	yield takeLatest(getType(settingsActions.fetchSettings.request), watchFetchSettingsSaga);
}
