import {createContext, ReactNode, useContext, useEffect, useMemo, useReducer} from "react";

interface INavigationContext {
	userMenuOpen: boolean;
	contractMenuOpen: boolean;
	reservationsMenuOpen: boolean;
	dispatch: (action: {type: NavigationActionType}) => void;
}

const initialState: INavigationContext = {
	userMenuOpen: false,
	contractMenuOpen: false,
	reservationsMenuOpen: false,
	dispatch: () => undefined,
};

const navigationContext = createContext<INavigationContext>(initialState);

export enum NavigationActionType {
	OpenUserMenu = "OpenUserMenu",
	OpenContractMenu = "OpenContractMenu",
	OpenReservationsMenu = "OpenReservationsMenu",
	CloseMenus = "CloseMenus",
}

function navigationReducer(state: INavigationContext, action: {type: NavigationActionType}) {
	switch (action.type) {
		case NavigationActionType.OpenUserMenu:
			return {...state, userMenuOpen: true, contractMenuOpen: false, reservationsMenuOpen: false};
		case NavigationActionType.OpenContractMenu:
			return {...state, userMenuOpen: false, contractMenuOpen: true, reservationsMenuOpen: false};
		case NavigationActionType.OpenReservationsMenu:
			return {...state, userMenuOpen: false, contractMenuOpen: false, reservationsMenuOpen: true};
		case NavigationActionType.CloseMenus:
			return {...state, userMenuOpen: false, contractMenuOpen: false, reservationsMenuOpen: false};
		default:
			return state;
	}
}

export function NavigationProvider({children}: {children: ReactNode}) {
	const [state, dispatch] = useReducer(navigationReducer, initialState);
	const value = useMemo(() => ({...state, dispatch}), [state, dispatch]);

	const onFocus = () => {
		if (document.activeElement && document.activeElement.tagName === 'IFRAME') {
			dispatch({type: NavigationActionType.CloseMenus});
		}
	};

	useEffect(() => {
		window.addEventListener('focus', onFocus, false);
		window.addEventListener('blur', onFocus, false);

		return () => {
			window.removeEventListener('focus', onFocus);
			window.removeEventListener('blur', onFocus);
		};
	}, []);

	return (
		<article onClick={() => dispatch({type: NavigationActionType.CloseMenus})}>
			<navigationContext.Provider value={value}>
				{children}
			</navigationContext.Provider>
		</article>
	);
}

export function useNavigationContext() {
	return useContext(navigationContext);
}
