import { Helmet } from "react-helmet";
import { IShellState } from "../../rootReducer";
import { useSelector } from "react-redux";


export function CookieBotStylesLink () {
	const assetsBaseUrl = useSelector(({settings}:IShellState) => settings.assetsBaseUrl)

	return  (
		<Helmet>
			<link rel="stylesheet" type="text/css" href={`${assetsBaseUrl}styles/cookiebot-lumo.css`}/>
		</Helmet>
	)
}
