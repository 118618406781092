import {
	Button,
	IconAllBenefits,
	IconFeedback,
	IconHome,
	IconRentalContract,
	Menu,
	SpacingContainer
} from "@kojamo/lumo-dls";
import {ResourceText} from "@kojamo/react-utils";
import {authenticatedRoutes} from "../../Routing/authenticatedRoutes";
import {contractMenuRoutes, linkClicked, NavigationContractMenuLinks} from "./NavigationShared";
import {NavigationActionType, useNavigationContext} from "./NavigationProvider";
import {GA4EventAction} from "../../telemetryGA4";
import {LinkWithSelectedState, useMatchesOneOfRoutes} from "./LinkWithSelectedState";

const bottomNavigationLinkButtonClasses = "l-button is-icon-label"

export function BottomNavigationMobile() {
	const { contractMenuOpen, dispatch } = useNavigationContext();
	const matchesContractSubRoute = useMatchesOneOfRoutes(contractMenuRoutes);
	return (
		<div className="BottomNavigationMobile l-visible-mobile-only">
			<SpacingContainer className="l-justify-evenly l-background-brand-primary l-rounding-16-t l-padding-1-y">
				<Menu
					trigger={
						<Button
							variant="icon-label"
							onClick={(e) => {
								e.stopPropagation();
								dispatch({type: contractMenuOpen ? NavigationActionType.CloseMenus : NavigationActionType.OpenContractMenu});
							}}
							isSelected={matchesContractSubRoute}
						>
							<IconRentalContract/>
							<ResourceText textType="Plain" resourceKey="Dashboard_QuickLinks_Contract"/>
						</Button>
					}
					open={contractMenuOpen}
					className="l-background-brand-background-lighter l-margin-1-b l-stack-9"
					direction="up"
					align="left"
				>
					<NavigationContractMenuLinks />
				</Menu>
				<LinkWithSelectedState to={authenticatedRoutes.house} className={bottomNavigationLinkButtonClasses}>
					<IconHome/>
					<ResourceText textType="Plain" resourceKey="Dashboard_QuickLinks_HouseAndFacilities"/>
				</LinkWithSelectedState>
				<LinkWithSelectedState to={authenticatedRoutes.contact} className={bottomNavigationLinkButtonClasses}>
					<IconFeedback />
					<ResourceText textType="Plain" resourceKey="Layout_MainNav_Link_Contact"/>
				</LinkWithSelectedState>
				<LinkWithSelectedState to={authenticatedRoutes.services} className={bottomNavigationLinkButtonClasses} onClick={() => linkClicked(GA4EventAction.services)}>
					<IconAllBenefits />
					<ResourceText textType="Plain" resourceKey="Layout_MainNav_Link_Services"/>
				</LinkWithSelectedState>
			</SpacingContainer>
		</div>
	);
}
