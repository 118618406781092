import { useDispatch, useSelector } from "react-redux";
import { IAuthenticatedShellState } from "../../rootReducer";
import { contractContextActions } from "./contractContextActions";
import { useCallback, useEffect } from "react";

export function useContract() {
	const { user, selectedContract, contracts } = useSelector(({ user, contracts }: IAuthenticatedShellState) => ({
		user,
		selectedContract: contracts.selectedContract,
		contracts: contracts.contracts
	}));
	const dispatch = useDispatch();

	const changeContract = (contractNumber: string) => dispatch(contractContextActions.changeSelectedContract(contractNumber));
	const logContractChange = contractContextActions.logContractChange.request;

	useEffect(() => {
		if (contracts.length === 1) {
			window.document.body.classList.add("single-contract-customer");
		} else {
			window.document.body.classList.remove("single-contract-customer");
		}
	}, []);

	const selectedContractChanged = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
		changeContract(event.target.value);
		const contract = contracts.find(c => c.contractNumber === event.target.value);
		if (contract) {
			logContractChange({ user, contractId: contract.contractId });
		}
	}, []);

	return { contracts, selectedContract, selectedContractChanged };
}
