import { IShellSettingsAM } from '../ApiClient';
import { createAsyncAction, ActionType } from 'typesafe-actions';

export const settingsActions = {
    fetchSettings: createAsyncAction(
        'APP/FETCH_SETTINGS_REQUEST',
        'APP/FETCH_SETTINGS_SUCCESS',
        'APP/FETCH_SETTINGS_FAILURE'
    )<void, IShellSettingsAM, Error>()
}

export type SettingsActionTypes = ActionType<typeof settingsActions>;