import * as React from "react";
import {IShellState, IUserContext, UILanguage} from "src/rootReducer";
import { connect } from "react-redux";
import { Link, LinkProps } from "react-router-dom";
import { IEmbeddedUrl } from "src/Pages/Embed/embedActions";
import { IContractAM } from 'src/ApiClient';
import { parseContractNumberFromPath } from "../Routing/ContractContext/contractUtils";
import { getLanguage } from "src/Pages/Embed/getLanguage";

export interface IInternalLinkStateProps {
	embeddedUrl: IEmbeddedUrl | undefined;
	user: IUserContext | null;
	contract: IContractAM | null;
	currentLanguage: UILanguage;
}

export interface IInternalLinkProps {
	app?: string;
	parameters?: string;
	forceReload?: true;
}

class InternalLink extends React.Component<
	IInternalLinkStateProps & LinkProps & IInternalLinkProps,
	Record<string, never>
> {

	public render() {


		if (this.props.app) {

			let queryParameters = this.props.parameters || '';

			if (!this.props.embeddedUrl) {
				return null;
			}

			if (this.props.forceReload) {
				queryParameters = queryParameters.startsWith('?') ?
					`${queryParameters}&ts=${Date.now()}` :
					`?ts=${Date.now()}${queryParameters}`;
			}

			const lang = getLanguage(this.props.currentLanguage, window.location.pathname)

			const path = `${this.getPotentialContractSegment()}/${this.props.embeddedUrl.paths[lang]}${queryParameters}`;

			return (
				<Link {...this.getPropsForLink()} to={path}>
					{this.props.children}
				</Link>
			);
		}

		const targetUrl = this.getTargetUrl();

		return this.hasProtocol(targetUrl)
			? <a href={targetUrl} {...this.getPropsForLink()}>{this.props.children}</a>
			: <Link to={targetUrl} {...this.getPropsForLink()}>{this.props.children}</Link>;
	}

	private getPotentialContractSegment(): string {
		if (!this.props.contract) { return ""; }

		return "/" + this.props.contract.contractNumber;
	}

	private getTargetUrl(): string {

		let url = this.props.to.toString();

		if (this.hasProtocol(url)) {

			if (!url.startsWith(window.location.origin)) {
				return url; // normal outside url
			}

			// Strip origin (for example https://my.lumo.fi) from start if we're linking to the current site
			url = url.substring(window.location.origin.length);
		}

		if (!url.startsWith("/")) {
			url = "/" + url;
		}
		const contractNumber = parseContractNumberFromPath(url);

		if (!contractNumber) {
			url = this.getPotentialContractSegment() + url;
		}

		return url;
	}

	private hasProtocol(url: string): boolean {
		return /^https?:\/\//.test(url);
	}

	private getPropsForLink(): any {

		const allProps = this.props;

		const { app, embeddedUrl, user, contract, to, forceReload,currentLanguage, ...result } = allProps;
		return result;
	}
}

const mapStateToProps = (
	{ contracts, embedding, user, dashboard }: IShellState,
	ownProps: IInternalLinkProps & LinkProps
): IInternalLinkStateProps => ({
	embeddedUrl: ownProps.app
		? embedding.urls.find(
			e => ownProps.app === e.app && e.name === ownProps.to
		)
		: undefined,
	user,
	currentLanguage: dashboard.uiLanguage,
	contract: contracts.selectedContract
});

export default connect(
	mapStateToProps,
	{}
)(InternalLink);
