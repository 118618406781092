import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { EmbedActionTypes, embedActions, IEmbeddedUrl } from './embedActions';

export interface IEmbedState {
	fetchingUrls: boolean;
	urls: IEmbeddedUrl[];

	fetchingFooter: boolean;
	footer: string | null;
}

const initialState = {
	fetchingUrls: false,
	urls: [],
	fetchingFooter: false,
	footer: null
};

export const embedReducer: Reducer<IEmbedState, EmbedActionTypes> = (state = initialState, action) => {
	switch (action.type) {
		
		case getType(embedActions.fetchUrls.request):
			return { ...state, fetchingUrls: true, urls: [] };
		case getType(embedActions.fetchUrls.success):
			return { ...state, fetchingUrls: false, urls: action.payload };

		case getType(embedActions.fetchFooter.request):
			return { ...state, fetchingFooter: true };
		case getType(embedActions.fetchFooter.success):
			return { ...state, fetchingFooter: false, footer: action.payload }
	}

	return state;
}