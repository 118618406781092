import { AuthenticationClient } from '../Authentication/authenticationClient';
import { Redirect } from 'react-router';
import { IShellState } from 'src/rootReducer';
import { connect } from 'react-redux';

export interface IOidcLogoutCallbackStateProps {
	authority: string;
}

function OidcLogoutCallback({ authority }: IOidcLogoutCallbackStateProps) {
	const authenticationClient = new AuthenticationClient({ authority });
	authenticationClient.receiveSignOut();

	return (
		<Redirect to="/" />
	);
}

const mapStateToProps = (state: IShellState): IOidcLogoutCallbackStateProps => ({
	authority: state.settings.ssoAuthority!
});

export default connect(mapStateToProps, { })(OidcLogoutCallback);
