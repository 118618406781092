import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { AnonymousActionTypes, anonymousActions } from './anonymousActions';
import BusinessEntityMaintenanceInformationAM, { ApartmentBasicAM } from './businessEntityMaintenanceInformationAM';
import { IContentArticleAM, ApiOperationState } from 'src/ApiClient';

export interface IAnonymousState {
	isDataLoaded: boolean;
	isDataLoading: boolean;
	isErrorLoadingData: boolean;
	maintenanceInformation: BusinessEntityMaintenanceInformationAM | null;
	apartments: ApartmentBasicAM[];
	isServiceRequestApartmentDataLoading: boolean;
	frontPageArticles: IContentArticleAM[];
	frontPageArticlesFetchOperationState: ApiOperationState | undefined;
}

export enum ServiceRequestState {
	New,
	Creating,
	Created,
	UploadingAttachments,
	AttachmentsUploaded,
	Submitting,
	Submitted,
	Failed
}

const initialState: IAnonymousState = {
	isDataLoaded: false,
	isDataLoading: false,
	isErrorLoadingData: false,
	maintenanceInformation: null,
	apartments: [],
	isServiceRequestApartmentDataLoading: false,
	frontPageArticles: [],
	frontPageArticlesFetchOperationState: undefined,
}

export const anonymousReducer: Reducer<IAnonymousState, AnonymousActionTypes> = (state = initialState, action) => {
	switch (action.type) {
		case getType(anonymousActions.loadMaintenanceInformation.request):
			return { ...state, isDataLoading: true, isDataLoaded: false, maintenanceInformation: null };
		case getType(anonymousActions.loadMaintenanceInformation.success):
			return { ...state, isDataLoading: false, isDataLoaded: true, maintenanceInformation: action.payload };
		case getType(anonymousActions.loadMaintenanceInformation.failure):
			return { ...state, isDataLoading: false, isDataLoaded: false, isErrorLoadingData: true, maintenanceInformation: null };

		case getType(anonymousActions.loadApartments.request):
			return { ...state, isServiceRequestApartmentDataLoading: true };
		case getType(anonymousActions.loadApartments.success):
			return { ...state, apartments: action.payload, isServiceRequestApartmentDataLoading: false };
		case getType(anonymousActions.loadApartments.failure):
			return { ...state, isServiceRequestApartmentDataLoading: false };

		case getType(anonymousActions.getArticlesByTag.request):
			return { ...state, frontPageArticlesFetchOperationState: ApiOperationState.Requested };
		case getType(anonymousActions.getArticlesByTag.success):
			return { ...state, frontPageArticles: action.payload, frontPageArticlesFetchOperationState: ApiOperationState.Succeeded };
		case getType(anonymousActions.getArticlesByTag.failure):
			return { ...state, frontPageArticlesFetchOperationState: ApiOperationState.Failed };

	}
	return state;
}
