import * as React from 'react';
import { ITileConfiguration } from './TileConfiguration';
import TileRenderer from './TileRenderer';
import { Stack } from 'src/Components/Stack';
import { addTelemetryEventGA4, GA4EventAction, GA4EventCategory, GA4EventType } from "../telemetryGA4";

export interface ITileViewProps {
	tiles: ITileConfiguration[];
	group: string;
	action: GA4EventAction;
}

const TileView: React.SFC<ITileViewProps> = ({ tiles, group, action }: ITileViewProps) => {
	const tilesInThisGroup = tiles.filter((t) => t.group === group);

	return (
			<Stack showPager onSwipeEnded={() => handleSwipe(action)}>
				{tilesInThisGroup.map((t) => (
					<TileRenderer onClick={() => handleClick(action)} tile={t} key={t.id} />
				))}
			</Stack>
	);

};

function handleSwipe(action: GA4EventAction) {
	addTelemetryEventGA4({ event: GA4EventType.ui_clicks, category: GA4EventCategory.tile_swipe, action });
}

function handleClick(action: GA4EventAction) {
	addTelemetryEventGA4({ event: GA4EventType.ui_clicks, category: GA4EventCategory.tile_click, action });
}

export default TileView;
