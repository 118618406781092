import {useResource, ResourceText} from "@kojamo/react-utils";
import {addTelemetryEventGA4, GA4EventAction, GA4EventCategory, GA4EventType} from "../../telemetryGA4";
import InternalLink from "../InternalLink";

const FaqLifts = () => {
	const onFaqLinkClicked = (ga4Action: GA4EventAction) => {
		addTelemetryEventGA4({event: GA4EventType.ui_clicks, category: GA4EventCategory.faq_tile, action: ga4Action});
	};

	const {getResourceText} = useResource();
	return (

		<section className="faq-lifts">
			<div className="content-wrapper">
				<h2>
					<ResourceText textType="MarkdownInline" resourceKey="Dashboard_FAQ_Heading"/>
				</h2>
				<ul className="faq-lift-list">
					<li className="faq-lift">
						<InternalLink app="Contact" to="keys"
									  onClick={() => onFaqLinkClicked(GA4EventAction.keys)}>
							<ResourceText textType="MarkdownInline" resourceKey="Dashboard_FAQ_Link_Keys"/>
						</InternalLink>
					</li>
					<li className="faq-lift">
						<InternalLink app="Contact" to="report-problem"
									  onClick={() => onFaqLinkClicked(GA4EventAction.problem)}>
							<ResourceText textType="MarkdownInline" resourceKey="Dashboard_FAQ_Link_ServiceRequest"/>
						</InternalLink>
					</li>
					<li className="faq-lift">
						<InternalLink app="Contact" to="sauna"
									  onClick={() => onFaqLinkClicked(GA4EventAction.sauna)}>
							<ResourceText textType="MarkdownInline" resourceKey="Dashboard_FAQ_Link_Sauna"/>
						</InternalLink>
					</li>
					<li className="faq-lift">
						<a href={getResourceText("Dashboard_FAQ_Url_ParkingSpace")}
						   onClick={() => onFaqLinkClicked(GA4EventAction.parking_space)}>
							<ResourceText textType="MarkdownInline" resourceKey="Dashboard_FAQ_Link_ParkingSpace"/>
						</a>
					</li>
					<li className="faq-lift">
						<InternalLink app="Contact" to="rent-question"
									  onClick={() => onFaqLinkClicked(GA4EventAction.rent_question)}>
							<ResourceText textType="MarkdownInline" resourceKey="Dashboard_FAQ_Link_Rent"/>
						</InternalLink>
					</li>
					<li className="faq-lift">
						<InternalLink app="Contact" to="disturbance"
									  onClick={() => onFaqLinkClicked(GA4EventAction.disturbance_report)}>
							<ResourceText textType="MarkdownInline" resourceKey="Dashboard_FAQ_Link_Disturbance"/>
						</InternalLink>
					</li>
					<li className="faq-lift">
						<a href={getResourceText("Dashboard_FAQ_Url_BroadBand")}
						   onClick={() => onFaqLinkClicked(GA4EventAction.broadband)}>
							<ResourceText textType="MarkdownInline" resourceKey="Dashboard_FAQ_Link_BroadBand"/>
						</a>
					</li>
					<li className="faq-lift">
						<a href={getResourceText("Dashboard_FAQ_Url_Faq")}
						   onClick={() => onFaqLinkClicked(GA4EventAction.faq)}>
							<ResourceText textType="MarkdownInline" resourceKey="Dashboard_FAQ_Link_OtherQuestions"/>
						</a>
					</li>
				</ul>
			</div>
		</section>
	);
};

export default FaqLifts;
