import {useResource} from "@kojamo/react-utils";
import { useSelector } from "react-redux";
import { IShellState } from "../../rootReducer";
import React,{ FC } from "react";

interface ApartmentSelectProps {
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ApartmentSelect: FC<ApartmentSelectProps> = ({ onChange }) => {
	const apartments = useSelector((state: IShellState) => state.anonymousState.apartments);
	const isLoadingApartments = useSelector((state: IShellState) => state.anonymousState.isServiceRequestApartmentDataLoading);
	const {getResourceText} = useResource();
	return (
		<select id="ApartmentAddress" onChange={onChange} disabled={isLoadingApartments}>
			<option value="">
				{getResourceText("ServiceRequestPage_Option_Choose")}
			</option>
	{apartments.map((a) => (
		<option key={a.id} value={a.id}>
			{a.streetAddress} {a.apartmentAddress}
		</option>
	))}
      <option value="other">
		  {getResourceText("ServiceRequestPage_Option_OtherSpace")}
	</option>
	</select>
	);
};
	export default ApartmentSelect;
