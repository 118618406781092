import { UILanguage } from "../rootReducer";
import { extract } from 'query-string';

interface LocalizedRoute {
	fi: string;
	en: string;
}

const urlSegmentRegEx = /:\w+(\([^\s/]+\))?/g;

function generatePathRegex(pathTemplate: string): RegExp {
	// Regular expression for URL segments, capturing .html endings as well
	const replacedTemplate = pathTemplate.replace(urlSegmentRegEx, '([\\w-\\.]+)?');
	return new RegExp(`^${replacedTemplate}$`);
}

function replaceDynamicSegments(pathTemplate: string, values: string[]): string {
	let result = pathTemplate;
	values.forEach(value => {
		const match = result.match(urlSegmentRegEx);
		if (match) {
			result = result.replace(match[0], value);
		}
	});
	return result;
}

export function getTranslatedUrlForRoute(routes: Record<string, LocalizedRoute>, url: string, targetLanguage: UILanguage, isLoggedIn: boolean): string | undefined {
	const sourceLanguage: UILanguage = targetLanguage === 'en' ? 'fi' : 'en';
	if (url.endsWith('/') && isLoggedIn) {
		url = url.slice(0, -1);
	}

	for (const routeKey of Object.keys(routes)) {
		const route = routes[routeKey];
		const routeRegex = generatePathRegex(route[sourceLanguage]);

		let match = url.match(routeRegex);
		let hasContractId = false;
		if (!match && isLoggedIn) {
			hasContractId = true;
			const routeWithContractIdRegex = generatePathRegex(prependContractId(route[sourceLanguage]));
			match = url.match(routeWithContractIdRegex);
		}

		if (match) {
			const queryString = extract(url);
			const dynamicSegments = match.slice(1);
			const translatedUrl = replaceDynamicSegments(hasContractId ? prependContractId(route[targetLanguage]) : route[targetLanguage], dynamicSegments);
			return queryString ? `${translatedUrl}?${queryString}` : translatedUrl;
		}
	}
	return undefined;
}

function prependContractId(url: string): string {
	return `/:contractNumber${url}`;
}
