import { ITileConfiguration } from "../../Tiles/TileConfiguration";
import {
	createAsyncAction,
	ActionType,
	createAction
} from "typesafe-actions";
import { IUserContext, UILanguage } from "../../rootReducer";
import { IGiosgAM } from "./Giosg/GiosgAM";
import { IArticleRequest, IContentArticleAM, IWelcomePageInfoAM } from "src/ApiClient";

export interface ILoadTilesRequest {
	user: IUserContext;
	contractId: string;
}

export interface ILoadWelcomePageInfoRequest {
	user: IUserContext;
	contractId: string;
}

export interface ILoadGiosgTokenRequest {
	user: IUserContext;
	contractId: string;
}

export const dashboardActions = {
	loadTiles: createAsyncAction(
		"dashboard/LOAD_TILES_REQUEST",
		"dashboard/LOAD_TILES_SUCCESS",
		"dashboard/LOAD_TILES_FAILED"
	)<ILoadTilesRequest, ITileConfiguration[], Error>(),
	loadGiosgToken: createAsyncAction(
		"dashboard/LOAD_GIOSGTOKEN_REQUEST",
		"dashboard/LOAD_GIOSGTOKEN_SUCCESS",
		"dashboard/LOAD_GIOSGTOKEN_FAILED"
	)<ILoadGiosgTokenRequest, IGiosgAM, Error>(),
	setChangeApartmentModalVisibility: createAction(
		"dashboard/SET_CHANGE_APARTMENT_MODAL_VISIBILITY"
	)<boolean>(),
	setSiteSurveyModalVisibility: createAction(
		"dashboard/SET_SITE_SURVEY_MODAL_VISIBILITY"
	)<boolean>(),
	setDirectRentalSurveyModalVisibility: createAction(
		"dashboard/SET_DIRECT_RENTAL_SURVEY_MODAL_VISIBILITY"
	)<boolean>(),
	switchLanguage: createAction("dashboard/SWITCH_LANGUAGE")<
		UILanguage
	>(),
	getArticlesByTag: createAsyncAction(
		"anonymous/GET_ARTICLES_BY_TAG_REQUEST",
		"anonymous/GET_ARTICLES_BY_TAG_SUCCESS",
		"anonymous/GET_ARTICLES_BY_TAG_FAILED"
	)<IArticleRequest, IContentArticleAM[], Error>(),
	loadWelcomePageInfo: createAsyncAction(
		"dashboard/LOAD_WELCOME_PAGE_REQUEST",
		"dashboard/LOAD_WELCOME_PAGE_SUCCESS",
		"dashboard/LOAD_WELCOME_PAGE_FAILED"
	)<ILoadWelcomePageInfoRequest, IWelcomePageInfoAM, Error>()
};

export type DashboardActionTypes = ActionType<typeof dashboardActions>;
