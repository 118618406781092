import { IUserContext, UILanguage } from "../../rootReducer";
import { ApiClient } from "../../ApiClient";

export async function persistUserLanguage(
	language: UILanguage,
	user: IUserContext | null
) {
	if (user !== null) {
		const api: ApiClient = new ApiClient(language, user);
		try {
			await api.changeCustomerLanguage(language);
		} catch (e) {
			if (e instanceof Error) {
				console.error(`Error changing customer language: ${e.name}: ${e.message}`);
			} else {
				console.error("An error occurred while changing customer language.");
			}
		}
	}
}
