import { useResource } from "@kojamo/react-utils";
import {FC, MouseEvent} from "react";


interface ResourceLinkProps {
	className: string;
	hrefKey?: string;
	resourceKey: string;
	target?: string;
	onClick?: (ev: MouseEvent) => void;
}

export const ResourceLink: FC<ResourceLinkProps> = ({className, hrefKey, resourceKey, target, onClick}) => {
	const { getResourceText } = useResource();

	const url = hrefKey ? getResourceText(hrefKey) : "#";
	const linkText = getResourceText(resourceKey);

	return (
		<a className={className} href={url} target={target} onClick={onClick}>
			{linkText}
		</a>
	);
};
