import { IGiosgAM } from "../../Pages/Dashboard/Giosg/GiosgAM";

export const closeGiosgChats = () => {
	if (typeof _giosg !== 'undefined') {
		_giosg('chat', 'close', {hide: true, clearHistory: true});
	}
}

interface IGiosgAllowedRoom {
	id: string;
	legacy_id: string;
}

interface IGiosgObject {
	allowedRooms: IGiosgAllowedRoom[];
}

export function setGiosgVisitorData({token}: IGiosgAM) {
	const giosg = ((window as any).giosg) as IGiosgObject;
	if (giosg && giosg.allowedRooms && _giosg) {
		setGiosgVisitorDataForRooms(giosg.allowedRooms, token);
	} else {
		// Giosg script is not yet loaded (probably because of missing cookie consent),
		// try again in 500ms
		setTimeout(() => setGiosgVisitorData({token}), 500);
	}
}

function setGiosgVisitorDataForRooms(rooms: IGiosgAllowedRoom[], token: string) {
	_giosg && _giosg("visitor", "submit", token, "hs256", true, rooms.map(({id}) => ({id})));
}

export const sessionStorageUserIdKey = "userId";
