import "../../Styles/style.scss";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { IAuthenticatedShellState, IUserContext } from "../../rootReducer";
import { contractContextActions, ILoadContractsRequest } from "./contractContextActions";
import { IContractAM } from "../../ApiClient";
import ContractNotFound from '../../Pages/ContractNotFound';
import { Location } from 'history';
import { ResourceText } from "@kojamo/react-utils";
import { authenticatedRoutes } from "../authenticatedRoutes";
import Layout from "../../Layout";
import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router";
import { getPathWithContractNumber, parseContractNumberFromPath } from "./contractUtils";

export interface IContractContextStateProps {
	user: IUserContext;
	contract: IContractAM | null;
	isLoadingContracts: boolean;
}

export type IContractContextProps = { children: ReactNode } & IContractContextStateProps &
	IContractContextDispatchProps;

interface IContractContextState {
	pathname: string;
	contractNumberFromUrl: string | null;
	pathWithoutContractNumber: string;
}

export interface IContractContextDispatchProps {
	loadContracts: (request: ILoadContractsRequest) => any;
}

interface PathInfo { pathname: string, query: string, hash: string, fullPath: string }

function ContractContext(props: IContractContextProps) {
	const location = useLocation();
	const history = useHistory();
	const state = parseStateFromUrl(getPathFromLocation(location));

	useEffect(() => {
		props.loadContracts({ user: props.user, defaultContractNumber: state.contractNumberFromUrl });
	}, []);

	useEffect(() => {
		if (!Object.values(authenticatedRoutes.welcome).includes(location.pathname) && (props.contract && (!state.contractNumberFromUrl || state.contractNumberFromUrl !== props.contract.contractNumber))) {
			history.replace(getPathWithContractNumber(props.contract.contractNumber, state.pathWithoutContractNumber))
		}
	}, [location.pathname, props.contract?.contractNumber, location.search, state.contractNumberFromUrl]);

	if (props.isLoadingContracts) {
		return <p className="loading-message"><ResourceText textType="MarkdownInline"
			resourceKey="ContractContext_Text_LoadingContractData" />
		</p>;
	}

	if (!props.contract) {
		return <Layout pageIdentifier="no-contract"><ContractNotFound /></Layout>
	}

	return <>{props.children}</>;
}

const mapStateToProps = ({
	user,
	contracts
}: IAuthenticatedShellState): IContractContextStateProps => ({
	user,
	contract: contracts.selectedContract,
	isLoadingContracts: contracts.loadingContracts || !contracts.contractsLoaded
});

export default connect(
	mapStateToProps,
	{ loadContracts: contractContextActions.loadContracts.request }
)(ContractContext);

function getPathFromLocation(location: Location<any>): PathInfo {
	const { pathname, search: query, hash } = location;
	return { pathname, query, hash, fullPath: `${pathname}${query}${hash}` };
}

function parseStateFromUrl(path: PathInfo): IContractContextState {
	const contractNumberFromUrl = parseContractNumberFromPath(path.pathname);

	return {
		pathname: path.pathname,
		contractNumberFromUrl,
		pathWithoutContractNumber: contractNumberFromUrl
			? path.fullPath.substring(contractNumberFromUrl.length + 1)
			: path.fullPath
	};
}
