import {
	IconAllBenefits,
	IconCalendar,
	IconFeedback,
	IconHome,
	IconRentalContract,
	SpacingContainer
} from "@kojamo/lumo-dls";
import {ResourceText, useResource} from "@kojamo/react-utils";
import {authenticatedRoutes} from "../../Routing/authenticatedRoutes";
import {Link} from "react-router-dom";
import {useAppLanguage} from "../../Common/Localization/useAppLanguage";
import {useContract} from "../../Routing/ContractContext/useContract";
import {useSelector} from "react-redux";
import {IShellState, IUserContext} from "../../rootReducer";
import FeedView from "../../Components/Feed/FeedView";

interface DashboardQuickLinksStateProps {
	hasMessages: boolean;
	user: IUserContext | null;
	cdnBaseUrl?: string;
}

export function DashboardQuickLinks() {
	const { hasMessages, user, cdnBaseUrl} = useSelector<IShellState, DashboardQuickLinksStateProps>(({user, feed, settings}) => ({hasMessages: Boolean(feed.messages.length), user, cdnBaseUrl: settings.cdnBaseUrl}));
	const { getResourceImageUrlByKey } = useResource();
	return (
		<div className="DashboardQuickLinks__container" style={{backgroundImage: `url(${getResourceImageUrlByKey(cdnBaseUrl, "FrontPage_Hero_Background")}`}}>
			<SpacingContainer direction="column" className="DashboardQuickLinks l-padding-6-y l-padding-4-x l-padding-2-x-mobile l-padding-7-b-mobile">
				<SpacingContainer justify="edges">
					<h2 className={`DashboardQuickLinks__greeting l-font-quote l-text-ui-white l-margin-0 l-padding-4-t l-padding-6-b l-padding-7-b-mobile l-padding-5-x l-padding-2-x-mobile ${hasMessages ? "l-visible-desktop-only" : ""}`}>
						<ResourceText textType="Plain" resourceKey="FrontPage_HeroGreeting" parameters={[user?.firstName ?? ""]} />
					</h2>
					<FeedView />
				</SpacingContainer>
				<HeroLinks />
			</SpacingContainer>
		</div>
	);
}

function HeroLinks() {
	const {selectedContract} = useContract();
	const hasSauna = Boolean(selectedContract?.reservationServiceAccess?.onlineSaunaReservation);
	const uiLanguage = useAppLanguage();
	const buttonClasses = "l-button is-mega";
	return (
		<SpacingContainer justify="center" columnGap={6} rowGap={6} columnGapMobile={4} rowGapMobile={4} wrap>
			<Link to={authenticatedRoutes.rent[uiLanguage]} className={buttonClasses}>
				<IconRentalContract />
				<ResourceText textType="Plain" resourceKey="FrontPage_HeroLinks_Contract" />
			</Link>
			<Link to={authenticatedRoutes.house[uiLanguage]} className={buttonClasses}>
				<IconHome />
				<ResourceText textType="Plain" resourceKey="FrontPage_HeroLinks_HouseAndFacilities" />
			</Link>
			<Link to={(hasSauna ? authenticatedRoutes.sauna : authenticatedRoutes.parkingSpace)[uiLanguage]} className={buttonClasses}>
				<IconCalendar />
				<ResourceText textType="Plain" resourceKey="FrontPage_HeroLinks_Reservations" />
			</Link>
			<Link to={authenticatedRoutes.contact[uiLanguage]} className={buttonClasses}>
				<IconFeedback />
				<ResourceText textType="Plain" resourceKey="FrontPage_HeroLinks_Contact" />
			</Link>
			<Link to={authenticatedRoutes.services[uiLanguage]} className={buttonClasses}>
				<IconAllBenefits />
				<ResourceText textType="Plain" resourceKey="FrontPage_HeroLinks_Services" />
			</Link>
		</SpacingContainer>
	);
}
