import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';
import { User } from 'oidc-client';
import { IUserMetadataAM } from 'src/ApiClient';
import { IUserContext, UILanguage } from 'src/rootReducer';
import { ApiCallError } from 'src/ApiCallError';

export interface ILoginRequest {
	returnUrl?: string;
}

export interface ILoginSuccess {
	user: User;
	metadata: IUserMetadataAM;
}

export interface IRefreshSuccess {
	user: User;
	metadata?: IUserMetadataAM;
}

export interface ILogAndCompleteLogin {
	user: User;
	source?: string;
	contractId?: string;
}
interface ISaveSiteSurveyActionRequestStarted {
	user: IUserContext;
	customerContinuedToSurvey: boolean;
}
interface ISaveSiteSurveyActionRequestCompleted {
	user: IUserContext;
	customerCompletedSurvey: boolean;
}
export type ISaveSiteSurveyActionRequest = ISaveSiteSurveyActionRequestStarted | ISaveSiteSurveyActionRequestCompleted;

export const authActions = {
	login: createAsyncAction(
		'AUTH/LOGIN_REQUEST',
		'AUTH/LOGIN_SUCCESS',
		'AUTH/LOGIN_FAILURE'
	)<ILoginRequest, ILoginSuccess, Error>(),

	refresh: createAsyncAction(
		'AUTH/REFRESH_LOGIN_REQUEST',
		'AUTH/REFRESH_LOGIN_SUCCESS',
		'AUTH/REFRESH_LOGIN_FAILURE'
	)<void, IRefreshSuccess, void>(),

	logAndCompleteLogin: createAction(
		'AUTH/LOG_AND_COMPLETE_LOGIN'
	)<ILogAndCompleteLogin>(),

	logout: createAsyncAction(
		'AUTH/LOGOUT_REQUEST',
		'AUTH/LOGOUT_SUCCESS',
		'AUTH/LOGOUT_FAILURE'
	)<void, void, Error>(),

	switchLanguageOnLogin: createAction("AUTH/SWITCH_LANGUAGE_ON_LOGIN")<
		UILanguage
	>(),

	markWelcomeReady: createAction(
		'AUTH/WELCOME_READY'
	)<void>(),

	saveSiteSurveyAction: createAsyncAction(
		"AUTH/SAVE_SITE_SURVEY_ACTION_REQUEST",
		"AUTH/SAVE_SITE_SURVEY_ACTION_SUCCESS",
		"AUTH/SAVE_SITE_SURVEY_ACTION_FAILED"
	)<ISaveSiteSurveyActionRequest, void, Error>(),

	error: createAction('AUTH/ERROR')<ApiCallError>(),
}

export type AuthActionTypes = ActionType<typeof authActions>;
