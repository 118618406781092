import * as React from 'react';
import {IAuthenticatedShellState, IUserContext, UILanguage} from '../../rootReducer';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Stack } from 'src/Components/Stack';
import { feedActions, ILoadFeedMessagesRequest, IFeedMessageAM, IChangeFeedMessageStatusRequest, ILogFeedMessageFollowRequest } from './feedActions';
import { IContractAM } from 'src/ApiClient';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/fi';
import InternalLink from '../InternalLink';
import orderBy from 'lodash.orderby';
import { addQueryParameterToUrl } from 'src/Tooling';
import {ResourceText} from "@kojamo/react-utils";
import {authenticatedRoutes} from "../../Routing/authenticatedRoutes";
import {Button, IconBell, IconUIClose, Paper, SpacingContainer} from "@kojamo/lumo-dls";

export interface IFeedViewStateProps {
	user: IUserContext;
	contract: IContractAM;
	isLoading: boolean;
	hasMessages: boolean;
	currentMessages: IFeedMessageAM[];
	uiLanguage: UILanguage;
}

export type IFeedViewProps = IFeedViewStateProps & IFeedViewDispatchProps;

export const MomentCalendarStrings = {
	"fi": {
		sameDay: '[tänään klo] LT',
		lastDay: '[eilen klo] LT',
		lastWeek: 'ddd [klo] LT',
		sameElse: 'ddd l'
	},
	"en": {
		sameDay: '[today at] LT',
		lastDay: '[yesterday at] LT',
		lastWeek: 'ddd [at] LT',
		sameElse: 'ddd l'
	}
};


class FeedView extends React.Component<IFeedViewProps> {
	constructor(props: IFeedViewProps, context?: any) {
		super(props, context);
		Moment.globalLocale = props.user.language;

		if (!this.props.isLoading) {
			this.props.loadMessages({
				user: this.props.user,
				contractId: this.props.contract.contractId
			});
		}
	}

	public render() {
		return this.props.hasMessages ? (
			<SpacingContainer className="message-stack" width="parent" direction="column">
				{this.props.currentMessages.length > 0 &&
					<Stack>
						{this.props.currentMessages.map((message, index) => (
							<FeedMessage
								key={`${message.id}${this.props.uiLanguage}`}
								message={message}
								language={this.props.uiLanguage}
								onClick={() => this.linkClicked(message)}
								onClose={() => this.itemClosed(index)}
							/>
						))}
					</Stack>
				}
				<div className="l-padding-3-y">
					<Link
						to={authenticatedRoutes.messageHistory[this.props.uiLanguage]}
						className="l-text-ui-white l-font-body is-fullwidth l-display-flex l-justify-end l-justify-start-mobile"
					>
						<ResourceText textType="MarkdownInline" resourceKey="FeedView_Link_EarlierMessages" />
					</Link>
				</div>
			</SpacingContainer>
		) : null;
	}

	private itemClosed = (index: number) => {
		this.props.changeMessageStatus({
			user: this.props.user,
			messageId: this.props.currentMessages[index].id,
			newStatus: "UserClosed"
		});
	};

	private linkClicked(message: IFeedMessageAM): void {
		this.props.logMessageFollow({
			user: this.props.user,
			messageId: message.id,
			followType: "MyLumoUI/FeedView"
		});
	};
}

interface FeedMessageProps {
	message: IFeedMessageAM;
	language: UILanguage;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
	onClose?: () => void
}

function FeedMessage({message, language, onClick, onClose}: FeedMessageProps) {
	const localizedContent = message.localizedContents.find(c => c.language === language.toString()) ?? message.localizedContents[0];
	return (
		<InternalLink
			to={addQueryParameterToUrl(localizedContent.linkUri, "fc", "f")}
			onClick={onClick}
		>
			<Paper className="feed-item l-background-brand-background-lighter">
				<SpacingContainer justify="edges" align="start">
					<SpacingContainer className="l-padding-4-l l-padding-4-y" align="center" columnGap={2}>
						<SpacingContainer inline justify="center" align="center" className="l-background-brand-accent l-rounding-round l-padding-2">
							<IconBell height={24} width={24} className="l-text-ui-white" />
						</SpacingContainer>
						<SpacingContainer direction="column">
							<span className="l-font-body is-xs l-text-brand-primary"><Moment calendar={MomentCalendarStrings[language]} date={message.created} /></span>
							<span className="l-font-body is-xs l-text-ui-black feed-item__content" title={localizedContent.body}>{localizedContent.body}</span>
						</SpacingContainer>
					</SpacingContainer>
					<Button variant="icon" onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						onClose && onClose();
					}}
					>
						<IconUIClose className="l-text-ui-highlight-dark" />
					</Button>
				</SpacingContainer>
			</Paper>
		</InternalLink>
	);
}

export interface IFeedViewDispatchProps {
	loadMessages: (request: ILoadFeedMessagesRequest) => any;
	changeMessageStatus: (request: IChangeFeedMessageStatusRequest) => any;
	logMessageFollow: (request: ILogFeedMessageFollowRequest) => any;
}

const mapStateToProps = ({ user, feed, contracts, dashboard }: IAuthenticatedShellState): IFeedViewStateProps => {

	const timeLimit: Date = moment().subtract(2, 'days').toDate();

	let currentMessages = feed.messages.filter(m =>
		m.priority !== "High" &&
		(m.status === "Active" && (!m.firstRead || m.firstRead > timeLimit))
	);
	currentMessages = orderBy(currentMessages, [m => !m.firstRead ? 0 : 1, m => m.created], ['asc', 'desc']);

	return {
		user,
		isLoading: feed.loadingMessages,
		hasMessages: !!feed.messages.length,
		currentMessages,
		contract: contracts.selectedContract!,
		uiLanguage: dashboard.uiLanguage
	};
};

export default connect(
	mapStateToProps,
	{
		loadMessages: feedActions.loadMessages.request,
		changeMessageStatus: feedActions.changeMessageStatus.request,
		logMessageFollow: feedActions.logMessageFollow.request
	}
)(FeedView);
