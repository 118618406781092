import { useLocation } from "react-router";
import { anonymousRoutes } from "../../Routing/anonymousRoutes";
import { Helmet } from "react-helmet";

export function AnonymousViewCanonicalLink() {
	const location = useLocation();
	const anonymousRoutesArray = Object.values(anonymousRoutes).flatMap(values => Object.values(values));
	const isAnonymousView = anonymousRoutesArray.some(route => route === location.pathname);

	return isAnonymousView ? (
		<Helmet>
			<link rel="canonical" href={`https://my.lumo.fi${location.pathname}`} />
		</Helmet>
	) : null;
}
