import { call, put } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { dashboardActions } from "./dashboardActions";
import { ApiClient } from "../../ApiClient";
import { getApiClient } from "../../Common/getApiClient";
import { setGiosgVisitorData } from "../../lib/giosg";

export function* fetchGiosgTokenSaga(
    action: ActionType<typeof dashboardActions.loadGiosgToken.request>
): any {
    try {
		const api:ApiClient = yield call(getApiClient,action.payload.user);

        const giosg = yield call(
            [api, api.fetchGiosgToken],
            action.payload.contractId
        );
        yield put(dashboardActions.loadGiosgToken.success(giosg));
    } catch (e: any) {
        yield put(dashboardActions.loadGiosgToken.failure(e));
    }
}

export function* setGiosgVisitorDataSaga(
    action: ActionType<typeof dashboardActions.loadGiosgToken.success>
) {
    yield call(setGiosgVisitorData, action.payload);
}

