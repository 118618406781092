import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { FeedActionTypes, feedActions, IFeedMessageAM, FeedMessageStatus } from './feedActions';


export interface IFeedState {
	loadingMessages: boolean;
	lastLoaded: Date;
	messages: IFeedMessageAM[];
}

const initialState: IFeedState = {
	loadingMessages: false,
	lastLoaded: new Date(0),
	messages: []
}

export const feedReducer: Reducer<IFeedState, FeedActionTypes> = (state = initialState, action) => {
	switch (action.type) {

		case getType(feedActions.loadMessages.request):
			return { ...state, loadingMessages: true };
		case getType(feedActions.loadMessages.success):
			return {
				loadingMessages: false,
				lastLoaded: action.payload.useCachedData ? state.lastLoaded : new Date(),
				messages: action.payload.useCachedData ? state.messages : action.payload.messages
			};
		case getType(feedActions.loadMessages.failure):
			return { ...state, loadingMessages: false };

		case getType(feedActions.changeMessageStatus.request):
			return state;
		case getType(feedActions.changeMessageStatus.success):
			return { ...state, messages: changeLocalMessageStatus(action.payload.messageId, action.payload.newStatus, state.messages) };
		case getType(feedActions.changeMessageStatus.failure):
			return state;

		case getType(feedActions.logMessageFollow.request):
			return state;
		case getType(feedActions.logMessageFollow.success):
			return { ...state, messages: logLocalMessageFollow(action.payload, state.messages) };
		case getType(feedActions.logMessageFollow.failure):
			return state;

		case getType(feedActions.clearFeedMessageCache):
			return initialState;
	}

	return state;
}


function changeLocalMessageStatus(messageId: string, newStatus: FeedMessageStatus, messages: IFeedMessageAM[]): IFeedMessageAM[] {
	const converter = (message: IFeedMessageAM) : IFeedMessageAM => {
		return { ...message, status: newStatus };
	}

	return changeLocalMessage(messageId, converter, messages);
}

function logLocalMessageFollow(messageId: string, messages: IFeedMessageAM[]): IFeedMessageAM[] {
	const converter = (message: IFeedMessageAM): IFeedMessageAM => {
		if (message.firstRead) { return message; }
		return { ...message, firstRead: new Date() };
	}

	return changeLocalMessage(messageId, converter, messages);
}

function changeLocalMessage(messageId: string, converter: (message: IFeedMessageAM) => IFeedMessageAM, messages: IFeedMessageAM[]): IFeedMessageAM[] {
	if (!messages) { return messages; }

	return messages.map(m => {
		if (m.id !== messageId) { return m; }
		return converter(m);
	});
}
