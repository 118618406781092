import { createAsyncAction, ActionType } from 'typesafe-actions';
import BusinessEntityMaintenanceInformationAM, { ApartmentBasicAM } from "./businessEntityMaintenanceInformationAM";
import { IArticleRequest, IContentArticleAM } from 'src/ApiClient';

export const anonymousActions = {
	loadMaintenanceInformation: createAsyncAction(
		'anonymous/LOAD_MAINTENANCEINFORMATION_REQUEST',
		'anonymous/LOAD_MAINTENANCEINFORMATION_SUCCESS',
		'anonymous/LOAD_MAINTENANCEINFORMATION_FAILED'
	)<void, BusinessEntityMaintenanceInformationAM, Error>(),
	loadApartments: createAsyncAction(
		'anonymous/LOAD_APARTMENTS_REQUEST',
		'anonymous/LOAD_APARTMENTS_SUCCESS',
		'anonymous/LOAD_APARTMENTS_FAILED'
	)<string, ApartmentBasicAM[], Error>(),
	getArticlesByTag: createAsyncAction(
		'anonymous/GET_ARTICLES_BY_TAG_REQUEST',
		'anonymous/GET_ARTICLES_BY_TAG_SUCCESS',
		'anonymous/GET_ARTICLES_BY_TAG_FAILED'
	)<IArticleRequest, IContentArticleAM[], Error>(),
};

export type AnonymousActionTypes = ActionType<typeof anonymousActions>;

