import { takeLatest, call, put } from "redux-saga/effects";
import { getType, ActionType } from "typesafe-actions";
import { anonymousActions } from "./anonymousActions";
import { ApiClient } from "../../ApiClient";
import { getApiClient } from "../../Common/getApiClient";

function* fetchMaintenanceInformationSaga() : any {
	try {
		const api:ApiClient = yield call(getApiClient);

		const maintenanceInformation = yield call(
			[api, api.fetchMaintenanceInformation]
		);
		yield put(anonymousActions.loadMaintenanceInformation.success(maintenanceInformation));
	} catch (e: any) {
		yield put(anonymousActions.loadMaintenanceInformation.failure(e));
	}
}

function* fetchApartmentsSaga(
	action: ActionType<typeof anonymousActions.loadApartments.request>
) : any {
	try {
		const api:ApiClient = yield call(getApiClient);

		const apartments = yield call(
			[api, api.getApartments], action.payload
		);
		yield put(anonymousActions.loadApartments.success(apartments));
	} catch (e: any) {
		yield put(anonymousActions.loadApartments.failure(e));
	}
}

function* fetchArticlesSaga(action: ActionType<typeof anonymousActions.getArticlesByTag.request>) : any {
	try {
		const api:ApiClient = yield call(getApiClient);
		const results = yield call([api, api.getArticlesByTag], action.payload);
		yield put(anonymousActions.getArticlesByTag.success(results));
	} catch (e: any) {
		yield put(anonymousActions.getArticlesByTag.failure(e));
	}
}

export function* anonymousSaga() {
	yield takeLatest(
		getType(anonymousActions.loadMaintenanceInformation.request),
		fetchMaintenanceInformationSaga
	);
	yield takeLatest(
		getType(anonymousActions.loadApartments.request),
		fetchApartmentsSaga
	);
	yield takeLatest(
		getType(anonymousActions.getArticlesByTag.request),
		fetchArticlesSaga
	);
}
