import { ITileConfiguration } from "../../Tiles/TileConfiguration";
import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";
import { DashboardActionTypes, dashboardActions } from "./dashboardActions";
import { IGiosgAM } from "./Giosg/GiosgAM";
import { UILanguage } from "src/rootReducer";
import { ApiOperationState, IContentArticleAM, IWelcomePageInfoAM } from "src/ApiClient";
import { authActions } from "../../Authentication/authenticationActions";

export interface IDashboardState {
	loadingTiles: boolean;
	tiles: ITileConfiguration[];
	giosg?: IGiosgAM;
	changeApartmentModalVisible: boolean;
	siteSurveyModalVisible: boolean;
	uiLanguage: UILanguage;
	lifestyleArticles: IContentArticleAM[];
	lifestyleArticlesFetchOperationState?: ApiOperationState | undefined;
	directRentalSurveyVisible: boolean;
	welcomePage?: IWelcomePageInfoAM;
}

export const initialDashboardState: IDashboardState = {
	loadingTiles: false,
	tiles: [],
	changeApartmentModalVisible: false,
	siteSurveyModalVisible: false,
	uiLanguage: "fi",
	lifestyleArticles: [],
	directRentalSurveyVisible: false
};

export const dashboardReducer: Reducer<
	IDashboardState,
	DashboardActionTypes | ActionType<typeof authActions.login.success> | ActionType<typeof authActions.refresh.success> | ActionType<typeof authActions.switchLanguageOnLogin>
> = (state = initialDashboardState, action) => {
	switch (action.type) {
		case getType(dashboardActions.loadTiles.request):
			return { ...state, loadingTiles: true, tiles: [] };
		case getType(dashboardActions.loadTiles.success):
			return { ...state, loadingTiles: false, tiles: action.payload };
		case getType(dashboardActions.loadGiosgToken.success):
			return { ...state, giosg: action.payload };
		case getType(dashboardActions.setChangeApartmentModalVisibility):
			return { ...state, changeApartmentModalVisible: action.payload };
		case getType(dashboardActions.setSiteSurveyModalVisibility):
			return { ...state, siteSurveyModalVisible: action.payload };
		case getType(dashboardActions.setDirectRentalSurveyModalVisibility):
			return { ...state, directRentalSurveyVisible: action.payload };
		case getType(dashboardActions.switchLanguage):
		case getType(authActions.switchLanguageOnLogin):
			return { ...state, uiLanguage: action.payload };
		case getType(authActions.login.success):
				return action.payload.metadata.language ?  {...state, uiLanguage: action.payload.metadata.language as UILanguage} : state


		case getType(dashboardActions.getArticlesByTag.request):
			return {
				...state,
				lifestyleArticlesFetchOperationState:
					ApiOperationState.Requested
			};
		case getType(dashboardActions.getArticlesByTag.success):
			return {
				...state,
				lifestyleArticlesFetchOperationState:
					ApiOperationState.Succeeded,
				lifestyleArticles: action.payload
			};
		case getType(dashboardActions.getArticlesByTag.failure):
			return {
				...state,
				lifestyleArticlesFetchOperationState: ApiOperationState.Failed
			};
		case getType(dashboardActions.loadWelcomePageInfo.request):
		case getType(dashboardActions.loadWelcomePageInfo.failure):
			return state;
		case getType(dashboardActions.loadWelcomePageInfo.success):
			return {...state, welcomePage: action.payload };
	}
	return state;
};
