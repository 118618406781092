import { takeLatest, takeEvery, call, put, select } from "redux-saga/effects";
import { getType, ActionType } from "typesafe-actions";
import { feedActions } from "./feedActions";
import { ApiClient } from "../../ApiClient";
import { IShellState } from 'src/rootReducer';
import { getApiClient } from "../../Common/getApiClient";

const getLastLoaded = (state: IShellState) => state.feed.lastLoaded;

function* loadMessagesSaga(
	action: ActionType<typeof feedActions.loadMessages.request>) : any {

	const lastLoaded: Date = yield select(getLastLoaded);
	const minutesToCacheMessages = 2;
	const timeLimit: Date = (d => new Date(d.setMinutes(d.getMinutes() - minutesToCacheMessages)))(new Date);
	if (lastLoaded > timeLimit) { // new enough
		yield put(feedActions.loadMessages.success({ messages: [], useCachedData: true }));
		return;
	}

	// Not new enough -> get from server
	try {
		const api:ApiClient = yield call(getApiClient,action.payload.user);

		const messages = yield call(
			[api, api.fetchFeedMessages],
			action.payload.contractId
		);
		yield put(feedActions.loadMessages.success({ messages, useCachedData: false }));
	} catch (e: any) {
		yield put(feedActions.loadMessages.failure(e));
	}
}

function* changeMessageStatusSaga(
	action: ActionType<typeof feedActions.changeMessageStatus.request>) {

	try {
		const api:ApiClient = yield call(getApiClient,action.payload.user);

		yield call(
			[api, api.changeFeedMessageStatus],
			action.payload.messageId, action.payload.newStatus
		);
		yield put(feedActions.changeMessageStatus.success({ messageId: action.payload.messageId, newStatus: action.payload.newStatus }));
	} catch (e: any) {
		yield put(feedActions.changeMessageStatus.failure(e));
	}
}

function* logMessageFollowSaga(
	action: ActionType<typeof feedActions.logMessageFollow.request>) {

	try {
		const api:ApiClient = yield call(getApiClient,action.payload.user);

		yield call(
			[api, api.logFeedMessageFollow],
			action.payload.messageId, action.payload.followType
		);
		yield put(feedActions.logMessageFollow.success(action.payload.messageId));
	} catch (e: any) {
		yield put(feedActions.logMessageFollow.failure(e));
	}
}

export function* feedSaga() {

	yield takeLatest(getType(feedActions.loadMessages.request), loadMessagesSaga);
	yield takeEvery(getType(feedActions.changeMessageStatus.request), changeMessageStatusSaga);
	yield takeEvery(getType(feedActions.logMessageFollow.request), logMessageFollowSaga);
}
