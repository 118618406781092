import { UILanguage } from "../../rootReducer";
import Cookies from "universal-cookie";

const cookieNameForUserLanguage = "ui-language";

export function updateLanguageCookie(language: UILanguage, cookieDomain: string) {
	const cookies = new Cookies();
	const expDate: Date = new Date();
	expDate.setDate(expDate.getDate() + 30);

	// Remove existing cookie if any
	cookies.remove(cookieNameForUserLanguage, {
		domain: cookieDomain,
		path: "/",
	});

	// Set new cookie
	cookies.set(cookieNameForUserLanguage, language, {
		expires: expDate,
		domain: cookieDomain,
		path: "/",
	});
}
