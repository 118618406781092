import * as React from 'react';
import {Link, Redirect, RouteComponentProps} from 'react-router-dom';
import Layout, {setHtmlLang} from '../../Layout';
import BusinessEntityMaintenanceInformationAM, {
	BusinessPartnerAM,
	BusinessEntityBasicAM,
	ApartmentBasicAM
} from './businessEntityMaintenanceInformationAM';
import { anonymousActions } from './anonymousActions';
import {IShellState, UILanguage} from '../../rootReducer';
import { connect } from 'react-redux';
import { ILoginRequest, authActions } from '../../Authentication/authenticationActions';
import { anonymousRoutes } from 'src/Routing/anonymousRoutes';
import AnonymousViewCanonicalLink from "../../Components/AnonymousViewCanonicalLink";
import {ResourceText, useResource} from "@kojamo/react-utils";
import ApartmentSelect from "../../Components/AnonymousUser/ApartmentSelect";
import {ResourceLink} from "../../Components/ResourceLink";
import {authenticatedRoutes} from "../../Routing/authenticatedRoutes";


interface IFindMaintenanceContactPageStateProps {
	isLoggedIn: boolean;
	isDataLoaded: boolean;
	isDataLoading: boolean;
	isErrorLoadingData: boolean;
	maintenanceInformation: BusinessEntityMaintenanceInformationAM | null;
	uiLanguage: UILanguage;
	isLoadingApartments: boolean;
	apartments: ApartmentBasicAM[];
}

interface IFindMaintenanceContactPageState {
	selectedCity?: string;
	selectedMaintenancePartnerIndex?: number;
	apartmentId?: string;
}

type FindMaintenanceContactPageProps = IFindMaintenanceContactPageStateProps & IFindMaintenanceContactPageDispatchProps & RouteComponentProps<any>;

interface IContactInformationProps {
	address: string;
	city: string;
	businessPartner: BusinessPartnerAM;
	// Key management partner, only set if different than businessPartner
	keyManagementPartner: BusinessPartnerAM | null;
	onLoginClicked: (ev: React.MouseEvent) => void;
}

const BusinessPartnerContactInformation = (props: BusinessPartnerAM) => {
	return (
		<p>
			<strong>{props.name}</strong>
			<a href={`tel:${props.telephone}`} className="tel">{props.telephone}</a>
			<span>{props.address.street}<br />{props.address.zipCode} {props.address.postOffice}</span>
		</p>
	);
};

const PartnersContactInfo = (props: IContactInformationProps) => {
	const {getResourceText} = useResource()
	const bpContactInfo = BusinessPartnerContactInformation(props.businessPartner);
	if (props.keyManagementPartner) {
		return (<div className="partners-contact-info">
			{bpContactInfo}
			<h3>{getResourceText("FindMaintenanceContactPage_Heading_KeyManagement")}</h3>
			{BusinessPartnerContactInformation(props.keyManagementPartner)}
		</div>);
	}
	return bpContactInfo;
};

const ContactInformation = (props: IContactInformationProps) => {
	const {getResourceText} = useResource()
	return (
		<section className="content-block is-card">
			<div className="card">

				<div className="group">
					<h3 className="small-grey-heading">{props.address}, {props.city}</h3>
					<h2>{getResourceText("FindMaintenanceContactPage_Heading_MaintenanceService")}</h2>
					<p>
						{getResourceText("FindMaintenanceContactPage_Description_MaintenanceContactInfo")}
					</p>
					{PartnersContactInfo(props)}

					<p>
						<small className="tiny">
							{getResourceText("FindMaintenanceContactPage_Description_PhoneCallPricingInfo")}
						</small>
					</p>
					<p>
						<small className="tiny">
							{getResourceText("FindMaintenanceContactPage_Description_OtherContactInfo")}
						</small>
					</p>

					<a href="/" target="_top" className="button" onClick={props.onLoginClicked}>{getResourceText("FindMaintenanceContactPage_Link_LogIn")}</a>
				</div>
			</div>
		</section>
	);
}

export class FindMaintenanceContactPage extends React.Component<FindMaintenanceContactPageProps, IFindMaintenanceContactPageState> {

	constructor(props: FindMaintenanceContactPageProps, context?: any) {
		super(props, context);

		if (!this.props.isDataLoaded && !this.props.isDataLoading && !this.props.isErrorLoadingData) {
			this.props.loadMaintenanceInformation();
		}

		this.state = {
			selectedCity: undefined,
			selectedMaintenancePartnerIndex: undefined
		};
	}

	public componentDidMount() {
		setHtmlLang(this.props.uiLanguage);
	}

	public componentDidUpdate(prevProps: Readonly<FindMaintenanceContactPageProps>, prevState: Readonly<IFindMaintenanceContactPageState>) {

		if(this.props.maintenanceInformation && this.state.selectedCity && this.state.selectedMaintenancePartnerIndex !== undefined) {
			const businessEntity : BusinessEntityBasicAM = this.props.maintenanceInformation.businessEntitiesPerCity[this.state.selectedCity][this.state.selectedMaintenancePartnerIndex];
			const shouldLoadApartments = (!this.props.apartments || this.props.apartments.length === 0) && !this.props.isLoadingApartments
				|| this.state.selectedMaintenancePartnerIndex !== prevState.selectedMaintenancePartnerIndex
				|| this.state.selectedCity !== prevState.selectedCity;

			if(shouldLoadApartments) {
				if (businessEntity.id) {
					this.props.loadApartments(businessEntity.id);
				}
			}
		}
	}

	public render() {
		return this.props.isLoggedIn ? <Redirect to={authenticatedRoutes.contact[this.props.uiLanguage]} /> : (
			<>
				<AnonymousViewCanonicalLink />
				<Layout pageIdentifier="anonymous">
					<div>
						<section className="content-block is-card">
							<Link to={anonymousRoutes.contact[this.props.uiLanguage]} className="back-arrow back-to-home align-left">
								<svg width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
									<path fill="#F05183"
										d="M12 5c0 .4-.3.7-.7.7h-9L5 8.5c.3.3.3.7 0 1l-.4.2-.5-.2-4-4-.1-.2v-.6l.1-.2 4-4c.3-.2.7-.2 1 0 .2.3.2.7 0 1l-3 2.8h9.1c.4 0 .7.3.7.7z" />
								</svg>
								<ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Link_ContactInfo"/>
							</Link>

							<h1 className="icon-heading align-center">
								<ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Heading_Main"/>
							</h1>

							{this.props.isDataLoading ? (<p><ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Text_JustAMoment" /></p>) : null}

							{this.props.isDataLoaded && !!this.props.maintenanceInformation ? (
								<div className="card">
									<div className="group">
										<h3 className="align-center"><ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Heading_WhereDoYouLive"/></h3>
										<div className="input select">

											<label htmlFor="sel_city"><ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Label_City"/></label>

											<div className="select">
												<select id="sel_city" name="sel_city" value={this.state.selectedCity} onChange={this.cityChanged}>
													<option value='' />
													{this.props.maintenanceInformation!.cities.map(c => (<option value={c} key={c}>{c}</option>))}
												</select>
											</div>
										</div>

										<div className="input select">

											<label htmlFor="sel_address"><ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Label_Address"/></label>

											<div className={!!this.state.selectedCity ? "select" : "select disabled"}>
												<select id="sel_address" name="sel_address" disabled={!this.state.selectedCity} value={this.state.selectedMaintenancePartnerIndex} onChange={this.addressChanged}>
													<option value='' />
													{!!this.state.selectedCity
														? this.props.maintenanceInformation.businessEntitiesPerCity[this.state.selectedCity].map((be, index) => (<option value={index} key={index + be.name}>{be.name}</option>))
														: null}
												</select>
											</div>
										</div>
									</div>
								</div>
							) : null}
						</section>
						{this.renderContactInformation()}
						{this.renderServiceRequestSection()}
					</div>
				</Layout>
			</>
		);
	}

	private renderServiceRequestSection() {
		if (!this.props.isDataLoaded || !this.props.maintenanceInformation || this.state.selectedMaintenancePartnerIndex === undefined || !this.state.selectedCity) {
			return null;
		}
		const businessEntity = this.props.maintenanceInformation.businessEntitiesPerCity[this.state.selectedCity][this.state.selectedMaintenancePartnerIndex];

		return (
			<section className="content-block is-card">
				<div className="card">
					<div className="group">

						<h3><ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Heading_NonUrgentIssues"/></h3>
						<div style={{ marginBottom: 8, marginTop: 16 }}>
							<a href="/" target="_top" className="button" onClick={this.onLoginClicked}><ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Link_LogIntoService"/></a>
						</div>
						<hr style={{ marginBottom: 16, marginTop: 16 }} />
						<div className="input select">
							<label htmlFor="ApartmentAddress"><ResourceText textType="MarkdownInline" resourceKey="ServiceRequestPage_Label_Address"/></label>
							<div className="select">
								<ApartmentSelect onChange={this.apartmentChanged} />
							</div>
						</div>
						<div style={{ marginBottom: 16 }}>
							<Link to={`${anonymousRoutes.serviceRequest[this.props.uiLanguage]}?apartmentId=${this.state.apartmentId}&businessEntityId=${businessEntity.id}`}
								className={`button ${Boolean(this.state.apartmentId) ? "" : "disabled"}`}><ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Link_SendServiceRequest"/></Link>
						</div>
						<p>
							<small className="tiny">
								<ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Description_ServiceTime"/>
							</small>
						</p>
					</div>
				</div>
			</section>
		);
	}

	private apartmentChanged = (e: any) => {
		this.setState({ apartmentId: e.target.value });
	}

	private renderContactInformation() {
		if (!this.props.isDataLoaded || !this.props.maintenanceInformation || this.state.selectedMaintenancePartnerIndex === undefined || !this.state.selectedCity) {
			return null;
		}
		const businessEntity = this.props.maintenanceInformation.businessEntitiesPerCity[this.state.selectedCity][this.state.selectedMaintenancePartnerIndex] as BusinessEntityBasicAM;
		const maintanenceCompany = this.props.maintenanceInformation!.maintenanceCompanies[businessEntity.maintenanceCompanyId];

		if (!businessEntity || !maintanenceCompany) {
			return (<section className="content-block is-card">
				<div className="card">

					<div className="group">
						<h2><ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Heading_MaintenanceCompanyContactInfoNotFound" /></h2>
						<p>
							<small className="tiny">
								<ResourceText textType="MarkdownInline" resourceKey="FindMaintenanceContactPage_Description_MaintenanceCompanyContactInfoNotFound"/>
							</small>
						</p>
						<ResourceLink className="button" resourceKey="FindMaintenanceContactPage_Link_LogIn" onClick={this.onLoginClicked}/>
					</div>
				</div>
			</section>
			);
		}

		return <ContactInformation
			address={businessEntity.name}
			city={this.state.selectedCity!}
			businessPartner={maintanenceCompany}
			keyManagementPartner={businessEntity.keyManagementCompanyId !== businessEntity.maintenanceCompanyId
				? this.props.maintenanceInformation!.maintenanceCompanies[businessEntity.keyManagementCompanyId]
				: null}
			onLoginClicked={this.onLoginClicked}
		/>
	}

	private cityChanged = (e: any) => {
		this.setState({ selectedCity: e.target.value, selectedMaintenancePartnerIndex: undefined, apartmentId: undefined });
	};

	private addressChanged = (e: any) => {
		this.setState({ selectedMaintenancePartnerIndex: Number(e.target.value) });
	}

	private onLoginClicked = (ev: React.MouseEvent) => {
		ev.preventDefault();

		const location = this.props.location;
		const locationState = location.state as any;
		const loginProps = { returnUrl: locationState ? locationState.from.pathname + locationState.from.hash : undefined, culture: this.props.uiLanguage };

		this.props.login(loginProps);
	}
}

export interface IFindMaintenanceContactPageDispatchProps {
	login: (request: ILoginRequest) => void;
	loadMaintenanceInformation: () => void;
	loadApartments: (buisnessEntityId: string) => void;
}

const mapStateToProps = ({ anonymousState, dashboard, user }: IShellState): IFindMaintenanceContactPageStateProps => {
	return {
		isLoggedIn: Boolean(user),
		isDataLoaded: anonymousState.isDataLoaded,
		isDataLoading: anonymousState.isDataLoading,
		isErrorLoadingData: anonymousState.isErrorLoadingData,
		maintenanceInformation: anonymousState.maintenanceInformation,
		uiLanguage: dashboard.uiLanguage,
		isLoadingApartments: anonymousState.isServiceRequestApartmentDataLoading,
		apartments: anonymousState.apartments,
	};
};

export default connect(
	mapStateToProps,
	{
		login: authActions.login.request,
		loadMaintenanceInformation: anonymousActions.loadMaintenanceInformation.request,
		loadApartments: anonymousActions.loadApartments.request,
	})(FindMaintenanceContactPage);
