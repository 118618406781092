import * as React from "react";
import Layout from "../../Layout";
import { connect } from "react-redux";
import { IAuthenticatedShellState, IUserContext, UILanguage } from "../../rootReducer";
import { RouteComponentProps, withRouter } from "react-router";
import { embedActions, IEmbeddedUrl } from "./embedActions";
import NotFoundPage from "../NotFound";
import { IContractAM } from "../../ApiClient";
import {
	getTargetUrlByLocalizedPath,
	findUrlConfigurationByLocalizedUrl,
	findUrlConfigurationByTargetUrl,
	getEmbedUrlLocalizedPath, findUrlConfigurationByLogicalName
} from 'src/Routing/embeddedUrls';
import { addQueryParameterToUrl } from 'src/Tooling';
import { authActions } from 'src/Authentication/authenticationActions';
import { getPathWithContractNumber } from "../../Routing/ContractContext/contractUtils";
import {getLanguage, getLanguageByPath} from "./getLanguage";
import AnonymousViewCanonicalLink from "../../Components/AnonymousViewCanonicalLink";
import {authenticatedRoutes} from "../../Routing/authenticatedRoutes";
import {Loader} from "@kojamo/lumo-dls";
import {mainContentId} from "../../Components/Navigation/mainContentId";

export interface IEmbedPageLayoutParameters {
	simpleLayout?: boolean;
	keepMatchUrlInPath?: boolean;
	useContractId: boolean;
	hasAnonymousNavigation?: boolean;
}

export interface IEmbedPageStateProps {
	user: IUserContext;
	contract: IContractAM | null;
	frameUrl: string | null;
	redirectUrl?: string;
	embeddedUrlsLoaded: boolean;
	fetchingEmbeddedUrls: boolean;
	footer: string | null;
	fetchingFooter: boolean;
	urls: IEmbeddedUrl[];
	currentLanguage: UILanguage;
}

export type IEmbedPageProps = IEmbedPageStateProps &
	RouteComponentProps<any> & IEmbedPageLayoutParameters &
	IEmbedPageDispatchProps;

interface IEmbedPageState {
	styleUrl: string | null;
	mounted: boolean;
	hasCookieConsentResponse: boolean;
	cookiebotLoaded: boolean;
	iframeLoaded: boolean;
}

class EmbedPage extends React.Component<IEmbedPageProps, IEmbedPageState> {

	private iframe: React.RefObject<HTMLIFrameElement>;

	private styleElement: HTMLStyleElement | undefined;

	constructor(props: IEmbedPageProps, context?: any) {
		super(props, context);
		this.iframe = React.createRef();

		this.state = {
			hasCookieConsentResponse: window.Cookiebot?.hasResponse ?? false,
			cookiebotLoaded: Boolean(window.Cookiebot),
			styleUrl: null,
			mounted: false,
			iframeLoaded: false,
		};

		this.ensureInitialized();

		window.addEventListener("CookiebotOnLoad", () => this.setState({
			cookiebotLoaded: true,
		}));

		window.addEventListener("CookiebotOnConsentReady", () => this.setState({
			hasCookieConsentResponse: true,
		}));
	}

	public componentDidUpdate(prevProps: Readonly<IEmbedPageProps>) {
		if(prevProps.currentLanguage !== this.props.currentLanguage) {
			this.props.fetchFooter();
		}
		this.ensureInitialized();
	}

	public componentDidMount() {
		if (document.head) {

			const currentOrigin = document.location!.origin;

			// Find the shell style url / inline css
			let styleUrl: string | null = null;
			let styleLinks = Array.from(document.head.getElementsByTagName('link'));
			styleLinks = styleLinks!.filter(l => l.rel === "stylesheet" && l.href && l.href.startsWith(currentOrigin));
			if (styleLinks.length) {
				styleUrl = styleLinks[0].href.substring(currentOrigin.length);
			}
			else {
				const styles = Array.from(document.head.getElementsByTagName('style')).filter(s => !s.id);
				if (styles.length) {
					this.styleElement = styles.find(s => s.innerText.startsWith('@charset "UTF-8";')) || styles[styles.length - 1];
				}
			}

			this.setState({ styleUrl });

			window.addEventListener("message", this.receiveMessage, false);
		}

		// Set a flag to indicate that the component has mounted
		// We can't rely on styleUrl being set because in dev it might not be
		// But we need to know when it is safe to construct the URL without
		// the risk of loading the view twice: first without the style URL
		// and then with it.
		this.setState({ mounted: true })
	}

	public componentWillUnmount() {
		window.removeEventListener("message", this.receiveMessage);
	}

	public render() {
		const { embeddedUrlsLoaded, frameUrl, redirectUrl } = this.props;
		const { mounted, styleUrl } = this.state;
		const frameSrc = mounted && frameUrl ? this.getFrameSrc(frameUrl, styleUrl) : null;

		if (!embeddedUrlsLoaded || this.props.fetchingFooter || (this.state.cookiebotLoaded && !this.state.hasCookieConsentResponse)) {
			return null;
		}

		if (!frameUrl) {
			return !!redirectUrl ? null : <NotFoundPage />;
		}

		return (
			<>
				<AnonymousViewCanonicalLink />
				<Layout pageIdentifier={`embed${this.props.hasAnonymousNavigation ? "-anonymous" : ""}`} isSimple={this.props.simpleLayout} top={
					<div id={mainContentId} className="embedded-view-wrapper with-navigation">
						{this.state.iframeLoaded ? null : <Loader />}
						{frameSrc ? <iframe id="embedded-view" src={frameSrc} ref={this.iframe} onLoad={this.frameLoaded} /> : null}
					</div>
				} />
			</>
		);
	}

	private frameLoaded = () => {
		this.setState({ iframeLoaded: true });
		const embedTarget = this.parseAuthorityAndHash(this.props.frameUrl!);
		if (!embedTarget.hash) {
			return;
		}

		const currentPage = this.parseAuthorityAndHash(this.iframe.current!.src);
		// We only want to change the hash if we're _not_ e.g. redirecting to the identity provider
		if (currentPage.authority !== embedTarget.authority) {
			return;
		}

		// If the hash is already there, let's not do anything
		if (currentPage.hash === embedTarget.hash) {
			return;
		}

		const currentUrl = this.iframe.current!.src.split('#')[0];
		this.iframe.current!.src = currentUrl + embedTarget.hash;
	};

	private parseAuthorityAndHash(url: string): { authority: string, hash: string } {
		const anchor = document.createElement('a');
		anchor.href = url;
		return { authority: anchor.host, hash: anchor.hash };
	}

	private ensureInitialized() {
		const { embeddedUrlsLoaded, footer, fetchingFooter, fetchingEmbeddedUrls } = this.props;

		if (!embeddedUrlsLoaded && !fetchingEmbeddedUrls) {
			this.props.fetchEmbeddedUrls();
		}

		if (!footer && !fetchingFooter) {
			this.props.fetchFooter();
		}
	}

	private receiveMessage = (event: MessageEvent) => {
		if (!event.data) {
			return;
		}
		if (event.data === "style") {
			this.receiveStyleRequest(event);
		}
		else if (event.data === "footer") {
			this.receiveFooterRequest(event);
		}
		else if (typeof event.data === "object") {
			this.receiveComplexRequest(event.data);
		}
		else if (event.data === "accessDenied") {
			this.props.history.push(authenticatedRoutes.frontpage[this.props.currentLanguage]);
		}
		else if (event.data === "welcomeReady") {
			this.props.markWelcomeReady();
			this.props.history.push(authenticatedRoutes.frontpage[this.props.currentLanguage]);
		}
	}

	private receiveStyleRequest = (event: any) => {

		const iframe = this.iframe.current;
		if (!iframe) { return; }

		if (event.data === 'style' && iframe.contentWindow && document.location) {
			if (this.styleElement) {
				const css = this.styleElement.innerText.replace(/url\(\//g, `url(${document.location.origin}/`);
				iframe.contentWindow.postMessage({ type: 'styleRules', content: css }, event.origin);
			}
		}
	}

	private receiveComplexRequest(event: ComplexRequest) {
		if (event.msg === "open_payment") {
			this.receiveOpenPaymentPageRequest(event);
		}
		else if (event.msg === "navigated_to") {
			const language = getLanguage(this.props.currentLanguage, document.location ? document.location.pathname : '');
			const urlConfig = findUrlConfigurationByTargetUrl(this.props.urls, event.url, language)
			if (urlConfig == null) {
				return
			}

			const path = '/' + getEmbedUrlLocalizedPath(urlConfig, event.url, language);
			const fullPath = this.props.contract ? getPathWithContractNumber(this.props.contract.contractNumber, path) : path;
			const currentPath = document.location?.pathname || '';
			if (currentPath !== fullPath) {
				history.pushState(null, '', fullPath);
			}
		}
		if (event.msg === "navigate_to") {
			const language = getLanguage(this.props.currentLanguage, document.location ? document.location.pathname : '');
			const urlConfig = findUrlConfigurationByLogicalName(this.props.urls, event.app, event.urlName)
			if (urlConfig == null) {
				return
			}

			const path = '/' + urlConfig.paths[language];
			const fullPath = this.props.contract ? getPathWithContractNumber(this.props.contract.contractNumber, path) : path;
			window.location.href = fullPath;
			// TODO: this could do React navigation but there are some problems with that happening properly. changing path doesn't always trigger EmbedPage content update
			// this.props.history.push(fullPath);
			// // Ugly, but make Layout.tsx put navigation visible
			// window.postMessage("scrollTop:" + 0, "*");
		}
		else if (event.msg === "hashchange") {
			location.hash = event.hash;
		}
	}

	private receiveOpenPaymentPageRequest = (event: { paymentUrl: string }) => {
		const iframe = this.iframe.current;
		if (!iframe || !window?.top) { return; }

		window.top.location.assign(event.paymentUrl);
	}

	private receiveFooterRequest = (event: any) => {
		if (this.props.simpleLayout) { return; }

		const iframe = this.iframe.current;
		if (!iframe) { return; }

		if (event.data === 'footer' && iframe.contentWindow && document.location && this.props.footer) {
			iframe.contentWindow.postMessage({ type: 'footer', content: this.props.footer }, event.origin);
		}
	}

	/**
	 * If the URL contains a fragment identifier, it is stripped away. The shell's style sheet URL is appended.
	 * The fragment will be restored later, to ensure consistent scroll position behavior.
	 * @param originalUrl
	 * @param styleUrl
	 */
	private getFrameSrc(originalUrl: string | null, styleUrl: string | null): string {

		if (!originalUrl) {
			return "";
		}

		const withoutFragment = originalUrl.split('#')[0];

		return `${styleUrl
			? addQueryParameterToUrl(withoutFragment, "styleUrl", styleUrl)
			: withoutFragment}${location.hash ? location.hash : ''}`;
	}
}

interface IPaymentPageRequest {
	msg: "open_payment";
	paymentUrl: string
}

/**
 * Notification that page navigation event occurred inside the iframe.
 */
interface INavigatedToRequest {
	msg: "navigated_to";
	url: string;
	title: string | undefined;
}

interface INavigateToRequest {
	msg: "navigate_to";
	app: string;
	urlName: string;
}

interface IHashChangeRequest {
	msg: "hashchange";
	hash: string;
}

type ComplexRequest = IPaymentPageRequest | INavigatedToRequest | IHashChangeRequest | INavigateToRequest;

export interface IEmbedPageDispatchProps {
	fetchEmbeddedUrls: () => any;
	fetchFooter: () => any;
	markWelcomeReady: () => any;
}

const mapStateToProps = (
	{ user, contracts, embedding, dashboard }: IAuthenticatedShellState,
	ownProps: IEmbedPageProps
): IEmbedPageStateProps => {

	const contract = ownProps.useContractId ? contracts.selectedContract : null;

	const localizedPath = getLocalizedPath(ownProps);
	const language = getLanguageByPath(localizedPath);
	let urlConfig: IEmbeddedUrl | null = null;
	let redirectUrl: string | undefined;

	const embeddedUrlsLoaded = !!embedding.urls.length;
	if (embeddedUrlsLoaded) {

		urlConfig = findUrlConfigurationByLocalizedUrl(embedding.urls, localizedPath, language);

		// If no match was found, try with the other language
		// (all routes should work for both languages instead of showing 404)
		if (!urlConfig) {
			const urlConfigInOtherLanguage = findUrlConfigurationByLocalizedUrl(embedding.urls, localizedPath, language === "en" ? "fi" : "en");
			if (!!urlConfigInOtherLanguage) {
				redirectUrl = '/' + urlConfigInOtherLanguage.paths[language] + ownProps.location.search + ownProps.location.hash;
			}
		}
	}

	if (!urlConfig ||
		(ownProps.useContractId && !contract)) { // when contractId is used, load the iframe only if we already have the contractId (otherwise the embedded view might get called several times)

		return {
			user,
			contract,
			embeddedUrlsLoaded,
			fetchingEmbeddedUrls: embedding.fetchingUrls,
			frameUrl: null,
			redirectUrl,
			footer: embedding.footer,
			currentLanguage: dashboard.uiLanguage,
			fetchingFooter: embedding.fetchingFooter,
			urls: embedding.urls
		}
	}


	const frameUrl = getFrameUrl(urlConfig, localizedPath, language, contract);

	return {
		user,
		contract,
		embeddedUrlsLoaded,
		fetchingEmbeddedUrls: embedding.fetchingUrls,
		frameUrl,
		footer: embedding.footer,
		currentLanguage: dashboard.uiLanguage,
		fetchingFooter: embedding.fetchingFooter,
		urls: embedding.urls
	};
};



export default withRouter(
	connect(
		mapStateToProps,
		{
			fetchEmbeddedUrls: embedActions.fetchUrls.request,
			fetchFooter: embedActions.fetchFooter.request,
			markWelcomeReady: authActions.markWelcomeReady,
		}
	)(EmbedPage)
);

function getFrameUrl(
	urlConfig: IEmbeddedUrl,
	localizedPath: string,
	language: string,
	contract: IContractAM | null
) {
	let frameUrl = getTargetUrlByLocalizedPath(
		urlConfig,
		localizedPath,
		language
	);
	if (contract) {
		frameUrl = addQueryParameterToUrl(
			frameUrl,
			"contractId",
			contract.contractId
		);
	}
	if(!urlConfig.languageByRouting) {
		frameUrl = addQueryParameterToUrl(
			frameUrl,
			"language",
			language
		);
	}

	return frameUrl;
}

function getLocalizedPath(
	props: Pick<IEmbedPageProps, "location" | "match" | "keepMatchUrlInPath">
) {
	const fullUrl =
		props.location.pathname + props.location.search + props.location.hash;

	let localizedPath = props.keepMatchUrlInPath
		? fullUrl // In this special case, pass the current location as whole to EmbedPage
		: fullUrl.substring(props.match.url.length); // In normal case, skip the contract number part ("match" in routing) from the url that is passed to EmbedPage
	if (localizedPath.startsWith("/")) {
		localizedPath = localizedPath.substring(1);
	}
	return localizedPath;
}
