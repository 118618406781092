import { takeLatest, call, put } from "redux-saga/effects";
import { getType, ActionType } from "typesafe-actions";
import { dashboardActions } from "./dashboardActions";
import { ApiClient } from "../../ApiClient";
import { fetchGiosgTokenSaga, setGiosgVisitorDataSaga } from "./giosgSaga";
import { getApiClient } from "../../Common/getApiClient";

function* watchFetchTilesSaga(
	action: ActionType<typeof dashboardActions.loadTiles.request>
) : any {
	try {
		const api:ApiClient = yield call(getApiClient,action.payload.user);

		const tiles = yield call(
			[api, api.fetchTiles],
			action.payload.contractId
		);
		yield put(dashboardActions.loadTiles.success(tiles));
	} catch (e: any) {
		yield put(dashboardActions.loadTiles.failure(e));
	}
}

function* fetchArticlesSaga(
	action: ActionType<typeof dashboardActions.getArticlesByTag.request>
) : any {
	try {
		const api:ApiClient = yield call(getApiClient);
		const results = yield call([api, api.getArticlesByTag], action.payload);
		yield put(dashboardActions.getArticlesByTag.success(results));
	} catch (e: any) {
		yield put(dashboardActions.getArticlesByTag.failure(e));
	}
}

function* fetchWelcomePageInfoSaga(
	action: ActionType<typeof dashboardActions.loadWelcomePageInfo.request>
) : any {
	try {
		const api:ApiClient = yield call(getApiClient,action.payload.user);
		const results = yield call([api, api.getWelcomePageInfo], action.payload.contractId);
		yield put(dashboardActions.loadWelcomePageInfo.success(results));
	} catch (e: any) {
		yield put(dashboardActions.loadWelcomePageInfo.failure(e));
	}
}

export function* dashboardSaga() {
	yield takeLatest(
		getType(dashboardActions.loadTiles.request),
		watchFetchTilesSaga
	);

	yield takeLatest(
		getType(dashboardActions.loadGiosgToken.request),
		fetchGiosgTokenSaga
	);

	yield takeLatest(
		getType(dashboardActions.loadGiosgToken.success),
		setGiosgVisitorDataSaga
	);

	yield takeLatest(
		getType(dashboardActions.getArticlesByTag.request),
		fetchArticlesSaga
	);

	yield takeLatest(
		getType(dashboardActions.loadWelcomePageInfo.request),
		fetchWelcomePageInfoSaga
	);
}
