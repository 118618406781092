import React from "react";
import { SurveyDialog } from "./SurveyDialog";

import { ISiteSurveyDialogProps } from "./SiteSurveyDialog";
import {useResource, ResourceText} from "@kojamo/react-utils";
const DirectRentalSurveyDialog: React.FC<ISiteSurveyDialogProps> = ({ onCloseRequested, onOpenSurveyRequested }) => {
	const { getResourceText } = useResource();
	return (
		<SurveyDialog
			onCloseRequested={onCloseRequested}
			onOpenSurveyRequested={onOpenSurveyRequested}
			title={<ResourceText textType="MarkdownInline" resourceKey="DirectRental_SiteSurveyDialog_Title" />}
			infoText={<ResourceText textType="MarkdownInline" resourceKey="DirectRental_SiteSurveyDialog_InfoText" />}
			surveyUrl={getResourceText("DirectRental_SiteSurveyDialog_Accept_Url")}
			acceptLinkText={<ResourceText textType="MarkdownInline" resourceKey="DirectRental_SiteSurveyDialog_Accept_Link" />}
			declineLinkText={<ResourceText textType="MarkdownInline" resourceKey="DirectRental_SiteSurveyDialog_Decline_Link" />}
		/>
	);
};

export default DirectRentalSurveyDialog;
