import { createStore, compose, applyMiddleware, Middleware, AnyAction, Dispatch } from 'redux';
import { rootReducer, IShellState } from './rootReducer';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './rootSaga';
import { reportError, setupErrorReporting, setCurrentTelemetryUserId, trackPageView } from './Telemetry';
import { settingsActions } from './Settings/settingsActions';
import { getType, ActionCreator, ActionCreatorTypeMetadata } from 'typesafe-actions';
import { authActions } from './Authentication/authenticationActions';
import { appActions } from "./appActions";
import { ApiMaintenanceError } from "./ApiMaintenanceError";
import { CustomHeaders } from "./CustomHeaders";
import { dashboardActions } from './Pages/Dashboard/dashboardActions';
import { persistUserLanguage } from "./Common/Localization/persistLanguage";
import { updateLanguageCookie } from "./Common/Localization/languageCookie";

const global = window as any;

const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function actionIsType<T extends ActionCreator<any> & ActionCreatorTypeMetadata<any>>(type: T, action: any): action is ReturnType<T> {
	return action.type === getType(type);
}

const telemetryMiddleware: Middleware<Record<string, never>, IShellState, Dispatch<AnyAction>> = () => next => action => {
	if (actionIsType(settingsActions.fetchSettings.success, action)) {
		const { appInsightsInstrumentationKey } = action.payload;

		setupErrorReporting({
			appInsightsInstrumentationKey
		});
	}

	if (actionIsType(authActions.login.success, action) || actionIsType(authActions.refresh.success, action)) {
		try {
			setCurrentTelemetryUserId(action.payload.user.profile.sub);

			// We should only end up here once per full page load, so this is a good place to
			// track page views
			trackPageView();
		} catch {
			// In case we can't parse the user ID, let's not crash here
		}
	}

	if (action.payload && action.payload instanceof Error) {
		reportError(action.payload);
	}

	try {
		return next(action);
	} catch (e: any) {
		reportError(e);

		throw e;
	}
}

const languageSwitcherMiddleware: Middleware<Record<string, never>, IShellState, Dispatch<AnyAction>> = ({ getState }) => (next) => async (action) => {
	const { user, settings: { languageCookieDomain } } = getState();

	const switchLanguage = actionIsType(dashboardActions.switchLanguage, action);
	const switchLanguageOnLogin = actionIsType(authActions.switchLanguageOnLogin, action);

	if (switchLanguage || switchLanguageOnLogin) {
		const { payload } = action;
		updateLanguageCookie(payload, languageCookieDomain || window.location.hostname);
		switchLanguage && await persistUserLanguage(payload, user);
	}
	return next(action);
};


const globalErrorMiddleware: Middleware<Record<string, never>, IShellState, Dispatch<AnyAction>> = () => next => async action => {
	if (action.payload && action.payload instanceof ApiMaintenanceError) {
		// Maintenance page has been turned on, refresh page
		// fetch root page content and set overwrite current DOM, to avoid possible Web Worker caching
		const response = await fetch(window.location.origin);
		// check again, just to be sure
		if(response.headers.has(CustomHeaders.MaintenancePage)){
			const responseBody = await response.text();
			document.open('text/html');
			document.write(responseBody);
			document.close();
		} else {
			// Maintenance page was turned off, carry on as usual
			return next(action);
		}
	} else if (actionIsType(appActions.authenticationTimeout, action)) {
		reportError(new Error(`Request timed out while handling authentication action '${action.payload}' at '${location.href}'`));
		location.href = "/Home/Error";
	} else if(actionIsType(authActions.error, action)){
		reportError(new Error(`Authentication API request failed '${action.payload.message}' at '${location.href}'`));
		location.href = "/Home/Error";
	} else {
		return next(action);
	}
}
export function createStoreWithState(state?: Partial<IShellState>) {
	const sagaMiddleware = createSagaMiddleware();
	const store = createStore(rootReducer, state, composeEnhancers(applyMiddleware(telemetryMiddleware, languageSwitcherMiddleware, sagaMiddleware, globalErrorMiddleware)));
	sagaMiddleware.run(rootSaga);
	return store;
}
