import {
	HostConfig,
	ContainerStyleSet,
	ContainerStyleDefinition,
	TextColorDefinition,
	ShowCardActionConfig,
	Size,
	FactTitleDefinition,
	FactSetConfig,
	TextWeight,
	TextColor,
	TextSize,
	FactTextDefinition,
	ImageSetConfig,
	HorizontalAlignment,
	Orientation,
	ShowCardActionMode,
	Spacing,
	ActionsConfig
} from "adaptivecards";

export interface ITileConfiguration {
	id: string;
	app: string;
	group: string;
	cardJson: string;
	backgroundColor: string;
	contentClass: string;
}

export const adaptiveCardHostConfig = new HostConfig({
	containerStyles: new ContainerStyleSet({
		default: new ContainerStyleDefinition({
			backgroundColor: "#F05183",
			foregroundColors: {
				dark: new TextColorDefinition(
					"black",
					"gray"
				),
				light: new TextColorDefinition(
					"white",
					"lightgray"
				),
				default: new TextColorDefinition(
					"#FF333333",
					"#EE333333"
				),
				accent: new TextColorDefinition(
					"#FF2E89FC",
					"#882E89FC"
				),
				attention: new TextColorDefinition(
					"#FFcc3300",
					"#DDcc3300"
				),
				good: new TextColorDefinition(
					"#FF54a254",
					"#DD54a254"
				),
				warning: new TextColorDefinition(
					"#FFe69500",
					"#DDe69500"
				)
			}
		}),
		emphasis: new ContainerStyleDefinition({
			BackgroundColor: "yellow",
			foregroundColor: {
				dark: new TextColorDefinition(
					"black",
					"gray"
				),
				light: new TextColorDefinition(
					"white",
					"lightgray"
				),
				default: new TextColorDefinition(
					"#FF333333",
					"#EE333333"
				),
				accent: new TextColorDefinition(
					"#FF2E89FC",
					"#882E89FC"
				),
				attention: new TextColorDefinition(
					"#FFcc3300",
					"#DDcc3300"
				),
				good: new TextColorDefinition(
					"#FF54a254",
					"#DD54a254"
				),
				warning: new TextColorDefinition(
					"#FFe69500",
					"#DDe69500"
				)
			}
		})
	}),
	fontFamily: "Graphik-Medium",
	fontSizes: {
		small: 13,
		default: 14,
		medium: 22,
		large: 48,
		extraLarge: 88
	},
	fontWeights: {
		lighter: 200,
		default: 400,
		bolder: 600
	},
	supportsInteractivity: true,
	actions: new ActionsConfig({
		maxActions: 3,
		spacing: Spacing.Default,
		buttonSpacing: 10,
		showCard: new ShowCardActionConfig({
			actionMode: ShowCardActionMode.Inline,
			inlineTopMargin: 15
		}),
		actionAlignment: HorizontalAlignment.Left,
		actionsOrientation: Orientation.Vertical
	}),
	spacing: {
		small: 4,
		default: 12,
		medium: 16,
		large: 30,
		extraLarge: 40,
		padding: 20
	},
	separator: {
		lineThickness: 1,
		lineColor: "#EEEEEE"
	},
	ImageSet: new ImageSetConfig({
		imageSize: Size.Medium
		// Here should also exist MaxImageHeight (https://docs.microsoft.com/en-us/adaptive-cards/rendering-cards/host-config)
	}),
	imageSizes: {
		small: 40,
		medium: 80,
		large: 160
	},
	factSet: new FactSetConfig({
		title: new FactTitleDefinition({
			color: TextColor.Default,
			size: TextSize.Default,
			isSubtle: false,
			weight: TextWeight.Bolder,
			wrap: true,
			maxWidth: 150
		}),
		value: new FactTextDefinition({
			Color: TextColor.Default,
			Size: TextSize.Default,
			IsSubtle: false,
			Weight: TextWeight.Default,
			Wrap: true,
			MaxWidth: 150
		}),
		Spacing: 10
	})
});
