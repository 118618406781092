import React from "react";

interface ISurveyDialogProps {
	onCloseRequested: () => void;
	onOpenSurveyRequested: () => void;
	title: React.ReactElement<any>,
	infoText: React.ReactElement<any>,
	surveyUrl: string,
	acceptLinkText: React.ReactElement<any>,
	declineLinkText: React.ReactElement<any>
}

export class SurveyDialog extends React.Component<ISurveyDialogProps> {
	constructor(props: ISurveyDialogProps) {
		super(props);
	}

	public render() {
		const anchors = document.getElementsByClassName("header-extension");
		let overlayStyle = {};

		const overlayTop = Math.max(...Array.from(anchors).map(c => c.getBoundingClientRect().bottom));
		overlayStyle = {paddingTop: overlayTop + 'px'};

		return <section className="overlay" style={overlayStyle} onClick={this.onlyCloseOverlay}>
			<div className="card overlay__card -centeredContent" onClick={this.suppressClicks}>
				<a className="close-button overlay__closeButton" onClick={this.onlyCloseOverlay}>
					{" "}
					<span className="lines"/>
				</a>
				<h3 className="card__title">
					{this.props.title}
				</h3>
				<p className="card__text">
					{this.props.infoText}
				</p>
				<div className="card__ctas">
					<a className="button button--secondary" onClick={this.onlyCloseOverlay}>
						{this.props.declineLinkText}
					</a>
					<a className="button" href={this.props.surveyUrl} target="_blank"
					   onClick={this.onOpenSurveyClicked}>
						{this.props.acceptLinkText}
					</a>
				</div>
			</div>
		</section>;
	}

	private suppressClicks = (e: React.MouseEvent) => {
		e.stopPropagation();
	}

	private onlyCloseOverlay = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		this.closeOverlay();
	}

	private closeOverlay = () => this.props.onCloseRequested()

	private onOpenSurveyClicked = (e: React.MouseEvent) => {
		e.stopPropagation();
		this.openSurvey();
	}

	private openSurvey = () => this.props.onOpenSurveyRequested()
}
