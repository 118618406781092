import { UILanguage } from 'src/rootReducer';
import { getTranslatedUrlForRoute } from "./getTranslatedUrlForRoute";


const parkingSpaceRoutes = {
	parkingSpaces: {fi: '/autopaikat', en: '/en/parkingspaces'}
};

const shellRoutes = {
	root: { fi: '/', en: '/en' },
	announcement: { fi: '/tiedote/:announcementId', en: '/en/announcement/:announcementId' },
}

const contactRoutes = {
	contact: {fi: '/yhteystiedot', en: '/en/contact-information'},
	serviceRequest: { fi: '/vikailmoitus/kirjautumaton', en: '/en/service-request/anonymous' },
	maintenanceInformation: { fi: '/yhteystiedot/loyda-huoltopaivystys', en: '/en/contact-information/maintenance' },
}
const customerRoutes = {
	contactPreferences: {fi: '/viestintaasetukset/:customerId', en: '/en/communication-preferences/:customerId'},
}

export const anonymousRoutes = {
	...shellRoutes,
	...contactRoutes,
	...customerRoutes,
	...parkingSpaceRoutes,

};

export function translateAnonymousUrl(isLoggedIn: boolean, url: string, targetLanguage: UILanguage): string | undefined {
	return getTranslatedUrlForRoute(anonymousRoutes, url, targetLanguage, isLoggedIn);
}
