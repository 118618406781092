import { takeLatest, call, put } from "redux-saga/effects";
import { getType, ActionType } from "typesafe-actions";
import { contractContextActions } from "./contractContextActions";
import { ApiClient } from "../../ApiClient";
import { feedActions } from 'src/Components/Feed/feedActions';
import { getApiClient } from "../../Common/getApiClient";

function* fetchContractsSaga(
	action: ActionType<typeof contractContextActions.loadContracts.request>
) : any {
	try {
		const api:ApiClient = yield call(getApiClient,action.payload.user);

		const contracts = yield call([api, api.fetchContracts]);
		yield put(contractContextActions.loadContracts.success({ contracts, defaultContractNumber: action.payload.defaultContractNumber }));
	} catch (e: any) {
		yield put(contractContextActions.loadContracts.failure(e));
	}
}

function* changeSelectedContractSaga() : any {
	yield put(feedActions.clearFeedMessageCache());
}

function* watchLogContractChangeSaga(action: ActionType<typeof contractContextActions.logContractChange.request>) : any {
	const api:ApiClient = yield call(getApiClient,action.payload.user);
	try {
		api.logContractSelection(action.payload.contractId);
		yield put(contractContextActions.logContractChange.success());
	} catch (e: any) {
		yield put(contractContextActions.logContractChange.failure(e));
	}
}

export function* contractContextSaga() {
	yield takeLatest(getType(contractContextActions.loadContracts.request), fetchContractsSaga);
	yield takeLatest(getType(contractContextActions.changeSelectedContract), changeSelectedContractSaga)
	yield takeLatest(getType(contractContextActions.logContractChange.request), watchLogContractChangeSaga);
}
