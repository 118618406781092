import {SpacingContainer} from "@kojamo/lumo-dls";
import {TopNavigationDesktop} from "./TopNavigationDesktop";
import {TopNavigationMobile} from "./TopNavigationMobile";

export function TopNavigation() {
	return (
		<SpacingContainer direction="column" className="l-background-brand-background l-padding-1-b">
			<TopNavigationDesktop />
			<TopNavigationMobile />
		</SpacingContainer>
	);
}

