import { IContractAM } from './ApiClient';
import moment from 'moment';

export function isContractActive(contract: IContractAM) {
	const endOfLastPeriod = contract.involvementPeriods[contract.involvementPeriods.length - 1].end;
	return !endOfLastPeriod || endOfLastPeriod > moment().toDate();
}

export function isContractScheduledToEnd(contract: IContractAM){
	return contract.isContractTerminationScheduled
}

export function addQueryParameterToUrl(url: string, parameterName: string, parameterValue: string): string {

	if (!url) { return url; }

	const indexOfHash = url.indexOf("#");
	const hash = url.substring(indexOfHash);
	let urlWithoutHash = indexOfHash > 0 ? url.substring(0, indexOfHash) : url;
	urlWithoutHash +=
		(urlWithoutHash.includes("?") ? "&" : "?") +
		parameterName + "=" + parameterValue;

	return indexOfHash > 0
		? urlWithoutHash + hash
		: urlWithoutHash;
}
