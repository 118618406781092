import {useResource, ResourceText} from "@kojamo/react-utils";
import {addTelemetryEventGA4, GA4EventAction, GA4EventCategory, GA4EventType} from "../../telemetryGA4";
const LinkList = () => {
	const {getResourceText} = useResource();

	const onOtherResidenceContentLinkClicked = (ga4Action: GA4EventAction) => {
		addTelemetryEventGA4({
			event: GA4EventType.ui_clicks,
			category: GA4EventCategory.other_residence_contact,
			action: ga4Action
		});
	};

	return (
		<section className="linklist">
			<div className="content-wrapper">
				<h2>
					<ResourceText resourceKey="Dashboard_OtherResidenceContent_Heading_Main"/>
				</h2>
				<ul className="linklist-list">
					<li className="linklist-listitem">
						<a href={getResourceText("Dashboard_OtherResidenceContent_Url_Item1")}
						   onClick={() => onOtherResidenceContentLinkClicked(GA4EventAction.lumotoolbox)}>
								<span className="subtitle">
									<ResourceText resourceKey="Dashboard_OtherResidenceContent_Heading_Item1"/>
								</span>
							<span className="link-txt">
									<ResourceText resourceKey="Dashboard_OtherResidenceContent_Link_Item1"/>
									<svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
										<circle cx="14" cy="14" r="14" fill="#f05183"/>
										<path
											d="M11.3 18.6a.6.6 0 0 1-.3-1.2l6.4-3.4-6.4-3.4a.6.6 0 0 1 .6-1.1l7.5 4a.6.6 0 0 1 .2.8.6.6 0 0 1-.2.2l-7.5 4a.6.6 0 0 1-.3.1z"
											fill="#fff1e7"/>
									</svg>
								</span>
						</a>
					</li>
					<li className="linklist-listitem">
						<a href={getResourceText("Dashboard_OtherResidenceContent_Url_Item2")}
						   onClick={() => onOtherResidenceContentLinkClicked(GA4EventAction.homeguide)}>
								<span className="subtitle">
									<ResourceText resourceKey="Dashboard_OtherResidenceContent_Heading_Item2"/>
								</span>
							<span className="link-txt">
									<ResourceText resourceKey="Dashboard_OtherResidenceContent_Link_Item2"/>
									<svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
										<circle cx="14" cy="14" r="14" fill="#f05183"/>
										<path
											d="M11.3 18.6a.6.6 0 0 1-.3-1.2l6.4-3.4-6.4-3.4a.6.6 0 0 1 .6-1.1l7.5 4a.6.6 0 0 1 .2.8.6.6 0 0 1-.2.2l-7.5 4a.6.6 0 0 1-.3.1z"
											fill="#fff1e7"/>
									</svg>
								</span>
						</a>
					</li>
					<li className="linklist-listitem">
						<a href={getResourceText("Dashboard_OtherResidenceContent_Url_Item3")}
						   onClick={() => onOtherResidenceContentLinkClicked(GA4EventAction.lumoteam)}>
								<span className="subtitle">
									<ResourceText resourceKey="Dashboard_OtherResidenceContent_Heading_Item3"/>
								</span>
							<span className="link-txt">
									<ResourceText resourceKey="Dashboard_OtherResidenceContent_Link_Item3"/>
									<svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
										<circle cx="14" cy="14" r="14" fill="#f05183"/>
										<path
											d="M11.3 18.6a.6.6 0 0 1-.3-1.2l6.4-3.4-6.4-3.4a.6.6 0 0 1 .6-1.1l7.5 4a.6.6 0 0 1 .2.8.6.6 0 0 1-.2.2l-7.5 4a.6.6 0 0 1-.3.1z"
											fill="#fff1e7"/>
									</svg>
								</span>
						</a>
					</li>
				</ul>
			</div>
		</section>
	);
};

export default LinkList;
