import {UILanguage} from "../../rootReducer";
import {ReactNode} from "react";
import {useAppLanguage} from "../../Common/Localization/useAppLanguage";
import {matchTranslatedRoute} from "../../Routing/authenticatedRoutes";
import {Link} from "react-router-dom";
import {navigationLinkButtonClasses} from "./NavigationShared";
import {useLocation} from "react-router";

export function LinkWithSelectedState({to, children, onClick, small, className}: {
	to: Record<UILanguage, string>,
	children: ReactNode,
	onClick?: () => void,
	small?: boolean,
    className?: string
}) {
	const language = useAppLanguage();
	const { pathname } = useLocation();
	const match = matchTranslatedRoute(pathname, to, language);
	return (
		<Link to={to[language]} className={`${className ? className : navigationLinkButtonClasses} ${Boolean(match) ? "is-selected" : ""} ${small ? "is-small" : ""}`}
			  onClick={onClick}>
			{children}
		</Link>
	);
}

export function useMatchesOneOfRoutes(routes: Record<UILanguage, string>[]) {
	const language = useAppLanguage();
	const { pathname } = useLocation();
	return routes.some(route => matchTranslatedRoute(pathname, route, language));
}
