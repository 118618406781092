import { useContract } from "src/Routing/ContractContext/useContract";
import { IconChevronDown } from "../SVG/IconChevronDown";
import {IContractAM} from "../../ApiClient";

export default function ContractSelector({ className, navigation }: { className: string, navigation?: true }) {
	const { contracts, selectedContract, selectedContractChanged } = useContract();

	if (contracts.length === 1) {
		return <div className={`${className} single-contract`}>{formatContract(contracts[0])}</div>
	}

	if (contracts.length > 1 && selectedContract) {
		return (
			<div className={`${className} ${navigation ? 'with-icon with-icon__right' : ''} input`}>
				<div className="select">
					<select
						id="contract"
						value={selectedContract.contractNumber}
						onChange={selectedContractChanged}
					>
						{contracts.map(c => (
							<option key={c.contractId} value={c.contractNumber}>
								{formatContract(c)}
							</option>
						))}
					</select>
					{navigation && <IconChevronDown className="icon-right" />}
				</div>
			</div>
		);
	}

	return null;
}

export function formatContract(contract: IContractAM) {
	return `${contract.address.street}, ${contract.address.city}`;
}
