import * as React from 'react';
import { AuthenticationClient } from '../Authentication/authenticationClient';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { authActions, ILogAndCompleteLogin } from 'src/Authentication/authenticationActions';
import { IShellState, IUserContext } from 'src/rootReducer';
import { Link } from 'react-router-dom';
import { reportError } from 'src/Telemetry';
import { ResourceText } from "@kojamo/react-utils";

export interface IOidcCallbackProps {
	silent?: boolean;
}

export interface IOidcCallbackStateProps {
	authority?: string;
	refreshingUser: boolean;
	user: IUserContext | null;
}

export type OidcCallbackProps = IOidcCallbackProps & IOidcCallbackStateProps & IOidcCallbackDispatchProps & RouteComponentProps<any>;

export interface IOidcCallbackState {
	authenticationStarted: boolean;
	authenticationFailed: boolean;
}

class OidcCallback extends React.Component<OidcCallbackProps, IOidcCallbackState> {
	constructor(props: OidcCallbackProps) {
		super(props);
		this.state = {
			authenticationStarted: false,
			authenticationFailed: false
		};
	}

	public async componentDidMount() {
		this.refreshAuthenticationState();
	}

	public async componentDidUpdate() {
		this.refreshAuthenticationState();
	}

	public render() {
		const { user, refreshingUser } = this.props;
		const { authenticationFailed } = this.state;

		if (authenticationFailed && user) {
			return (
				<div className="loading-message">
					<p>
						<ResourceText textType="MarkdownInline" resourceKey="OidcCallback_Heading_LoginAlreadyProcessed"/>
					</p>
					<p>
						<Link to="/etusivu"><ResourceText textType="MarkdownInline" resourceKey="OidcCallback_Link_GoToFrontPage"/></Link>
					</p>
				</div>
			);
		}

		if (authenticationFailed && !refreshingUser) {
			return (
				<div className="loading-message">
					<p>
						<ResourceText textType="MarkdownInline" resourceKey="OidcCallback_Heading_LoginFailed"/>
					</p>
					<p>
						<Link to="/etusivu"><ResourceText textType="MarkdownInline" resourceKey="OidcCallback_Link_GoToFrontPage"/></Link>
					</p>
				</div>
			)
		}

		return (
			<div className="loading-message"><ResourceText textType="MarkdownInline" resourceKey="OidcCallback_Heading_ProcessingLogin"/></div>
		);
	}

	private async refreshAuthenticationState() {
		const { authority, silent, history, logAndCompleteLogin } = this.props;
		if (!authority || this.state.authenticationStarted) {
			return;
		}

		this.setState({ authenticationStarted: true });

		const authenticationClient = new AuthenticationClient({ authority });

		if (silent) {
			await authenticationClient.receiveSilent();
			return;
		}

		try {
			const user = await authenticationClient.receiveInteractive();

			// todo: unauthenticated page, that receives query parameters
			// todo: get source and contractId from cookie, and pass then to logAndCompleteLogin action on the next line
			history.push(authenticationClient.getPostLoginRedirectUrl());
			logAndCompleteLogin({ user });
		} catch (e: any) {
			reportError(e);
			this.setState({ authenticationFailed: true });
		}
	}
}

export interface IOidcCallbackDispatchProps {
	logAndCompleteLogin: (input: ILogAndCompleteLogin) => void;
}

const mapStateToProps = (state: IShellState): IOidcCallbackStateProps => ({
	authority: state.settings.ssoAuthority,
	user: state.user,
	refreshingUser: state.refreshingUser
})

const ConnectedOidcCallback = connect(mapStateToProps, {
	logAndCompleteLogin: authActions.logAndCompleteLogin
})(OidcCallback);

export default withRouter(ConnectedOidcCallback);
