import {FC} from "react";
import { useSelector } from "react-redux";

import {IAuthenticatedShellState} from "../../rootReducer";
import {ContractCancellation} from "./ContractCancellation";



const CancellationInfo: FC = () => {
	const welcomePage = useSelector((state: IAuthenticatedShellState) => state.dashboard.welcomePage);
	const contract = useSelector((state: IAuthenticatedShellState) => state.contracts.selectedContract);

	if (!welcomePage || !contract || (!contract.isCancellable && !contract.isCancelled)) {
		return null;
	}

	return <ContractCancellation isCancelled={contract.isCancelled} />;
};

export default CancellationInfo;
