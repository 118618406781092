import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IUserContext, IAuthenticatedShellState } from 'src/rootReducer';
import { connect } from 'react-redux';
import { dashboardActions } from 'src/Pages/Dashboard/dashboardActions';

type SiteSurveyProps = RouteComponentProps<any> & ISiteSurveyStateProps & ISiteSurveyDispatchProps & { currentPath: string | undefined };

const numberOfPageChangesBeforeSurvey = 5;

class SiteSurvey extends React.Component<SiteSurveyProps> {

	constructor(props: SiteSurveyProps, context?: any) {
		super(props, context);

	}

	public componentDidMount() {
		this.onNavigating();
	}

	public componentDidUpdate(prevProps: SiteSurveyProps) {
		if (prevProps.currentPath !== this.props.currentPath) {
			this.onNavigating();
		}
	}

	public render() {
		return null;
	}

	private onNavigating = () => {
		if (this.props.user.shouldShowSiteSurvey) {
			const pageChanges = increaseNavigationCounter();
			if (pageChanges >= numberOfPageChangesBeforeSurvey) {
				this.props.setSiteSurveyModalVisibility(true);
			}
		}
	}
}

function increaseNavigationCounter() {
	const count = getNavigationCount() + 1;
	window.sessionStorage.setItem('navigationCount', count.toString());
	return count;
}

function getNavigationCount() {
	const storedValue = window.sessionStorage.getItem('navigationCount')
	if (storedValue && storedValue !== '') {
		return parseInt(storedValue, undefined);
	}
	return 0;
}

interface ISiteSurveyStateProps {
	user: IUserContext;
}

interface ISiteSurveyDispatchProps {
	setSiteSurveyModalVisibility: (visible: boolean) => any;
}


const mapStateToProps = ({
	user,
}: IAuthenticatedShellState): ISiteSurveyStateProps => ({
	user,
});

export default withRouter(connect(
	mapStateToProps,
	{
		setSiteSurveyModalVisibility: dashboardActions.setSiteSurveyModalVisibility,
	}
)(SiteSurvey));
