import { createAsyncAction, ActionType } from 'typesafe-actions';

export const embedActions = {
	fetchUrls: createAsyncAction(
		'embed/LOAD_URLS_REQUEST',
		'embed/LOAD_URLS_SUCCESS',
		'embed/LOAD_URLS_FAILED'
	)<void, IEmbeddedUrl[], Error>(),

	fetchFooter: createAsyncAction(
		'embed/LOAD_FOOTER_REQUEST',
		'embed/LOAD_FOOTER_SUCCESS',
		'embed/LOAD_FOOTER_FAILED'
	)<void, string, Error>(),
};

export type EmbedActionTypes = ActionType<typeof embedActions>;

export interface IEmbeddedUrl {
	app: string;
	name: string;
	paths: Record<string, string>;
	targetUrl: string;
	languageByRouting?: boolean;
}
