import {useAppLanguage} from "../../Common/Localization/useAppLanguage";
import {Button, IconBell, IconUIChevronDown, Menu, SpacingContainer} from "@kojamo/lumo-dls";
import {
	contractMenuRoutes,
	linkClicked,
	MyLumoLogo,
	NavigationContractMenuLinks,
	navigationLinkButtonClasses,
	SwitchLanguageButton,
	TopNavigationContractQuickLink,
	topNavigationLinkColorClasses,
	TopNavigationLinkGroup,
	TopNavigationProfileMenu,
	TopNavigationReservationsMenu
} from "./NavigationShared";
import {Link} from "react-router-dom";
import {authenticatedRoutes} from "../../Routing/authenticatedRoutes";
import {ResourceText, useResource} from "@kojamo/react-utils";
import {NavigationActionType, useNavigationContext} from "./NavigationProvider";
import {GA4EventAction} from "../../telemetryGA4";
import {mainContentId} from "./mainContentId";
import {LinkWithSelectedState, useMatchesOneOfRoutes} from "./LinkWithSelectedState";

export function TopNavigationDesktop() {
	return (
		<div className="l-visible-desktop-only">
			<TopNavigationDesktopUpperRow/>
			<TopNavigationDesktopLowerRow/>
		</div>
	);
}

function TopNavigationDesktopUpperRow() {
	const {getResourceText} = useResource();
	return (
		<div className="l-background-brand-background-lighter">
			<SpacingContainer justify="center">
				<a className={`${navigationLinkButtonClasses} l-visible-screen-reader-only`} href={`#${mainContentId}`}>
					<ResourceText textType="Plain" resourceKey="Layout_MainNav_Link_ContentAccessibility" />
				</a>
			</SpacingContainer>
			<SpacingContainer justify="edges" className="l-fill-parent-width l-layout-max-width l-padding-2" aria-label={getResourceText("Layout_MainNav_Aria_MainNavigation")}>
				<TopNavigationLinkGroup>
					<SwitchLanguageButton/>
					<TopNavigationContractQuickLink />
				</TopNavigationLinkGroup>
				<TopNavigationLinkGroup>
					<TopNavigationReservationsMenu/>
					<LinkWithSelectedState to={authenticatedRoutes.announcementsArchive}>
						<IconBell className={topNavigationLinkColorClasses}/>
						<ResourceText textType="Plain" resourceKey="Layout_MainNav_Link_Announcements"/>
					</LinkWithSelectedState>
					<TopNavigationProfileMenu />
				</TopNavigationLinkGroup>
			</SpacingContainer>
		</div>
	);
}

function TopNavigationDesktopLowerRow() {
	const uiLanguage = useAppLanguage();
	const { contractMenuOpen, dispatch } = useNavigationContext();
	const {getResourceText} = useResource();
	const matchesContractSubRoute = useMatchesOneOfRoutes(contractMenuRoutes);
	return (
		<div className="l-background-brand-background-light">
			<SpacingContainer justify="start" className="l-fill-parent-width l-layout-max-width l-padding-3"  aria-label={getResourceText("Layout_MainNav_Aria_NavigationSections")}>
				<TopNavigationLinkGroup>
					<Link to={authenticatedRoutes.frontpage[uiLanguage]} className={navigationLinkButtonClasses}>
						<MyLumoLogo/>
					</Link>
					<Menu
						trigger={
							<Button
								variant="text"
								className={topNavigationLinkColorClasses}
								onClick={(e) => {
									e.stopPropagation();
									dispatch({type: contractMenuOpen ? NavigationActionType.CloseMenus : NavigationActionType.OpenContractMenu});
								}}
								isSelected={matchesContractSubRoute}
							>
								<ResourceText textType="Plain" resourceKey="Dashboard_QuickLinks_Contract"/>
								<IconUIChevronDown className={topNavigationLinkColorClasses}/>
							</Button>
						}
						open={contractMenuOpen}
						className="l-background-brand-background-lighter l-margin-3-t l-stack-9"
					>
						<NavigationContractMenuLinks />
					</Menu>
					<LinkWithSelectedState to={authenticatedRoutes.house}>
						<ResourceText textType="Plain" resourceKey="Dashboard_QuickLinks_HouseAndFacilities"/>
					</LinkWithSelectedState>
					<LinkWithSelectedState to={authenticatedRoutes.contact} onClick={() => linkClicked(GA4EventAction.contactus)}>
						<ResourceText textType="Plain" resourceKey="Layout_MainNav_Link_Contact"/>
					</LinkWithSelectedState>
					<LinkWithSelectedState to={authenticatedRoutes.services} onClick={() => linkClicked(GA4EventAction.services)}>
						<ResourceText textType="Plain" resourceKey="Layout_MainNav_Link_Services"/>
					</LinkWithSelectedState>
				</TopNavigationLinkGroup>
			</SpacingContainer>
		</div>
	);
}

