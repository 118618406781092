import * as React from 'react';
import { Route, RouteProps } from 'react-router';
import { connect } from 'react-redux';
import { UILanguage, IShellState } from '../rootReducer';
import { Spinner } from 'src/Components/Spinner';

export interface ILanguageSpecificRouteParamProps {
	language: UILanguage;
}
export interface ILanguageSpecificRouteStateProps {
	currentLanguage: UILanguage;
	isAuthenticated: boolean;
	isRefreshingAuthentication: boolean;
}

type LanguageSpecificRouteProps = ILanguageSpecificRouteParamProps &
	ILanguageSpecificRouteStateProps & RouteProps;

class LanguageSpecificRoute extends React.Component<LanguageSpecificRouteProps> {

	constructor(props: LanguageSpecificRouteProps) {
		super(props);
	}

	public render() {
		const {
			language,
			component: Component,
			children,
			...routeProps
		} = this.props;

		if (!this.props.isAuthenticated) {

			if (this.props.isRefreshingAuthentication) {
				return (
					<Spinner />
				);
			}
		}

		return (
			<Route {...routeProps} render={this.renderRoute} />
		);
	}

	private renderRoute = () => {
		return <Route {...this.getRouteProps()} />
	}

	private getRouteProps(): any {
		const allProps = this.props;
		const { language, ...result } = allProps;
		return result;
	}

}

const mapStateToProps = ({ user, refreshingUser, dashboard }: IShellState): ILanguageSpecificRouteStateProps => ({
	isAuthenticated: !!user,
	isRefreshingAuthentication: refreshingUser,
	currentLanguage: dashboard.uiLanguage,
});

export default connect(mapStateToProps,
	{}
)(LanguageSpecificRoute);

