import * as React from 'react';
import Layout from 'src/Layout';
import {IAuthenticatedShellState, IUserContext, UILanguage} from 'src/rootReducer';
import { IContractAM } from 'src/ApiClient';
import { IFeedMessageAM, feedActions, ILoadFeedMessagesRequest, ILogFeedMessageFollowRequest } from 'src/Components/Feed/feedActions';
import { orderBy } from 'lodash';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import InternalLink from 'src/Components/InternalLink';
import { MomentCalendarStrings } from 'src/Components/Feed/FeedView';
import { addQueryParameterToUrl } from 'src/Tooling';
import { embedActions } from '../Embed/embedActions';
import { ResourceText } from "@kojamo/react-utils";
import {authenticatedRoutes} from "../../Routing/authenticatedRoutes";


export interface IFeedHistoryStateProps {
	user: IUserContext;
	contract: IContractAM;
	isLoading: boolean;
	messages: IFeedMessageAM[];
	shouldLoadUrls: boolean;
	uiLanguage: UILanguage;
}

export interface IFeedHistoryDispatchProps {
	loadMessages: (request: ILoadFeedMessagesRequest) => any;
	logMessageFollow: (request: ILogFeedMessageFollowRequest) => any;
	fetchEmbeddedUrls: (user: IUserContext) => any;
}

export type IFeedHistoryProps = IFeedHistoryStateProps & IFeedHistoryDispatchProps;


class FeedHistory extends React.Component<IFeedHistoryProps> {

	constructor(props: IFeedHistoryProps) {
		super(props);

		if (!this.props.isLoading) {
			this.props.loadMessages({
				user: this.props.user,
				contractId: this.props.contract.contractId
			});
		}

		if (this.props.shouldLoadUrls) {
			this.props.fetchEmbeddedUrls(this.props.user);
		}
	}

	public render() {
		return (
			<Layout pageIdentifier="feed-history" showFooter={true} className="fullwidth">
				<div>
				<section className="content-block feed-title feed">
					<h1><ResourceText textType="MarkdownInline" resourceKey="FeedHistory_Heading_Main"/></h1>
					<InternalLink to={authenticatedRoutes.frontpage[this.props.uiLanguage]} className="back-arrow">
						<svg width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
							<path fill="#F05183" d="M12 5c0 .4-.3.7-.7.7h-9L5 8.5c.3.3.3.7 0 1l-.4.2-.5-.2-4-4-.1-.2v-.6l.1-.2 4-4c.3-.2.7-.2 1 0 .2.3.2.7 0 1l-3 2.8h9.1c.4 0 .7.3.7.7z" />
						</svg>
						<ResourceText textType="MarkdownInline" resourceKey="FeedHistory_Link_ToFrontPage"/>
					</InternalLink>
				</section>
				<section className="content-block is-card feed">
					<div className="card">
						{this.props.messages.map(m =>
							<HistoryFeedMessage
								key={`${m.id}${this.props.uiLanguage}`}
								message={m}
								language={this.props.uiLanguage}
								onClick={() => this.linkClicked(m)}
							/>
						)}
					</div>
				</section>
				</div>
			</Layout>
		);
	}

	private linkClicked(message: IFeedMessageAM): void {
		this.props.logMessageFollow({
			user: this.props.user,
			messageId: message.id,
			followType: "MyLumoUI/FeedArchive"
		});
	};
}

function HistoryFeedMessage({message, language, onClick}: {message: IFeedMessageAM, language: UILanguage, onClick: React.MouseEventHandler<HTMLAnchorElement> | undefined}) {
	const localizedContent = message.localizedContents.find(c => c.language === language.toString()) ?? message.localizedContents[0];

	return (
	<div className={"content" + (message.priority === "High" ? " alert" : "") + (message.firstRead === null ? " new" : "")} key={message.id}>
		<div className="icon">
			<svg width="26" height="32" viewBox="0 0 26 32" xmlns="http://www.w3.org/2000/svg">
				<path fill="#F05183" d="M0 0v32h25.1V9.1L24 8l-6.9-6.9L16 0H0zm1.1 1.1H16v8h8V31H1.1V1zm16 1.7L22.4 8H17V2.8zM4 15h16v-1H4v1zm0 3h13v-1H4v1zm0 3h15v-1H4v1zm0 3h17v-1H4v1z" />
			</svg>
		</div>
		<p>
			<span className="date"><Moment calendar={MomentCalendarStrings[language]} date={message.created} /></span>{' '}
			<span title={localizedContent.body}>{localizedContent.body}</span>
		</p>
		<InternalLink to={addQueryParameterToUrl(localizedContent.linkUri, "fc", "fa")}
			onClick={onClick}
		>{localizedContent.linkText}&nbsp;&rsaquo;</InternalLink>
	</div>);
}

const mapStateToProps = ({ user, feed, contracts, embedding, dashboard }: IAuthenticatedShellState): IFeedHistoryStateProps => {

	const messages = orderBy(feed.messages, [m => m.created], ['desc']);
	const { urls, fetchingUrls } = embedding;

	return {
		user,
		isLoading: feed.loadingMessages,
		messages,
		contract: contracts.selectedContract!,
		shouldLoadUrls: !urls.length && !fetchingUrls,
		uiLanguage: dashboard.uiLanguage,
	};
};

export default connect(
	mapStateToProps,
	{
		loadMessages: feedActions.loadMessages.request,
		logMessageFollow: feedActions.logMessageFollow.request,
		fetchEmbeddedUrls: () => embedActions.fetchUrls.request()

	}
)(FeedHistory);
