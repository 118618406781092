import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import Cookies from 'universal-cookie';
import {
	ContractContextActionTypes,
	contractContextActions,
	ILoadContractsSuccessParams
} from "./contractContextActions";
import { IContractAM } from "src/ApiClient";

export interface IContractContextState {
	loadingContracts: boolean;
	contractsLoaded: boolean;
	contracts: IContractAM[];
	selectedContract: IContractAM | null;
}

const initialState: IContractContextState = {
	loadingContracts: false,
	contractsLoaded: false,
	contracts: [],
	selectedContract: null
};

export const contractContextReducer: Reducer<
	IContractContextState,
	ContractContextActionTypes
> = (state = initialState, action) => {
	switch (action.type) {
		case getType(contractContextActions.loadContracts.request):
			return {
				...state,
				loadingContracts: true
			};

		case getType(contractContextActions.loadContracts.success):
			return {
				loadingContracts: false,
				contractsLoaded: true,
				contracts: action.payload.contracts,
				selectedContract: saveSelectedContractToCookie(chooseSelectedContract(action.payload))
			};

		case getType(contractContextActions.loadContracts.failure):
			return {
				loadingContracts: false,
				contractsLoaded: false,
				contracts: [],
				selectedContract: null
			};

		case getType(contractContextActions.changeSelectedContract):
			return {
				...state,
				selectedContract: saveSelectedContractToCookie(changeSelectedContract(action.payload, state.contracts))
			};

		case getType(contractContextActions.logContractChange.success):
			return state;

		case getType(contractContextActions.logContractChange.failure):
			return state;
	}

	return state;
};

const cookieNameForSelectedContractNumber = "selected-contract";

function chooseSelectedContract(input: ILoadContractsSuccessParams): IContractAM | null {
	if (input.contracts.length === 0) { return null; }
	if (input.contracts.length === 1) { return input.contracts[0]; }

	if (input.defaultContractNumber) {
		const result = input.contracts.find(c => c.contractNumber === input.defaultContractNumber)
		if (result) { return result; }
	}

	const cookies = new Cookies();
	const contractNumberFromCookie = cookies.get(cookieNameForSelectedContractNumber);
	if (contractNumberFromCookie) {
		const contractBasedOnCookieValue = changeSelectedContract(contractNumberFromCookie, input.contracts);
		if (contractBasedOnCookieValue) { return contractBasedOnCookieValue; }
	}

	return input.contracts[0];
}

function changeSelectedContract (contractNumber: string, contracts: IContractAM[]): IContractAM | null {
	if (!contracts) { return null; }

	const contract = contracts.find(c => c.contractNumber === contractNumber);
	if (!contract) { return null; }

	return contract;
}

function saveSelectedContractToCookie(selectedContract: IContractAM | null): IContractAM | null {
	if (selectedContract) {
		new Cookies().set(cookieNameForSelectedContractNumber, selectedContract.contractNumber, { path: '/' });
	}
	return selectedContract;
}
