export interface IAnimateOptions<
	TAnimProps extends Record<string, number>
> {
	durationMs: number;
	start: TAnimProps;
	end: TAnimProps;
	callback: (props: TAnimProps) => void;
}

export const animate = <
	T1 extends Record<string, number>
>(
	options: IAnimateOptions<T1>
): Promise<void> =>
	new Promise((resolve, reject) => {
		const startTime = new Date().getTime();
		const nextFrame = () =>
			requestAnimationFrame(() => {
				const elapsed = new Date().getTime() - startTime;
				const lastFrame = elapsed >= options.durationMs;

				try {
					const currentValues : Record<string, number> = {};
					for (const key in options.start) {
						if (options.start.hasOwnProperty(key)) {
							const startValue = options.start[key];
							const endValue = options.end[key];
							currentValues[key] = 'lastFrame'
								? endValue
								: startValue +
								  (endValue - startValue) *
										(elapsed / options.durationMs);
						}
					}
					options.callback(currentValues as T1);

					if (!lastFrame) {
						nextFrame();
					} else {
						resolve();
					}
				} catch (err) {
					reject(err);
				}
			});
		nextFrame();
	});
