import React from 'react';
import { SurveyDialog } from "./SurveyDialog";
import {useResource, ResourceText} from "@kojamo/react-utils";

export interface ISiteSurveyDialogProps {
	onCloseRequested: () => void;
	onOpenSurveyRequested: () => void;
}

const SiteSurveyDialog: React.FC<ISiteSurveyDialogProps> = ({ onCloseRequested, onOpenSurveyRequested }) => {
	const { getResourceText } = useResource();

	return (
		<SurveyDialog
			onOpenSurveyRequested={onOpenSurveyRequested}
			onCloseRequested={onCloseRequested}
			title={<ResourceText textType="MarkdownInline"  resourceKey="SiteSurveyDialog_Title" />}
			infoText={<ResourceText textType="MarkdownInline"  resourceKey="SiteSurveyDialog_InfoText" />}
			surveyUrl={getResourceText("SiteSurveyDialog_Accept_Url")}
			acceptLinkText={<ResourceText textType="MarkdownInline" resourceKey="SiteSurveyDialog_Accept_Link" />}
			declineLinkText={<ResourceText textType="MarkdownInline" resourceKey="SiteSurveyDialog_Decline_Link" />}
		/>
	);
};

export default SiteSurveyDialog;
