import { createAsyncAction, ActionType, createAction } from "typesafe-actions";
import { IUserContext } from "../../rootReducer";
import { IContractAM } from "src/ApiClient";

export interface ILoadContractsRequest {
	user: IUserContext;
	defaultContractNumber: string | null;
}

export interface ILoadContractsSuccessParams {
	contracts: IContractAM[];
	defaultContractNumber: string | null;
}

export interface ILogContractsChangeRequest {
	user: IUserContext;
	contractId: string;
}

export const contractContextActions = {
	loadContracts: createAsyncAction(
		"contracts/LOAD_CONTRACTS_REQUEST",
		"contracts/LOAD_CONTRACTS_SUCCESS",
		"contracts/LOAD_CONTRACTS_FAILED"
	)<ILoadContractsRequest, ILoadContractsSuccessParams, Error>(),
	changeSelectedContract: createAction(
		"contracts/CHANGE_SELECTED_CONTRACT")<string>(),
	logContractChange: createAsyncAction(
		"contracts/LOG_CONTRACT_CHANGE_REQUEST",
		"contracts/LOG_CONTRACT_CHANGE_SUCCESS",
		"contracts/LOG_CONTRACT_CHANGE_FAILED"
	)<ILogContractsChangeRequest, void, Error>()
};

export type ContractContextActionTypes = ActionType<
	typeof contractContextActions
>;
