import * as React from "react";
import {
	Route,
	RouteProps,
	Redirect,
	RouteComponentProps,
	StaticContext,
	withRouter,
} from "react-router";
import { connect } from "react-redux";
import { IShellState, UILanguage } from "../rootReducer";
import { Spinner } from "../Components/Spinner";
import { LoginStatus } from "../Authentication/userReducer";
import { authActions } from "src/Authentication/authenticationActions";
import { dashboardActions } from "src/Pages/Dashboard/dashboardActions";
import {authenticatedRoutes} from "./authenticatedRoutes";
import {queryParameterSessionStorageKey} from "./loginQueryParameterCache";

interface IReduxProps {
	isAuthenticated: boolean;
	isLoggingIn: boolean;
	needsWelcomeActions: boolean;
	isRefreshing: boolean;
	uiLanguage: UILanguage;
}

interface IDispatchProps {
	login: typeof authActions.login.request;
	switchLanguage: typeof dashboardActions.switchLanguage;
}

type Props = IReduxProps &
	IDispatchProps &
	RouteProps &
	RouteComponentProps<any>;

interface IInternalState {
	status?: Status;
	changingLanguage?: boolean;
}

type Status = "Initial" | "Authenticated" | "Refreshing" | "Unauthenticated";

function tryReadUiLanguageFromQueryString(): UILanguage | undefined {
	if (window.location.search) {
		const idx = window.location.search.indexOf("lang=");
		if (idx > -1) {
			const lang = window.location.search.substr(idx + "lang=".length, 2);
			if ((lang && lang === "fi") || lang === "en") {
				return lang;
			}
		}
	}
	return undefined;
}
class AuthenticatedRoute extends React.Component<Props, IInternalState> {
	public static getDerivedStateFromProps(props: Props) {
		const lang = tryReadUiLanguageFromQueryString();
		let changingLanguage;
		if (lang && lang !== props.uiLanguage) {
			const query = props.location.search.replace("lang=" + lang, "");
			props.history.replace({ ...props.location, search: query });
			props.switchLanguage(lang);
			changingLanguage = true;
		}
		return {
			status: AuthenticatedRoute.calculateStatus(props),
			changingLanguage,
		};
	}

	private static calculateStatus(props: Props): Status {
		if (props.isAuthenticated) {
			return "Authenticated";
		}
		if (props.isRefreshing) {
			return "Refreshing";
		}
		return "Unauthenticated";
	}

	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	public componentDidMount() {
		this.loginUnauthenticated();
	}

	public componentDidUpdate() {
		this.loginUnauthenticated();
	}

	public render() {
		const {
			isAuthenticated,
			component: Component,
			children,
			...routeProps
		} = this.props;

		if (this.state.status === "Unauthenticated") {
			return null;
		}

		return <Route {...routeProps} render={this.renderRoute} />;
	}

	private renderRoute = (
		props: RouteComponentProps<any, StaticContext, any>
	) => {
		const { status } = this.state;

		if (
			this.state.changingLanguage === true ||
			status === "Refreshing" ||
			status === "Initial"
		) {
			return <Spinner />;
		}

		if (status === "Authenticated") {
			const shouldRedirectToWelcome =
				this.props.needsWelcomeActions &&
				this.props.location &&
				this.props.location.pathname !==
					authenticatedRoutes.welcome[this.props.uiLanguage];

			return shouldRedirectToWelcome ? (
				<Redirect
					to={{
						pathname:
							authenticatedRoutes.welcome[this.props.uiLanguage],
						state: { from: props.location },
					}}
				/>
			) : (
				<Route {...this.getRouteProps()} />
			);
		}

		return null;
	};

	private getRouteProps(): any {
		const allProps = this.props;
		const { isAuthenticated, isRefreshing, ...result } = allProps;
		return result;
	}



	private loginUnauthenticated = () => {
		if (this.state.status !== "Unauthenticated" || this.props.isLoggingIn) {
			return;
		}

		if (this.props.location) {
			const loginProps = {
				returnUrl:
					this.props.location.pathname +
					this.props.location.search +
					this.props.location.hash,
				culture: this.props.uiLanguage,
			};

			// Store query parameters to session storage before identification so we can fetch them later
			// and redirect to the original url.
			const query = {
				pathName: this.props.location.pathname +
				(this.props.location.pathname.endsWith("/") ? "" : "/"),
				search: this.props.location.search,
			}


			sessionStorage.setItem(queryParameterSessionStorageKey, JSON.stringify(query));

			this.props.login(loginProps);
		}
	};
}

const mapStateToProps = ({
	user,
	refreshingUser,
	loginState,
	dashboard,
}: IShellState): IReduxProps => {
	return {
		isAuthenticated: !!user,
		isLoggingIn: loginState.status === LoginStatus.LoggingIn,
		needsWelcomeActions: !!user && user.welcomeActionsNeeded,
		isRefreshing: refreshingUser,
		uiLanguage: dashboard.uiLanguage,
	};
};

export default connect(mapStateToProps, {
	login: authActions.login.request,
	switchLanguage: dashboardActions.switchLanguage,
})(withRouter(AuthenticatedRoute));
